import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Link,
  IconButton,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { If } from 'react-if';
import BreadCrumbs from './BreadCrumbs';
import { get as registryGet } from '../../lib/appRegistry';

const NavigationWrapper = styled(Box)(
  () => ({
    position: 'absolute',
    right: 8,
    top: 8,
  }),
);

const StyledDialogContent = styled(DialogContent)({
  fontSize: '12px',
});

const StyledTable = styled(Table)({
  minWidth: 600,
});

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(5),
  }),
);

const RespnonseBodyText = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  }),
);

const ButtonLink = styled(Link)({
  fontSize: 14,
  textAlign: 'inherit',
});
const BackButton = styled(Button)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
);

const TableCellContent = styled(TableCell)(
  () => ({
    whiteSpace: 'pre-line',
  }),
);

const getData = (path, root) => {
  if (!root) return null;

  let resultData = root;
  _.forEach(path, (part, index) => {
    const foundData = _.find(resultData, ['name', part]);
    resultData = path.length - 1 > index ? foundData.schema : foundData;
  });
  return resultData;
};

const moveBack = (path, setPath) => () => {
  path.pop();
  setPath([...path]);
};

const renderContent = (res, path, setNewPath) => (
  _.map(res.schema, ({
    name, type, description, schema,
  }) => {
    const { projectName } = registryGet('config');
    return (
      <TableRow key={name}>
        <TableCell>{name}</TableCell>
        <TableCell>
          {schema ? (
            <ButtonLink onClick={setNewPath(name)} sx={{ fontSize: 14 }} component="button">{type}</ButtonLink>
          ) : type}

        </TableCell>
        <TableCellContent>{_.replace(description, /{{projectName}}/gm, projectName)}</TableCellContent>
      </TableRow>
    );
  })
);

function InnerSchemaDialog({ data, name }) {
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState([]);

  const setNewPath = (part) => () => {
    setPath([...path, part]);
  };

  const handleClickOpen = () => {
    if (!data) return;
    setOpen(true);
    setPath([name]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const currentEntity = _.last(path);
  const currentData = getData(path, data.body);
  const valueType = currentData.type;
  const isRoot = path.length < 2;
  return (
    <>
      <Button
        onClick={handleClickOpen}
      >
        Schema
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
      >
        <StyledDialogTitle>
          <BreadCrumbs path={path} setPath={setPath} />
          <NavigationWrapper>
            <If condition={!isRoot}>
              <BackButton
                onClick={moveBack(path, setPath)}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </BackButton>
            </If>

            <IconButton
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </NavigationWrapper>
          <RespnonseBodyText variant="h6">{`${_.capitalize(currentEntity)}: ${valueType}`}</RespnonseBodyText>
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderContent(currentData, path, setNewPath)}</TableBody>
          </StyledTable>
        </StyledDialogContent>
      </Dialog>
    </>

  );
}

InnerSchemaDialog.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.array,
    valueType: PropTypes.string,
    code: PropTypes.number,
  }),
  name: PropTypes.string,
};

InnerSchemaDialog.defaultProps = {
  data: {},
  name: '',
};

export default InnerSchemaDialog;
