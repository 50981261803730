import comments from './comments.json';
import addResponse from './payloadExamples/add.json';
import updateResponse from './payloadExamples/update.json';
import removeResponse from './payloadExamples/remove.json';

comments.operations[0].payloadExample = addResponse;
comments.operations[1].payloadExample = updateResponse;
comments.operations[2].payloadExample = removeResponse;

export default comments;
