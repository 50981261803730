import _ from 'lodash';
import automationActionTypes from './automationActionTypes';
import automationTypes from './automationTypes';
import automationTriggerTypes from './automationTriggerTypes';

const data = [
  {
    type: automationActionTypes.addAssignee,
    name: 'Make responsible',
    key: automationActionTypes.addAssignee,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addAssignee}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', ''] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'userId', entries: ['userId', 'integer', '', 'when you need to add specific user', 'User id . 0- card owner'] },
                { key: 'userIds', entries: ['userId', 'array of integers', '', 'when you need to add random user from list', 'User ids . Empty array - random space user'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.removeAssignee,
    name: 'Remove responsible',
    key: automationActionTypes.removeAssignee,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.removeAssignee}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Send empty data if you need to assigned at the time of the action'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'userId', entries: ['userId', 'integer', '', 'no', 'User id to remove.'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.moveToPath,
    name: 'Move card to',
    key: automationActionTypes.moveToPath,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.moveToPath}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'spaceId', entries: ['spaceId', 'integer', '', 'yes', 'New card space'] },
                { key: 'columnId', entries: ['columnId', 'integer', '', 'yes', 'New card column'] },
                { key: 'boardId', entries: ['boardId', 'integer', '', 'yes', 'New card board'] },
                { key: 'laneId', entries: ['laneId', 'integer', '', 'yes', 'New card lane'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.moveOnBoard,
    name: 'Move a card within the board',
    key: automationActionTypes.moveOnBoard,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.moveOnBoard}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'direction', entries: ['direction', 'enum', ['top', 'bottom', 'next', 'previous'], 'yes', 'top, bottom - in same column, next - next column, previous - previous column'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addTag,
    name: 'Add tags',
    key: automationActionTypes.addTag,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addTag}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'tagNames', entries: ['tagNames', 'array of strings', '', 'yes', 'Tag names to add'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.removeTags,
    name: 'Remove tags',
    key: automationActionTypes.removeTags,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.removeTags}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'tagIds', entries: ['tagIds', 'array of integers', '', 'yes', 'Tag ids to remove, empty array - remove all'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addCardUsers,
    name: 'Add card members',
    key: automationActionTypes.addCardUsers,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addCardUsers}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'userIds', entries: ['userIds', 'array of integers', '', 'no', 'User ids to add. don`t send any data for event author'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addProperty,
    name: 'Add property',
    key: automationActionTypes.addProperty,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addProperty}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'propertyId', entries: ['propertyId', 'integer', '', 'yes', 'Property id to add'] },
                { key: 'value', entries: ['value', 'number | string | object | array', '', 'yes', ' property value, for date type - object,\n users type - array of user uid,\n select type - array of select options,\n rest - string '] },
              ],
              linkField: 'Type',
              objectProperties: {
                value: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'apply_date_mode', entries: ['apply_date_mode', [1, 2], 'yes', '1 - relative time, 2 -exact time'] },
                      { key: 'relative_days_offset', entries: ['relative_days_offset', 'number', '', 'for relative mode', 'Offset days after event'] },
                      { key: 'relative_hours_offset', entries: ['relative_hours_offset', 'number', '', 'for relative mode', 'Offset hours after event'] },
                      { key: 'relative_minutes_offset', entries: ['relative_minutes_offset', 'number', '', 'for relative mode', 'Offset minutes after event'] },
                      { key: 'offset_unit', entries: ['offset_unit', ['days', 'hours', 'minutes'], '', 'for relative mode', 'Relative offset unit'] },
                      { key: 'time', entries: ['time', 'string', '', 'no', 'Set if need to set time. Example: "20:00:00"'] },
                      { key: 'timezone', entries: ['timezone', 'string', '', 'yes', 'Timezone. Example: "America/Boa_Vista"'] },
                      { key: 'tzOffset', entries: ['tzOffset', 'integer', '', 'yes', 'Offset timezone from UTC in minutes'] },
                      { key: 'date', entries: ['date', 'string', '', 'for exact time.', ' Example: "2023-09-13"'] },
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addChildCard,
    name: 'Create child card',
    key: automationActionTypes.addChildCard,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addChildCard}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'title', entries: ['title', 'string', '', 'yes', 'Card title'] },
                { key: 'spaceId', entries: ['spaceId', 'integer | null', '', 'no', 'New card space. null - create on parent card path'] },
                { key: 'columnId', entries: ['columnId', 'integer | null', '', 'no', 'New card column.  null - create on parent card path'] },
                { key: 'boardId', entries: ['boardId', 'integer | null', '', 'no', 'New card board.  null - create on parent card path'] },
                { key: 'laneId', entries: ['laneId', 'integer | null', '', 'no', 'New card lane.  null - create on parent card path'] },
                { key: 'parentFields', entries: ['parentFields', 'array of enums', ['members', 'external_links', 'due_date', 'type', 'size', 'description', 'tags', 'files', 'responsible', 'timeline', 'estimate_workload', 'properties', 'customProperty_{custom_property_id}'], 'no', 'Inherit card fields. Variants:\n members - card members,\n external_links - card external links,\n due_date - card due date,\n type - card type,\n size - card size,\n description - card description,\n tags - card tags,\n files - card files\n responsible - card responsible,\n timeline - card timeline\n estimate_workload - card workload\n properties - inherit all card custom properties,\n  customProperty_{custom_property_id} - custom property . Example: customProperty_97"'] },
                { key: 'typeId', entries: ['typeId', 'integer', '', 'no', 'Add type'] },
                { key: 'size', entries: ['size', 'string', '', 'no', 'Add size'] },
                { key: 'dueDate', entries: ['dueDate', 'object', '', 'no', 'Add due date'] },
                { key: 'timezone', entries: ['timezone', 'string', '', 'no', 'Timezone. Work with "due_date" Example: "America/Boa_Vista"'] },
                { key: 'tagNames', entries: ['tagNames', 'array of strings', '', 'no', 'Add tags'] },
                { key: 'membersData', entries: ['membersData', 'object', '', 'no', 'Add members'] },
                { key: 'description', entries: ['description', 'string', 'maxLength": 32768', 'no', 'Add card description'] },
                { key: 'responsibleData', entries: ['responsibleData', 'object', '', 'no', 'Add card responsible'] },
                { key: 'workload', entries: ['workload', 'number | null', '', 'no', 'Set workload'] },
                { key: 'linksData', entries: ['linksData', 'object', 'no', '', 'Add external links. Object keys must be uuid'] },
                { key: 'customProperties', entries: ['customProperties', 'object', '', 'no', 'Add custom properties. Can contains several custom properties data'] },
              ],
              linkField: 'Type',
              objectProperties: {
                dueDate: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'apply_date_mode', entries: ['apply_date_mode', 'enum', [1, 2], 'yes', '1 - relative time, 2 -exact time'] },
                      { key: 'relative_days_offset', entries: ['relative_days_offset', 'number', '', 'for relative mode', 'Offset days after event'] },
                      { key: 'relative_hours_offset', entries: ['relative_hours_offset', 'number', '', 'for relative mode', 'Offset hours after event'] },
                      { key: 'relative_minutes_offset', entries: ['relative_minutes_offset', 'number', '', 'for relative mode', 'Offset minutes after event'] },
                      { key: 'offset_unit', entries: ['offset_unit', ['days', 'hours', 'minutes'], '', 'for relative mode', 'Relative offset unit'] },
                      { key: 'asap', entries: ['asap', 'boolean', '', 'no', 'Asap flag'] },
                      { key: 'due_date_remind_at', entries: ['due_date_remind_at', 'string', '', 'no', 'For exact time. Reminder date. ISO 8601 format'] },
                      { key: 'due_date_time_present', entries: ['due_date_time_present', 'boolean', '', 'no', 'For both modes. Set true if need to set time'] },
                      { key: 'due_date', entries: ['due_date', 'string', '', 'for exact time.', 'Due date. ISO 8601 format'] },
                      { key: 'expires_later', entries: ['expires_later', 'boolean', '', 'for exact time.', 'Expires later flag'] },
                      { key: 'hours', entries: ['hours', 'integer', '', 'no', 'Work only with due_date_time_present set to true'] },
                      { key: 'minutes', entries: ['minutes', 'integer', '', 'no', 'Work only with due_date_time_present set to true'] },
                      { key: 'reminder_value', entries: ['reminder_value', 'integer', '', 'no', 'Relative reminder value. \n Work only with "reminder_unit"'] },
                      { key: 'reminder_unit', entries: ['reminder_unit', 'enum', ['days, hours', 'minutes'], 'no', 'Relative reminder offset units. \n Work only with "reminder_value"'] },
                    ],
                  },
                },
                membersData: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'variant', entries: ['variant', 'enum', ['updater', 'select_user'], 'yes', 'updater - add event author as member\n select_user - add selected users'] },
                      { key: 'userIds', entries: ['userIds', 'array of integers', '', 'for "select_user" variant', 'User ids to add'] },
                    ],
                  },
                },
                responsibleData: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'variant', entries: ['variant', 'enum', ['updater', 'select_user', 'card_owner_related', ' random_space_user', 'random_user_from_list'], 'yes', 'updater - add event author as responsible\n select_user - add selected user\n card_owner_related - inherit card responsible\n random_space_user - add random space user as responsible,\n random_user_from_list - add random user from list'] },
                      { key: 'userIds', entries: ['userIds', 'array of integers', '', 'for "random_user_from_list" variant', 'User id to choose from '] },
                      { key: 'userId', entries: ['userId', 'integer', '', 'for "select_user" variant', 'User id to add'] },
                    ],
                  },
                },
                linksData: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: '{uuid}', entries: ['{uuid}', 'object', '', 'yes', 'Link data'] },
                    ],
                    linkField: 'Type',
                    objectProperties: {
                      '{uuid}': {
                        schema: {
                          columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                          data: [
                            { key: 'url', entries: ['url', 'string', 'url format', 'yes', 'Link url'] },
                            { key: 'uid', entries: ['uid', 'string', '', 'yes', 'Link uid'] },
                            { key: 'description', entries: ['description', 'string', 'maxLength: 512', 'yes', 'Link description'] },
                          ],
                        },
                      },
                    },
                  },
                },
                customProperties: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: '{custom_property_id}', entries: ['{custom_property_id}', 'object', '', 'yes', '"Custom property data. Format customProperty_{custom_property_id}.\nExample: customProperty_97'] },
                    ],
                    linkField: 'Type',
                    objectProperties: {
                      '{custom_property_id}': {
                        schema: {
                          columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                          data: [
                            { key: 'id', entries: ['id', 'integer', '', 'yes', 'Property id'] },
                            { key: 'value', entries: ['value', 'string | object | array', '', 'yes', 'Property value, for date type - object,\n users type - array of user uid,\n select type - array of select options,\n rest - string'] },
                          ],
                          linkField: 'Type',
                          objectProperties: {
                            value: {
                              schema: {
                                columns: ['Field name', 'Type', 'Required', 'Description'],
                                data: [
                                  { key: 'apply_date_mode', entries: ['apply_date_mode', 'enum', [1, 2], 'yes', '1 - relative time, 2 -exact time'] },
                                  { key: 'relative_days_offset', entries: ['relative_days_offset', 'number', '', 'for relative mode', 'Offset days after event'] },
                                  { key: 'relative_hours_offset', entries: ['relative_hours_offset', 'number', '', 'for relative mode', 'Offset hours after event'] },
                                  { key: 'relative_minutes_offset', entries: ['relative_minutes_offset', 'number', '', 'for relative mode', 'Offset minutes after event'] },
                                  { key: 'offset_unit', entries: ['offset_unit', ['days', 'hours', 'minutes'], '', 'for relative mode', 'Relative offset unit'] },
                                  { key: 'time', entries: ['time', 'string', '', 'no', 'Set if need to set time. Example: "20:00:00"'] },
                                  { key: 'timezone', entries: ['timezone', 'string', '', 'yes', 'Timezone. Example: "America/Boa_Vista"'] },
                                  { key: 'tzOffset', entries: ['tzOffset', 'integer', '', 'yes', 'Offset timezone from UTC in minutes'] },
                                  { key: 'date', entries: ['date', 'string', '', 'for exact time.', ' Example: "2023-09-13"'] },
                                ],
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addParentCard,
    name: 'Create parent card',
    key: automationActionTypes.addParentCard,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addParentCard}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'title', entries: ['title', 'string', '', 'yes', 'Card title'] },
                { key: 'spaceId', entries: ['spaceId', 'integer | null', '', 'no', 'New card space. null - create on child card path'] },
                { key: 'columnId', entries: ['columnId', 'integer | null', '', 'no', 'New card column.  null - create on child card path'] },
                { key: 'boardId', entries: ['boardId', 'integer | null', '', 'no', 'New card board.  null - create on child card path'] },
                { key: 'laneId', entries: ['laneId', 'integer | null', '', 'no', 'New card lane.  null - create on child card path'] },
                { key: 'parentFields', entries: ['parentFields', 'array of enums', ['members', 'external_links', 'due_date', 'type', 'size', 'description', 'tags', 'files', 'responsible', 'timeline', 'estimate_workload', 'properties', 'customProperty_{custom_property_id}'], 'no', 'Inherit card fields. Variants:\n members - card members,\n external_links - card external links,\n due_date - card due date,\n type - card type,\n size - card size,\n description - card description,\n tags - card tags,\n files - card files\n responsible - card responsible,\n timeline - card timeline\n estimate_workload - card workload\n properties - inherit all card custom properties,\n  customProperty_{custom_property_id} - custom property . Example: customProperty_97"'] },
                { key: 'typeId', entries: ['typeId', 'integer', '', 'no', 'Add type'] },
                { key: 'size', entries: ['size', 'string', '', 'no', 'Add size'] },
                { key: 'dueDate', entries: ['dueDate', 'object', '', 'no', 'Add due date'] },
                { key: 'timezone', entries: ['timezone', 'string', '', 'no', 'Timezone. Work with "due_date" Example: "America/Boa_Vista"'] },
                { key: 'tagNames', entries: ['tagNames', 'array of strings', '', 'no', 'Add tags'] },
                { key: 'membersData', entries: ['membersData', 'object', '', 'no', 'Add members'] },
                { key: 'description', entries: ['description', 'string', 'maxLength": 32768', 'no', 'Add card description'] },
                { key: 'responsibleData', entries: ['responsibleData', 'object', '', 'no', 'Add card responsible'] },
                { key: 'workload', entries: ['workload', 'number | null', '', 'no', 'Set workload'] },
                { key: 'linksData', entries: ['linksData', 'object', 'no', '', 'Add external links. Object keys must be uuid'] },
                { key: 'customProperties', entries: ['customProperties', 'object', '', 'no', 'Add custom properties. Can contains several custom properties data'] },
              ],
              linkField: 'Type',
              objectProperties: {
                dueDate: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'apply_date_mode', entries: ['apply_date_mode', 'enum', [1, 2], 'yes', '1 - relative time, 2 -exact time'] },
                      { key: 'relative_days_offset', entries: ['relative_days_offset', 'number', '', 'for relative mode', 'Offset days after event'] },
                      { key: 'relative_hours_offset', entries: ['relative_hours_offset', 'number', '', 'for relative mode', 'Offset hours after event'] },
                      { key: 'relative_minutes_offset', entries: ['relative_minutes_offset', 'number', '', 'for relative mode', 'Offset minutes after event'] },
                      { key: 'offset_unit', entries: ['offset_unit', ['days', 'hours', 'minutes'], '', 'for relative mode', 'Relative offset unit'] },
                      { key: 'asap', entries: ['asap', 'boolean', '', 'no', 'Asap flag'] },
                      { key: 'due_date_remind_at', entries: ['due_date_remind_at', 'string', '', 'no', 'For exact time. Reminder date. ISO 8601 format'] },
                      { key: 'due_date_time_present', entries: ['due_date_time_present', 'boolean', '', 'no', 'For both modes. Set true if need to set time'] },
                      { key: 'due_date', entries: ['due_date', 'string', '', 'for exact time.', 'Due date. ISO 8601 format'] },
                      { key: 'expires_later', entries: ['expires_later', 'boolean', '', 'for exact time.', 'Expires later flag'] },
                      { key: 'hours', entries: ['hours', 'integer', '', 'no', 'Work only with due_date_time_present set to true'] },
                      { key: 'minutes', entries: ['minutes', 'integer', '', 'no', 'Work only with due_date_time_present set to true'] },
                      { key: 'reminder_value', entries: ['reminder_value', 'integer', '', 'no', 'Relative reminder value. \n Work only with "reminder_unit"'] },
                      { key: 'reminder_unit', entries: ['reminder_unit', 'enum', ['days, hours', 'minutes'], 'no', 'Relative reminder offset units. \n Work only with "reminder_value"'] },
                    ],
                  },
                },
                membersData: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'variant', entries: ['variant', 'enum', ['updater', 'select_user'], 'yes', 'updater - add event author as member\n select_user - add selected users'] },
                      { key: 'userIds', entries: ['userIds', 'array of integers', '', 'for "select_user" variant', 'User ids to add'] },
                    ],
                  },
                },
                responsibleData: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'variant', entries: ['variant', 'enum', ['updater', 'select_user', 'card_owner_related', ' random_space_user', 'random_user_from_list'], 'yes', 'updater - add event author as responsible\n select_user - add selected user\n card_owner_related - inherit card responsible\n random_space_user - add random space user as responsible,\n random_user_from_list - add random user from list'] },
                      { key: 'userIds', entries: ['userIds', 'array of integers', '', 'for "random_user_from_list" variant', 'User id to choose from '] },
                      { key: 'userId', entries: ['userId', 'integer', '', 'for "select_user" variant', 'User id to add'] },
                    ],
                  },
                },
                linksData: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: '{uuid}', entries: ['{uuid}', 'object', '', 'yes', 'Link data'] },
                    ],
                    linkField: 'Type',
                    objectProperties: {
                      '{uuid}': {
                        schema: {
                          columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                          data: [
                            { key: 'url', entries: ['url', 'string', 'url format', 'yes', 'Link url'] },
                            { key: 'uid', entries: ['uid', 'string', '', 'yes', 'Link uid'] },
                            { key: 'description', entries: ['description', 'string', 'maxLength: 512', 'yes', 'Link description'] },
                          ],
                        },
                      },
                    },
                  },
                },
                customProperties: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: '{custom_property_id}', entries: ['{custom_property_id}', 'object', '', 'yes', '"Custom property data. Format customProperty_{custom_property_id}.\nExample: customProperty_97'] },
                    ],
                    linkField: 'Type',
                    objectProperties: {
                      '{custom_property_id}': {
                        schema: {
                          columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                          data: [
                            { key: 'id', entries: ['id', 'integer', '', 'yes', 'Property id'] },
                            { key: 'value', entries: ['value', 'string | object | array', '', 'yes', 'Property value, for date type - object,\n users type - array of user uid,\n select type - array of select options,\n rest - string'] },
                          ],
                          linkField: 'Type',
                          objectProperties: {
                            value: {
                              schema: {
                                columns: ['Field name', 'Type', 'Required', 'Description'],
                                data: [
                                  { key: 'apply_date_mode', entries: ['apply_date_mode', 'enum', [1, 2], 'yes', '1 - relative time, 2 -exact time'] },
                                  { key: 'relative_days_offset', entries: ['relative_days_offset', 'number', '', 'for relative mode', 'Offset days after event'] },
                                  { key: 'relative_hours_offset', entries: ['relative_hours_offset', 'number', '', 'for relative mode', 'Offset hours after event'] },
                                  { key: 'relative_minutes_offset', entries: ['relative_minutes_offset', 'number', '', 'for relative mode', 'Offset minutes after event'] },
                                  { key: 'offset_unit', entries: ['offset_unit', ['days', 'hours', 'minutes'], '', 'for relative mode', 'Relative offset unit'] },
                                  { key: 'time', entries: ['time', 'string', '', 'no', 'Set if need to set time. Example: "20:00:00"'] },
                                  { key: 'timezone', entries: ['timezone', 'string', '', 'yes', 'Timezone. Example: "America/Boa_Vista"'] },
                                  { key: 'tzOffset', entries: ['tzOffset', 'integer', '', 'yes', 'Offset timezone from UTC in minutes'] },
                                  { key: 'date', entries: ['date', 'string', '', 'for exact time.', ' Example: "2023-09-13"'] },
                                ],
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.connectParentCard,
    name: 'Add parent card',
    key: automationActionTypes.connectParentCard,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.connectParentCard}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'cardId', entries: ['cardId', 'integer', '', 'yes', 'Parent card id'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.removeCardUsers,
    name: 'Remove card members',
    key: automationActionTypes.removeCardUsers,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.removeCardUsers}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'userIds', entries: ['userIds', 'array of integers', '', 'yes', 'User ids to remove'] },
                { key: 'withResponsible', entries: ['withResponsible', 'boolean', '', 'yes', ' Should remove responsible'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.changeAsap,
    name: 'Change ASAP',
    key: automationActionTypes.changeAsap,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.changeAsap}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'asap', entries: ['asap', 'boolean', '', 'yes', 'Asap flag'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addUserGroups,
    name: 'Add user groups in card',
    key: automationActionTypes.addUserGroups,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addUserGroups}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'groupIds', entries: ['groupIds', 'array of integers', '', 'yes', 'Group ids'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addSize,
    name: 'Add size',
    key: automationActionTypes.addSize,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addSize}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'size', entries: ['size', 'string', '', 'yes', 'Add size'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addDueDate,
    name: 'Set due date',
    key: automationActionTypes.addDueDate,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addDueDate}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'dueDate', entries: ['dueDate', 'object', '', 'yes', 'Add due date'] },
                { key: 'timezone', entries: ['timezone', 'string', '', 'yes', 'Timezone. Example: "America/Boa_Vista"'] },
              ],
              linkField: 'Type',
              objectProperties: {
                dueDate: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'apply_date_mode', entries: ['apply_date_mode', 'enum', [1, 2], 'yes', '1 - relative time, 2 -exact time'] },
                      { key: 'relative_days_offset', entries: ['relative_days_offset', 'number', '', 'for relative mode', 'Offset days after event'] },
                      { key: 'relative_hours_offset', entries: ['relative_hours_offset', 'number', '', 'for relative mode', 'Offset hours after event'] },
                      { key: 'relative_minutes_offset', entries: ['relative_minutes_offset', 'number', '', 'for relative mode', 'Offset minutes after event'] },
                      { key: 'offset_unit', entries: ['offset_unit', ['days', 'hours', 'minutes'], '', 'for relative mode', 'Relative offset unit'] },
                      { key: 'asap', entries: ['asap', 'boolean', '', 'no', 'Asap flag'] },
                      { key: 'due_date_remind_at', entries: ['due_date_remind_at', 'string', '', 'no', 'For exact time. Reminder date. ISO 8601 format'] },
                      { key: 'due_date_time_present', entries: ['due_date_time_present', 'boolean', '', 'no', 'For both modes. Set true if need to set time'] },
                      { key: 'due_date', entries: ['due_date', 'string', '', 'for exact time.', 'Due date. ISO 8601 format'] },
                      { key: 'expires_later', entries: ['expires_later', 'boolean', '', 'for exact time.', 'Expires later flag'] },
                      { key: 'hours', entries: ['hours', 'integer', '', 'no', 'Work only with due_date_time_present set to true'] },
                      { key: 'minutes', entries: ['minutes', 'integer', '', 'no', 'Work only with due_date_time_present set to true'] },
                      { key: 'reminder_value', entries: ['reminder_value', 'integer', '', 'no', 'Relative reminder value. \n Work only with "reminder_unit"'] },
                      { key: 'reminder_unit', entries: ['reminder_unit', 'enum', ['days, hours', 'minutes'], 'no', 'Relative reminder offset units. \n Work only with "reminder_value"'] },
                    ],
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.removeDueDate,
    name: 'Remove due date',
    key: automationActionTypes.removeDueDate,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.removeDueDate}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Empty object'] },
        ],
      },
    },
  },
  {
    type: automationActionTypes.completeChecklists,
    name: 'Complete all checklists in card',
    key: automationActionTypes.completeChecklists,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.completeChecklists}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', 'Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Empty object'] },
        ],
      },
    },
  },
  {
    type: automationActionTypes.sortCards,
    name: 'Sort cards',
    key: automationActionTypes.sortCards,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.sortCards}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'sortDirection', entries: ['sortDirection', 'enum', ['asc', 'desc'], 'yes', 'Sorting direction'] },
                { key: 'sortWithinColumn', entries: ['sortWithinColumn', 'boolean', '', 'yes', 'The cards will sorted within entire column'] },
                { key: 'sortProperty', entries: ['sortProperty', 'string', ['created', 'title', 'due_date', 'due_date', 'cp_{custom_property_id}'], 'yes', 'Sort by property \n  variants: \n   "created" - by creating date \n   "title" - by title\n   "due_date" - by due date\n   "size" -by size\n   "cp_{custom_property_id}" - by custom property'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addComment,
    name: 'Add comment',
    key: automationActionTypes.addComment,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addComment}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'text', entries: ['text', 'string', '', 'yes', 'comment text'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addSla,
    name: 'Add SLA',
    key: automationActionTypes.addSla,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addSla}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'slaIds', entries: ['slaIds', 'array of strings', '', 'yes', 'Sla uuids to add'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.removeSla,
    name: 'Remove SLA',
    key: automationActionTypes.removeSla,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.removeSla}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'slaIds', entries: ['slaIds', 'array of strings', '', 'yes', 'Sla uuids to remove, empty array for all'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.archive,
    name: 'Archive card',
    key: automationActionTypes.archive,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.archive}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Empty object'] },
        ],
      },
    },
  },
  {
    type: automationActionTypes.changeCustomPropertyValue,
    name: 'Change property value',
    key: automationActionTypes.changeCustomPropertyValue,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.changeCustomPropertyValue}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'value', entries: ['value', 'number', '', 'yes', 'Change value by'] },
                { key: 'propertyId', entries: ['propertyId', 'integer', '', 'yes', 'Property id to change'] },
                { key: 'changeVariant', entries: ['changeVariant', 'enum', ['increase', 'decrease'], 'yes', 'increase - increment value, decrease - decrease value '] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.addPropertyToChildCards,
    name: 'Change property value',
    key: automationActionTypes.addPropertyToChildCards,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.addPropertyToChildCards}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'childLevel', entries: ['childLevel', 'enum', ['first'], 'yes', 'Apply for child level'] },
                { key: 'customProperties', entries: ['customProperties', 'object', '', 'yes', 'Add custom properties. Can contains several custom properties data'] },
              ],
              linkField: 'Type',
              objectProperties: {
                customProperties: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: '{custom_property_id}', entries: ['{custom_property_id}', 'object', '', 'yes', '"Custom property data. Format customProperty_{custom_property_id}.\nExample: customProperty_97'] },
                    ],
                    linkField: 'Type',
                    objectProperties: {
                      '{custom_property_id}': {
                        schema: {
                          columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                          data: [
                            { key: 'id', entries: ['id', 'integer', '', 'yes', 'Property id'] },
                            { key: 'value', entries: ['value', 'string | object | array', '', 'yes', 'Property value, for date type - object,\n users type - array of user uid,\n select type - array of select options,\n rest - string'] },
                          ],
                          linkField: 'Type',
                          objectProperties: {
                            value: {
                              schema: {
                                columns: ['Field name', 'Type', 'Required', 'Description'],
                                data: [
                                  { key: 'apply_date_mode', entries: ['apply_date_mode', 'enum', [1, 2], 'yes', '1 - relative time, 2 -exact time'] },
                                  { key: 'relative_days_offset', entries: ['relative_days_offset', 'number', '', 'for relative mode', 'Offset days after event'] },
                                  { key: 'relative_hours_offset', entries: ['relative_hours_offset', 'number', '', 'for relative mode', 'Offset hours after event'] },
                                  { key: 'relative_minutes_offset', entries: ['relative_minutes_offset', 'number', '', 'for relative mode', 'Offset minutes after event'] },
                                  { key: 'offset_unit', entries: ['offset_unit', ['days', 'hours', 'minutes'], '', 'for relative mode', 'Relative offset unit'] },
                                  { key: 'time', entries: ['time', 'string', '', 'no', 'Set if need to set time. Example: "20:00:00"'] },
                                  { key: 'timezone', entries: ['timezone', 'string', '', 'yes', 'Timezone. Example: "America/Boa_Vista"'] },
                                  { key: 'tzOffset', entries: ['tzOffset', 'integer', '', 'yes', 'Offset timezone from UTC in minutes'] },
                                  { key: 'date', entries: ['date', 'string', '', 'for exact time.', ' Example: "2023-09-13"'] },
                                ],
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.changeType,
    name: 'Change card type',
    key: automationActionTypes.changeType,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.changeType}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'typeId', entries: ['typeId', 'integer', '', 'yes', 'New type id'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationActionTypes.changeService,
    name: 'Attach or change service',
    key: automationActionTypes.changeService,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationActionTypes.changeService}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'serviceId', entries: ['serviceId', 'integer', '', 'yes', 'New service id'] },
              ],
            },
          },
        },
      },
    },
  },
];

const filterData = (actionsData, automationType, triggerType) => {
  if (automationType === automationTypes.onDate) {
    return _.reject(actionsData, ({ type }) => type === automationActionTypes.addDueDate);
  }
  if (triggerType === automationTriggerTypes.cardTypeChanged) {
    return _.reject(actionsData, ({ type }) => type === automationActionTypes.changeType);
  }
  return actionsData;
};

const getActionsData = (automationType, triggerType) => {
  const actionsData = filterData(data, automationType, triggerType);

  const attributes = {};
  const tableData = [];
  _.forEach(actionsData, (actionData) => {
    tableData.push({
      key: actionData.key,
      entries: [actionData.type, 'object', actionData.name],
    });
    attributes[actionData.key] = actionData.attributes;
  });

  return {
    schema: {
      columns: ['Action Type', 'Action attributes', 'Action Name'],
      data: tableData,
      linkField: 'Action attributes',
      objectProperties: attributes,
    },
  };
};

export default getActionsData;
