import groups from './groups.json';
import getGroups from './responseExamples/getGroups.json';
import getGroup from './responseExamples/getGroup.json';
import createGroup from './responseExamples/createGroup.json';
import updateGroup from './responseExamples/updateGroup.json';

groups.operations[0].responses[0].example = getGroups;
groups.operations[1].responses[0].example = getGroup;
groups.operations[2].responses[0].example = createGroup;
groups.operations[3].responses[0].example = updateGroup;

export default groups;
