import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { If, Then, Else } from 'react-if';
import { grey } from '@mui/material/colors';
import Response from './Response';

const Container = styled(Paper)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    overflow: 'hidden',
  }),
);

const Header = styled(Typography)(
  ({ theme }) => ({
    padding: `${theme.spacing(1)} 14px ${theme.spacing(1)} 14px`,
    borderBottom: '1px solid rgba(0,0,0,.1)',
  }),
);

const ContentTypography = styled(Typography)(
  ({ theme }) => ({
    marginRight: theme.spacing(2),
    padding: `${theme.spacing(1)} 14px ${theme.spacing(1)} 14px`,
    fontSize: 12,
  }),
);
const Snippet = styled(Paper)({
  padding: 3,
  marginRight: 3,
  marginLeft: 3,
  backgroundColor: grey[100],
});

const renderResponce = (res) => (
  <Response res={res} />
);

function ResponseBox({ processing, res }) {
  return (
    <Container variant="outlined">
      <Header>Response</Header>
      <If condition={processing}>
        <Then>
          <ContentTypography
            variant="body1"
          >
            Request being processed...
          </ContentTypography>
        </Then>
        <Else>
          <If condition={res}>
            <Then>
              {renderResponce(res)}
            </Then>
            <Else>
              <ContentTypography
                variant="body1"
              >
                Click
                <Snippet
                  component="span"
                >
                  Try It!
                </Snippet>
                to start a request and see the response here!
              </ContentTypography>
            </Else>
          </If>
        </Else>
      </If>
    </Container>
  );
}
ResponseBox.propTypes = {
  processing: PropTypes.bool.isRequired,
  res: PropTypes.object,
};

ResponseBox.defaultProps = {
  res: null,
};

export default ResponseBox;
