import colors from './colors.json';

export default {
  name: 'Appendix',
  id: 12,
  operations: [
    {
      id: 13,
      name: 'Colors',
      component: 'Colors',
      data: colors,
    },
  ],
};
