import boards from './boards.json';
import createSchema from '../schema/create/board.json';
import updateSchema from '../schema/old/update/board.json';
import deleteSchema from '../schema/delete/force.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import getResponse from './responseExamples/200/get.json';
import patchResponse from './responseExamples/200/patch.json';
import deleteResponse from './responseExamples/200/delete.json';

boards.operations[0].request.schema = createSchema;
boards.operations[3].request.schema = updateSchema;
boards.operations[4].request.schema = deleteSchema;

boards.operations[0].responces[0].example = postResponse;
boards.operations[1].responces[0].example = getListResponse;
boards.operations[2].responces[0].example = getResponse;
boards.operations[3].responces[0].example = patchResponse;
boards.operations[4].responces[0].example = deleteResponse;

export default boards;
