import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Link,
  IconButton,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { If } from 'react-if';
import BreadCrumbs from '../Responses/BreadCrumbs';
import {
  parseConstraints,
  strigifyData,
  parseDesc,
  parseSimpleType,
} from './helpers';

const NavigationWrapper = styled(Box)(
  () => ({
    position: 'absolute',
    right: 8,
    top: 8,
  }),
);

const CustomDefaultDataContainer = styled(Typography)(
  ({ theme }) => ({
    marginLeft: 2,
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const Caption = styled(Typography)({
  display: 'block',
});

const StyledDialogContent = styled(DialogContent)({
  fontSize: '12px',
});

const MainText = styled(Typography)({
  fontSize: '0.875rem',
});

const StyledTable = styled(Table)({
  minWidth: 600,
});

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(5),
  }),
);

const ButtonLink = styled(Link)({
  fontSize: 14,
  textAlign: 'inherit',
});
const DefaultDataContainer = styled(Typography)({
  marginLeft: 2,
});
const BackButton = styled(Button)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
);

const TableCellContent = styled(TableCell)(
  () => ({
    whiteSpace: 'pre-line',
  }),
);

const CustomDefaultDataHeader = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const moveBack = (path, setPath) => () => {
  path.pop();
  setPath([...path]);
};

const parseType = (data, name, setNewPath) => {
  if (
    (_.includes(data.type, 'object') && (data.properties || data.patternProperties))
    || (_.includes(data.type, 'array') && data.items?.type === 'object')
  ) {
    return (
      <ButtonLink
        onClick={setNewPath(name)}
        sx={{ fontSize: 14 }}
        component="button"
      >
        {_.isArray(data.type) ? data.type.join(' | ') : data.type}
      </ButtonLink>
    );
  }
  if (data.oneOf) {
    return data.oneOf.map(parseSimpleType).join(' | ');
  }
  return parseSimpleType(data);
};

const getData = (path, data) => {
  if (path.length < 2) {
    return {
      data: data.type === 'object' ? data : data.items,
      path,
    };
  }
  const restPath = _.tail(path);
  const resultPath = [path[0]];
  let resultData = data;
  _.forEach(restPath, (propName) => {
    const currentData = resultData.type === 'object' ? resultData : resultData.items;
    if (currentData.patternProperties) {
      resultPath.push(currentData.patternProperties[propName].displayName);
      resultData = currentData.patternProperties[propName];
      return;
    }
    resultPath.push(propName);
    resultData = currentData.properties[propName].type === 'object' ? currentData.properties[propName] : currentData.properties[propName].items;
  });
  return {
    data: resultData,
    path: resultPath,
  };
};

const renderProperties = (
  props,
  setNewPath,
  reqData = [],
  handleClose = _.noop,
) => _.keys(props).map((name) => {
  const propData = props[name];
  const type = parseType(propData, name, setNewPath);
  const required = reqData.indexOf(name) >= 0;
  const desc = parseDesc(propData, handleClose);
  const constrains = parseConstraints(propData, type);
  const types = propData.oneOf || [];
  const filtered = _.uniqBy(types, 'description');
  const typesCount = filtered.length;
  if (typesCount < 2) {
    return (
      <TableRow key={name}>
        <TableCellContent>
          {propData.displayName || name}
          <Caption
            variant="caption"
            color="error"
          >
            {required ? 'required' : ''}
          </Caption>
        </TableCellContent>
        <TableCellContent>
          <MainText component="div">
            {type}
          </MainText>
          {_.has(propData, 'default') && (
          <>
            <Typography variant="body2" component="span">
              default:
            </Typography>
            <DefaultDataContainer color="error" component="span">
              {strigifyData(propData.default)}
            </DefaultDataContainer>
          </>
          )}
        </TableCellContent>
        <TableCellContent>
          <MainText component="pre">
            {constrains}
          </MainText>
        </TableCellContent>
        <TableCellContent>
          <MainText component="pre">
            {desc}
          </MainText>
        </TableCellContent>
      </TableRow>
    );
  }
  return filtered.map((elem, index) => {
    const filteredType = parseType(elem, name);
    return (
      <TableRow key={`${name}_${filteredType}`}>
        {index === 0 && (
        <TableCellContent rowSpan={typesCount}>
          {propData.displayName || name}
          <Caption
            variant="caption"
            color="error"
          >
            {required ? 'required' : ''}
          </Caption>
        </TableCellContent>
        )}
        <TableCellContent>
          <MainText component="div">
            {filteredType}
          </MainText>
          {_.has(elem, 'default') && (
          <>
            <CustomDefaultDataHeader
              variant="body2"
              component="span"
            >
              default:
            </CustomDefaultDataHeader>
            <CustomDefaultDataContainer
              color="error"
              component="span"
            >
              {strigifyData(elem.default)}
            </CustomDefaultDataContainer>
          </>
          )}
        </TableCellContent>
        <TableCellContent>
          <MainText component="pre">
            {parseConstraints(elem, filteredType)}
          </MainText>
        </TableCellContent>
        <TableCellContent>
          <MainText component="pre">
            {parseDesc(elem, handleClose)}
          </MainText>
        </TableCellContent>
      </TableRow>
    );
  });
});

function InnerSchemaDialog({ data, name }) {
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState([]);

  const setNewPath = (part) => () => {
    setPath([...path, part]);
  };

  const handleClickOpen = () => {
    if (!data) return;
    setOpen(true);
    setPath([name]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: currentData, path: breadCrumbsPath } = getData(path, data);
  const isRoot = path.length < 2;
  return (
    <>
      <Button
        onClick={handleClickOpen}
      >
        Schema
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
      >
        <StyledDialogTitle>
          <BreadCrumbs path={breadCrumbsPath} setPath={setPath} />
          <NavigationWrapper>
            <If condition={!isRoot}>
              <BackButton
                onClick={moveBack(path, setPath)}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </BackButton>
            </If>

            <IconButton
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </NavigationWrapper>
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCellContent>Name</TableCellContent>
                <TableCellContent>Type</TableCellContent>
                <TableCellContent>Constraints</TableCellContent>
                <TableCellContent>Description</TableCellContent>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderProperties(
                currentData.properties ? currentData.properties : currentData.patternProperties,
                setNewPath,
                currentData.required,
                handleClose,
              )}
            </TableBody>
          </StyledTable>
        </StyledDialogContent>
      </Dialog>
    </>

  );
}

InnerSchemaDialog.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.array,
    valueType: PropTypes.string,
    code: PropTypes.number,
  }),
  name: PropTypes.string,
};

InnerSchemaDialog.defaultProps = {
  data: {},
  name: '',
};

export default InnerSchemaDialog;
