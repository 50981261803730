import groups from './groups.json';
import createSchema from '../schema/create/group.json';
import updateSchema from '../schema/update/group.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import requestExamples from './requestExamples.json';
import permissionTable from './additionalTables/permissions';

groups.operations[0].request.schema = createSchema;
groups.operations[3].request.schema = updateSchema;

groups.operations[0].responces[0].example = postResponse;
groups.operations[1].responces[0].example = getListResponse;
groups.operations[2].responces[0].example = postResponse;
groups.operations[3].responces[0].example = postResponse;
groups.operations[4].responces[0].example = postResponse;

groups.operations[3].request.examples = requestExamples;

groups.operations[3].additionalTables = [permissionTable];

export default groups;
