const tableData = {
  tableName: 'Group аccess permissions ',
  description: 'Detailed info for access permissions and its values',
  columns: ['Access name', 'Value'],
  data: [
    ['Administrative section "Members"', 1],
    ['Administrative section "Billing"', 2],
    ['Administrative section "Company settings"', 4],
    ['Administrative section "Spaces"', 16],
    ['Administrative section "Card types"', 32],
    ['Administrative section "Timesheets""', 64],
    ['Allow to read entries of colleagues in the card\'s Timesheets', 128],
    ['Administrative section "Export company data"', 256],
    ['Administrative section "Tags"', 512],
    ['Allow to invite users into the company', 1024],
    ['Settings sections of "Service Desk"', 2048],
    ['Allow to create custom properties for cards', 4096],
    ['Allow to open public access for cards (share the card)', 8192],
    ['Allow to convert cards to service desk requests and changing the author', 16384],
    ['Administrative section "Activity Logs"', 32768],
    ['Resource planning', 65536],
    ['Administrative section "Calendars"', 131072],
    ['Analytics sections of "Service Desk"', 262144],
    ['Requests section of "Service Desk"', 524288],
  ],
};

export default tableData;
