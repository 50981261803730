import React from 'react';
import {
  Typography, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NativeScrollContainer from '../NativeScrollContainer';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);
const BoldText = styled(Typography)({
  marginLeft: '3px',
  marginRight: '3px',
  fontWeight: 'bold',
});

const StyledListItem = styled(Box)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

function Introduction() {
  const { projectName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <Typography gutterBottom variant="h4" component="h1">
          {`${projectName} universal imports`}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {`
            This specification outlines the process and requirements for importing data from other task management tools into ${projectName}.
            The import process is designed to integrate external data seamlessly into our system,
            ensuring that it can be processed accurately and effectively.
            ${projectName} supports importing data across multiple sections, including:
          `}
        </Typography>
        <Box component="ul">
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Boards</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Cards</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Columns</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Comments</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Custom fields</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Card files</BoldText>
            </Typography>
          </StyledListItem>
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              <BoldText>Users</BoldText>
            </Typography>
          </StyledListItem>
        </Box>
        <Typography gutterBottom variant="subtitle1">
          {`
            The import process begins with a file named meta-data.json, which acts as the entry point for the import.
            This file contains references to each of the sections mentioned above,
            with the value of each section being the relative path to its corresponding JSON file.
            These JSON files contain the detailed structure and required fields for each section,
            ensuring that the imported data is compatible with ${projectName}'s internal architecture.
            Once the meta-data.json file is processed, each section is handled separately based on its respective JSON schema.
            The schema definitions provide a clear guideline on the expected format and content,
            allowing for a smooth import and integration into the ${projectName} system.
          `}
        </Typography>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Introduction;
