import React from 'react';
import {
  Typography,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import hljs from 'highlight.js';
import NativeScrollContainer from '../NativeScrollContainer';
import SectionTitle from '../SectionTitle';
import Snippet from '../Snippet';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';
import cancelButtonImg from '../../images/cancel_button.png';
import primaryButtonImg from '../../images/primary_button.png';
import inputFieldImg from '../../images/input_field.png';
import {
  Wrapper,
  SectionTitleWrapper,
  StyledTable,
  TableHeader,
  TableCellContent,
} from '../../lib/Components/StyledComponents';

const exampleCode = {
  style(hostName) {
    return `<html>
  <head>
    <link rel="stylesheet" href="https://files.${hostName}/web-sdk/v1.min.css" />
  </head>
  <body>
    ...
  </body>
  <script src="./js/client.js"></script>
</html>`;
  },
  styleExample: `.some-class {
  color: var(--addon-text-secondary-color)
}`,
};

function StylingAddon() {
  const { projectName, hostName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title="Styling Addon" hash="#styles" />
        </SectionTitleWrapper>
        <Typography gutterBottom>
          minified version -
          {' '}
          <a target="_blank" rel="noreferrer" href={`https://files.${hostName}/web-sdk/v1.min.css`}>
            {`https://files.${hostName}/web-sdk/v1.min.css`}
          </a>
        </Typography>
        <Typography gutterBottom>
          unminified version -
          {' '}
          <a target="_blank" rel="noreferrer" href={`https://files.${hostName}/web-sdk/v1.css`}>
            {`https://files.${hostName}/web-sdk/v1.css`}
          </a>
        </Typography>
        <Typography gutterBottom sx={{ marginTop: 4 }}>
          The simplest way to add styles to an iframe is to use our basic CSS styles. Both
          {' '}
          <a target="_blank" rel="noreferrer" href={`https://files.${hostName}/web-sdk/v1.min.css`}>
            minified
          </a>
          {' '}
          and
          {' '}
          <a target="_blank" rel="noreferrer" href={`https://files.${hostName}/web-sdk/v1.css`}>
            unminified
          </a>
          {' '}
          versions are available.
        </Typography>
        <Typography gutterBottom sx={{ marginBottom: 2 }}>
          To use our basic stylesheet, simply use the link tag in all your HTML files, for example:
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.style(hostName), { language: 'html' }).value }} />
        </Snippet>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          This file contains both the base styles (
          <Snippet inline removeGutters>
            normalize.css
          </Snippet>

          ) and CSS classes for fundamental DOM elements (and will be gradually expanded)
        </Typography>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          The styles correspond to the color theme, meaning they automatically reproduce different colors depending on whether the user is in light or dark mode. Be sure to call
          {' '}
          <Snippet inline removeGutters>
            Addon.iframe()
          </Snippet>
          {' '}
          within the script in your HTML files - this call initializes CSS variables for the current color scheme.
        </Typography>
        <Paper elevation={0}>
          <TableHeader>
            <SectionTitle title=" Main classes" hash="#classes" />
          </TableHeader>
          <TableContainer>
            <StyledTable size="small">
              <TableHead>
                <TableRow>
                  <TableCellContent>Classes</TableCellContent>
                  <TableCellContent>Description</TableCellContent>
                  <TableCellContent>Example</TableCellContent>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCellContent>
                    <b>.addon-btn</b>
                    <br />
                    <Snippet inline removeGutters>
                      {'<button class="addon-btn">Cancel</button>'}
                    </Snippet>
                  </TableCellContent>
                  <TableCellContent>Button styles (usually secondary button)</TableCellContent>
                  <TableCellContent>
                    <img width="150" src={cancelButtonImg} alt="cancel button" />
                  </TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    <b>.addon-btn-primary</b>
                    <br />
                    <Snippet inline removeGutters>
                      {'<button class="addon-btn-primary">Submit</button>'}
                    </Snippet>
                  </TableCellContent>
                  <TableCellContent>Primary button</TableCellContent>
                  <TableCellContent>
                    <img width="150" src={primaryButtonImg} alt="primary button" />
                  </TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    <b>
                      .addon-input
                      <br />
                      .addon-input-label
                    </b>
                    <br />
                    <Snippet inline removeGutters>
                      {'<label for="logText" class="addon-input-label">Time log</label>'}
                    </Snippet>
                    <br />
                    <Snippet inline removeGutters>
                      {'<input type="text" id="logText" class="addon-input" name="logText"'}
                    </Snippet>
                  </TableCellContent>
                  <TableCellContent>Input field</TableCellContent>
                  <TableCellContent>
                    <img width="250" src={inputFieldImg} alt="input field" />
                  </TableCellContent>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Paper>

        <Typography gutterBottom sx={{ marginTop: 4 }}>
          CSS variables available after calling
          {' '}
          <Snippet inline removeGutters>
            Addon.iframe()
          </Snippet>
          {' '}
          (specific variable values depend on the color theme currently being used by the user (light/dark))
        </Typography>
        <Paper elevation={0}>
          <TableContainer>
            <StyledTable size="small">
              <TableHead>
                <TableRow>
                  <TableCellContent>Variable</TableCellContent>
                  <TableCellContent>Description</TableCellContent>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCellContent>
                    --addon-text-primary-color
                  </TableCellContent>
                  <TableCellContent>Main text color</TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    --addon-text-secondary-color
                  </TableCellContent>
                  <TableCellContent>Secondary text color (gray)</TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    --addon-primary-color
                  </TableCellContent>
                  <TableCellContent>Theme primary color</TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    --addon-error-color
                  </TableCellContent>
                  <TableCellContent>Error or warning color</TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    --addon-success-color
                  </TableCellContent>
                  <TableCellContent>Success action color</TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    --addon-divider
                  </TableCellContent>
                  <TableCellContent>Divider color</TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    --addon-background-level1
                  </TableCellContent>
                  <TableCellContent>Background main color</TableCellContent>
                </TableRow>
                <TableRow>
                  <TableCellContent>
                    --addon-background-level2
                  </TableCellContent>
                  <TableCellContent>Background secondary color</TableCellContent>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Paper>

        <Typography gutterBottom sx={{ marginTop: 4 }}>
          Example :
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.styleExample, { language: 'css' }).value }} />
        </Snippet>
        <SectionTitleWrapper>
          <SectionTitle variant="h5" title="Fonts" hash="#fonts" />
        </SectionTitleWrapper>
        <Typography gutterBottom>
          {`${projectName} main font - Roboto`}
        </Typography>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          Add this font to all HTML files displayed in iframes (for example, using
          {' '}
          <a target="_blank" rel="noreferrer" href="https://www.npmjs.com/package/roboto-fontface">
            https://www.npmjs.com/package/roboto-fontface
          </a>
          {' '}
          ).
        </Typography>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default StylingAddon;
