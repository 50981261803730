import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import App from './App';
import Introduction from './components/Introduction';
import ExternalWebHookIntro from './components/externalWebhooks/Introduction';
import ExternalWebHookInfo from './components/externalWebhooks/Info';
import WebHookIntro from './components/webhooks/Intro';
import ScimIntro from './components/scim/Intro';
import ScimInfo from './components/scim/Info';
import Info from './components/Info';
import SentryIntegration from './components/webhooks/SentryIntegration';
import NotFoundPage from './components/NotFoundPage';
import UserMetadata from './components/userMetadata';
import AddonIntro from './components/Addons/Intro';
import CreateTestAddonGuide from './components/Addons/CreateTestAddonGuide';
import StylingAddon from './components/Addons/Styling';
import AddonInfo from './components/Addons/AddonInfo';
import ImportsIntro from './components/imports/Introduction';
import ImportsPages from './components/imports/Pages';

function Router({ addonSectionEnabled }) {
  return (
    <Routes>
      <Route
        path="/scim"
        element={
          <App prefix="/scim" api="scim" />
      }
      >
        <Route path="/scim" element={<ScimIntro />} />
        <Route path="/scim/:entity/:operation" element={<ScimInfo />} />
      </Route>
      {addonSectionEnabled && (
        <Route
          path="/addons"
          element={
            <App prefix="/addons" api="addons" />
      }
        >
          <Route path="/addons" element={<AddonIntro />} />
          <Route path="/addons/creating-example-addon" element={<CreateTestAddonGuide />} />
          <Route path="/addons/styling" element={<StylingAddon />} />
          <Route path="/addons/:entity/:operation" element={<AddonInfo />} />
        </Route>

      )}
      <Route
        path="/imports"
        element={
          <App prefix="/imports" api="imports" />
        }
      >
        <Route path="/imports" element={<ImportsIntro />} />
        <Route path="/imports/:entity/:operation" element={<ImportsPages />} />
      </Route>
      <Route
        path="/webhooks"
        element={
          <App prefix="/webhooks" api="webhooks" />
      }
      >
        <Route path="/webhooks" element={<WebHookIntro />} />
        <Route path="/webhooks/integrations/sentry" element={<SentryIntegration />} />
      </Route>
      <Route
        path="/external-webhooks"
        element={
          <App prefix="/external-webhooks" api="external webhooks" />
      }
      >
        <Route path="/external-webhooks" element={<ExternalWebHookIntro />} />
        <Route path="/external-webhooks/:entity/:operation" element={<ExternalWebHookInfo />} />
      </Route>
      <Route
        path="/user-metadata"
        element={
          <App prefix="/user-metadata" api="user metadata" />
      }
      >
        <Route path="/user-metadata" element={<UserMetadata />} />
      </Route>
      <Route
        path="/"
        element={
          <App api="rest" />
      }
      >
        <Route path="/" element={<Introduction />} />
        <Route path="/:entity/:operation" element={<Info />} />
      </Route>
      <Route path="*" element={<NotFoundPage to="/" />} />
    </Routes>
  );
}

Router.propTypes = {
  addonSectionEnabled: PropTypes.bool,
};

Router.defaultProps = {
  addonSectionEnabled: false,
};

export default Router;
