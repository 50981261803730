import tags from './tags.json';
import createSchema from '../schema/create/tag.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';

tags.operations[0].request.schema = createSchema;

tags.operations[0].responces[0].example = postResponse;
tags.operations[1].responces[0].example = getListResponse;

export default tags;
