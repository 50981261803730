import spaces from './spaces.json';
import createSchema from '../schema/old/create/space.json';
import updateSchema from '../schema/update/spaces.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import getResponse from './responseExamples/200/get.json';
import deleteResponse from './responseExamples/200/delete.json';

spaces.operations[0].request.schema = createSchema;
spaces.operations[3].request.schema = updateSchema;

spaces.operations[0].responces[0].example = postResponse;
spaces.operations[1].responces[0].example = getListResponse;
spaces.operations[2].responces[0].example = getResponse;
spaces.operations[3].responces[0].example = getResponse;
spaces.operations[4].responces[0].example = deleteResponse;
export default spaces;
