import cards from './cards';
import blocks from './blocks';
import comments from './comments';
import timelogs from './timelog';
import tags from './tag';
import boards from './board';
import files from './file';
import spaces from './space';
import cardMembers from './cardMembers';

const entities = [cards, blocks, comments, timelogs, tags, boards, files, spaces, cardMembers];

const api = {
  name: 'webhooks',
  type: 'WEBHOOK',
  entities,
  defaultRoute: '/external-webhooks',
  overview: [
    {
      id: 300,
      name: 'Introduction',
      path: '/external-webhooks',
    },
  ],
};

export default api;
