import catalog from './catalog.json';
import createSchema from '../schema/create/customPropertyCatalogValue.json';
import updateSchema from '../schema/update/customPropertyCatalogValue.json';
import createRequestExamples from './requestExamples.json';
import updateRequestExamples from './updateExamples.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

catalog.operations[0].request.schema = createSchema;
catalog.operations[0].request.examples = createRequestExamples;
catalog.operations[3].request.schema = updateSchema;
catalog.operations[3].request.examples = updateRequestExamples;

catalog.operations[0].responces[0].example = postResponse;
catalog.operations[1].responces[0].example = getListResponse;
catalog.operations[2].responces[0].example = postResponse;
catalog.operations[3].responces[0].example = postResponse;
catalog.operations[4].responces[0].example = deleteResponse;

export default catalog;
