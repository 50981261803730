import users from './users.json';
import updateSchema from '../schema/update/user.json';
import getListResponse from './responseExamples/200/getList.json';
import getResponse from './responseExamples/200/get.json';
import updateResponse from './responseExamples/200/update.json';

users.operations[2].request.schema = updateSchema;

users.operations[0].responces[0].example = getListResponse;
users.operations[1].responces[0].example = getResponse;
users.operations[2].responces[0].example = updateResponse;

export default users;
