import blockers from './cardBlockers.json';
import createSchema from '../schema/create/cardBlocker.json';
import updateSchema from '../schema/update/cardBlocker.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import patchResponse from './responseExamples/200/patch.json';
import deleteResponse from './responseExamples/200/delete.json';

blockers.operations[0].request.schema = createSchema;
blockers.operations[2].request.schema = updateSchema;

blockers.operations[0].responces[0].example = postResponse;
blockers.operations[1].responces[0].example = getListResponse;
blockers.operations[2].responces[0].example = patchResponse;
blockers.operations[3].responces[0].example = deleteResponse;

export default blockers;
