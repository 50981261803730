import members from './cardMembers.json';
import createSchema from '../schema/create/cardUser.json';
import updateSchema from '../schema/update/cardUser.json';
import getListResponse from './responseExamples/200/getList.json';
import postResponse from './responseExamples/200/post.json';
import updateResponse from './responseExamples/200/update.json';
import deleteResponse from './responseExamples/200/delete.json';

members.operations[0].request.schema = createSchema;
members.operations[2].request.schema = updateSchema;

members.operations[0].responces[0].example = postResponse;
members.operations[1].responces[0].example = getListResponse;
members.operations[2].responces[0].example = updateResponse;
members.operations[3].responces[0].example = deleteResponse;

export default members;
