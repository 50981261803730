import React from 'react';
import {
  Link, Box, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { get as registryGet } from '../lib/appRegistry';

const LogoLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
}));

const SupportText = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: '400',
}));

const Root = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette.divider,
  marginTop: '100px',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
}));

function Footer({ sx }) {
  const {
    supportEmail, projectUrl, projectName, logoUrl,
  } = registryGet('config');

  return (
    <Root sx={sx}>
      <LogoLink href={projectUrl}>
        <img src={logoUrl} className="logo-footer" alt="logo" />
        <Typography color="text.primary" variant="subtitle2">{projectName}</Typography>
      </LogoLink>
      <SupportText color="text.secondary" variant="subtitle2">
        {`If you have any questions or need help with integration feel free to
        write us at `}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
      </SupportText>
    </Root>

  );
}

Footer.propTypes = {
  sx: PropTypes.object,
};

Footer.defaultProps = {
  sx: {},
};

export default Footer;
