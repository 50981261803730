import React from 'react';
import { Helmet } from 'react-helmet';
import { get as registryGet } from '../lib/appRegistry';

const makeUrl = (faviconFolderUrl, fileName) => `${faviconFolderUrl}${fileName}`;

function Head() {
  const { projectName, faviconFolderUrl } = registryGet('config');

  return (
    <Helmet>
      <meta name="title" content={`${projectName} API`} />
      <meta name="Keywords" content={`API, ${projectName}`} />
      <meta property="og:title" content={`${projectName} API`} />

      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={makeUrl(faviconFolderUrl, '/apple-touch-icon-60x60.png')}
      />

      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={makeUrl(faviconFolderUrl, '/apple-touch-icon-76x76.png')}
      />

      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={makeUrl(faviconFolderUrl, '/apple-touch-icon-120x120.png')}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={makeUrl(faviconFolderUrl, '/apple-touch-icon-144x144.png')}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={makeUrl(faviconFolderUrl, '/apple-touch-icon-152x152.png')}
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={makeUrl(faviconFolderUrl, '/favicon-32x32.png')}
      />

      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={makeUrl(faviconFolderUrl, '/favicon-16x16.png')}
      />

      <link
        rel="icon"
        type="image/png"
        sizes="128x128"
        href={makeUrl(faviconFolderUrl, '/favicon-128.png')}
      />

      <link
        rel="icon"
        type="image/png"
        sizes="196x196"
        href={makeUrl(faviconFolderUrl, '/favicon-196x196.png')}
      />

      <meta
        name="msapplication-TileImage"
        content={makeUrl(faviconFolderUrl, '/mstile-70x70.png')}
      />

      <meta
        name="msapplication-TileImage"
        content={makeUrl(faviconFolderUrl, '/mstile-144x144.png')}
      />

      <meta
        name="msapplication-TileImage"
        content={makeUrl(faviconFolderUrl, '/mstile-150x150.png')}
      />

      <meta
        name="msapplication-TileImage"
        content={makeUrl(faviconFolderUrl, '/mstile-310x150.png')}
      />

      <meta
        name="msapplication-TileImage"
        content={makeUrl(faviconFolderUrl, '/mstile-310x310.png')}
      />

      <title>{`${projectName} API`}</title>
    </Helmet>
  );
}

export default Head;
