import cardMembers from './cardMembers.json';
import addResponse from './payloadExamples/add.json';
import updateResponse from './payloadExamples/update.json';
import removeResponse from './payloadExamples/remove.json';

cardMembers.operations[0].payloadExample = addResponse;
cardMembers.operations[1].payloadExample = updateResponse;
cardMembers.operations[2].payloadExample = removeResponse;

export default cardMembers;
