import vote from './customPropertyCollectiveVote.json';
import createSchema from '../schema/create/customPropertyCollectiveVoteValue.json';
import updateSchema from '../schema/update/customPropertyCollectiveVoteValue.json';
import deleteSchema from '../schema/delete/customPropertyCollectiveVoteValue.json';
import postResponse from './responseExamples/200/post.json';
import deleteResponse from './responseExamples/200/delete.json';
import getListResponse from './responseExamples/200/getList.json';

vote.operations[0].request.schema = createSchema;
vote.operations[2].request.schema = updateSchema;
vote.operations[3].request.schema = deleteSchema;

vote.operations[0].responces[0].example = postResponse;
vote.operations[1].responces[0].example = getListResponse;
vote.operations[2].responces[0].example = postResponse;
vote.operations[3].responces[0].example = deleteResponse;

export default vote;
