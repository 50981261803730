import conditionTypes from './automationConditionTypes';
import operatorTypes from './automationLogicOperators';

export default {
  [conditionTypes.tag]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of',
  },
  [conditionTypes.targetTag]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of',
  },
  [conditionTypes.cardType]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of',
  },
  [conditionTypes.newCardType]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of',
  },
  [conditionTypes.updater]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
      operatorTypes.inRole,
      operatorTypes.notInRole,
      operatorTypes.inGroups,
      operatorTypes.notInGroups,
      operatorTypes.inCompany,
      operatorTypes.notInCompany,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of\n in_role - is in role from list\n not_in_role - is not in role from list\n in_groups - is in groups\n not_in_groups - is not in groups\n in_company - have access to company\n not_ in_company - have not access to the company',
  },
  [conditionTypes.path]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.newPath]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.cardState]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of',
  },
  [conditionTypes.newCardState]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of',
  },
  [conditionTypes.comment]: {
    operators: [
      operatorTypes.startWith,
      operatorTypes.contains,
    ],
    description: ' start_with - start with \n contains - contains',
  },
  [conditionTypes.targetCustomProperty]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.customProperty]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.dueDate]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.isLess,
      operatorTypes.isMore,
      operatorTypes.isBetween,
      operatorTypes.any,
    ],
    description: ' eq - is\n ne-is not\n is_less - is before\n is_more - is after\n is_between - is between\n any - any.\n Only work for "set" data comparator, otherwise only eq allowed',
  },
  [conditionTypes.newDueDate]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.isLess,
      operatorTypes.isMore,
      operatorTypes.isBetween,
      operatorTypes.any,
    ],
    description: ' eq - is\n ne-is not\n is_less - is before\n is_more - is after\n is_between - is between\n any - any.\n Only work for "set", "changed" data comparator, otherwise only eq allowed',
  },
  [conditionTypes.newChildCardsState]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.childCardsState]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
    ],
    description: ' eq - is\n ne- is not',
  },
  [conditionTypes.checklistItemText]: {
    operators: [
      operatorTypes.startWith,
      operatorTypes.contains,
    ],
    description: ' start_with - start with \n contains - contains',
  },
  [conditionTypes.checklistItemDueDate]: {
    operators: [
      operatorTypes.exceeded,
      operatorTypes.notExceeded,
    ],
    description: ' exceeded - exceeded \n not_exceeded - not exceeded',
  },
  [conditionTypes.checklistItemCheckedState]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.cardIsRequest]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.cardAuthor]: {
    operators: [
      operatorTypes.inOrganisations,
    ],
    description: ' in_organisations - belongs to one of organizations',
  },
  [conditionTypes.blockStatus]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.relations]: {
    operators: [
      operatorTypes.eq,
    ],
    description: ' eq - is',
  },
  [conditionTypes.service]: {
    operators: [
      operatorTypes.eq,
      operatorTypes.ne,
      operatorTypes.contains,
      operatorTypes.notContains,
    ],
    description: ' eq - is \n ne-is not\n contains - is any of\n not_contains - is not any of',
  },
};
