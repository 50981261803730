import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Link,
  IconButton,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { If } from 'react-if';
import BreadCrumbs from '../Responses/BreadCrumbs';

const NavigationWrapper = styled(Box)(
  () => ({
    position: 'absolute',
    right: 8,
    top: 8,
  }),
);

const StyledDialogContent = styled(DialogContent)({
  fontSize: '12px',
});

const StyledTable = styled(Table)({
  minWidth: 600,
});

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(5),
  }),
);

const ButtonLink = styled(Link)({
  fontSize: 14,
  textAlign: 'inherit',
});
const BackButton = styled(Button)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
);

const TableCellContent = styled(TableCell)(
  () => ({
    whiteSpace: 'pre-line',
  }),
);

const Text = styled(Typography)({
  fontSize: '0.875rem',
});

const getData = (path, root) => {
  if (path.length < 2) return root;

  let resultData = root;
  _.forEach(_.tail(path), ({ path: part }) => {
    resultData = _.get(resultData.objectProperties, `${part}.schema`);
  });
  return resultData;
};

const moveBack = (path, setPath) => () => {
  path.pop();
  setPath([...path]);
};

function renderTableCell({
  cellData,
  data,
  rowData,
  columns,
  index,
  setNewPath,
  key,
}) {
  const { objectProperties, linkField, postfix = {} } = data;
  if (_.isArray(linkField) && _.includes(linkField, columns[index])) {
    const normalizedColumnName = _.toLower(_.replace(columns[index], / /gm, '_'));
    if (_.has(objectProperties, `${normalizedColumnName}.${rowData.key}`)) {
      return (
        <ButtonLink
          onClick={setNewPath({
            path: `${normalizedColumnName}.${rowData.key}`,
            displayName: postfix[normalizedColumnName] ? `${rowData.key}  ${postfix[normalizedColumnName]}` : rowData.key,
          })}
          sx={{ fontSize: 14 }}
          component="button"
        >
          {_.isArray(cellData) ? `[\n ${_.join(cellData, ',\n ')}\n]` : cellData}
        </ButtonLink>
      );
    }
  }
  if (columns[index] === linkField && _.has(objectProperties, rowData.key)) {
    return (
      <ButtonLink
        onClick={setNewPath({
          path: key,
          displayName: key,
        })}
        sx={{ fontSize: 14 }}
        component="button"
      >
        {_.isArray(cellData) ? `[\n ${_.join(cellData, ',\n ')}\n]` : cellData}
      </ButtonLink>
    );
  }
  if (_.isArray(cellData)) {
    return `[\n ${_.join(cellData, ',\n ')}\n]`;
  }
  return cellData;
}

const renderContent = (data, path, setNewPath) => {
  const {
    columns,
    data: tableData,
  } = data;
  return (
    _.map(tableData, (rowData) => (
      <TableRow>
        {_.map(rowData.entries, (cellData, index) => (
          <TableCellContent>
            <Text component="pre">
              {renderTableCell(
                {
                  cellData,
                  rowData,
                  data,
                  columns,
                  index,
                  setNewPath,
                  key: rowData.key,
                },
              )}
            </Text>
          </TableCellContent>
        ))}
      </TableRow>
    ))
  );
};

function InnerSchemaDialog({ data, name }) {
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState([]);

  const setNewPath = (part) => () => {
    setPath([...path, part]);
  };

  const handleClickOpen = () => {
    if (!data) return;
    setOpen(true);
    setPath([{
      path: name,
      displayName: name,
    }]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isRoot = path.length < 2;
  const currentData = getData(path, data);
  const { columns } = currentData;
  return (
    <>
      <Button
        onClick={handleClickOpen}
      >
        Schema
      </Button>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
      >
        <StyledDialogTitle>
          <BreadCrumbs path={path} setPath={setPath} />
          <NavigationWrapper>
            <If condition={!isRoot}>
              <BackButton
                onClick={moveBack(path, setPath)}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </BackButton>
            </If>

            <IconButton
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </NavigationWrapper>
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                {_.map(columns, (column) => (<TableCellContent>{column}</TableCellContent>))}
              </TableRow>
            </TableHead>
            <TableBody>{renderContent(currentData, path, setNewPath)}</TableBody>
          </StyledTable>
        </StyledDialogContent>
      </Dialog>
    </>

  );
}

InnerSchemaDialog.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.array,
    valueType: PropTypes.string,
    code: PropTypes.number,
  }),
  name: PropTypes.string,
};

InnerSchemaDialog.defaultProps = {
  data: {},
  name: '',
};

export default InnerSchemaDialog;
