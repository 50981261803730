import users from './users';
import groups from './groups';

const entities = [users, groups];

const api = {
  name: 'scimApi',
  type: 'REST',
  disableSearch: false,
  defaultRoute: '/scim',
  overview: [
    {
      id: 300,
      name: 'Introduction',
      path: '/scim',
    },
  ],
  entities,
};

export default api;
