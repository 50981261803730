module.exports = {
  eq: 'eq',
  ne: 'ne',
  contains: 'contains',
  notContains: 'not_contains',
  startWith: 'start_with',
  inGroups: 'in_groups',
  notInGroups: 'not_in_groups',
  inCompany: 'in_company',
  notInCompany: 'not_in_company',
  inRole: 'in_role',
  notInRole: 'not_in_role',
  isLess: 'is_less',
  isMore: 'is_more',
  isBetween: 'is_between',
  any: 'any',
  exceeded: 'exceeded',
  notExceeded: 'not_exceeded',
  inOrganisations: 'in_organisations',
};
