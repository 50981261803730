export default {
  set: 'set',
  cleared: 'cleared',
  eq: 'eq',
  any: 'any',
  changed: 'changed',
  anyOf: 'any_of',
  noneOf: 'none_of',
  hasValue: 'has_value',
  hasLength: 'has_length',
  hasAverageValue: 'has_average_value',
};
