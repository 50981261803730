import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Typography, Button, Container, Box,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { get as registryGet } from '../lib/appRegistry';

const StyledContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});
const Wrapper = styled(Box)({
  height: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
const MainText = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    textAlign: 'center',
  }),
);
const StyledButton = styled(Button)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    fontSize: 20,
    alignItems: 'center',
    color: theme.palette.text.primary,
    '&&:hover': {
      color: theme.palette.primary.main,
    },
  }),
);
const StyledIcon = styled(HomeIcon)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
);

function NotFoundPage({ to }) {
  const { logoUrl } = registryGet('config');
  return (
    <StyledContainer sx={{
    }}
    >
      <Wrapper>
        <img className="logo-404" src={logoUrl} alt="logo" />
        <MainText variant="h5">
          Sorry, the requested page was not found 😔
        </MainText>
        <StyledButton
          to={to}
          component={Link}
        >
          <StyledIcon />
          Home
        </StyledButton>
      </Wrapper>
    </StyledContainer>

  );
}

NotFoundPage.propTypes = {
  to: PropTypes.string.isRequired,
};

export default NotFoundPage;
