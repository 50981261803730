import React from 'react';
import {
  Typography, Link as MuiLink,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import hljs from 'highlight.js';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import Footer from '../../Footer';
import { Wrapper, SectionTitleWrapper } from '../../../lib/Components/StyledComponents';

const example = `Addon.initialize({
  card_buttons: (buttonsContext) => {
    return [
      text: 'My first button',
    ]
  },
  settings: (settingsContext) => {},
  card_body_section: (bodySectionContext) => {
    return [];
  },
  card_facade_badges: () => null,
});`;

function Introduction() {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title="Addon capabilities" hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom>
          The following capabilities are available:
        </Typography>
        <ul>
          <li>
            <Typography gutterBottom>
              <MuiLink color="secondary" component={RouterLink} to="/addons/capabilities/settings">
                settings
              </MuiLink>
              <b />
              {' '}
              - Addon settings in the space
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              <MuiLink color="secondary" component={RouterLink} to="/addons/capabilities/card-buttons">
                card_buttons
              </MuiLink>
              {' '}
              - Addon buttons/actions
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              <MuiLink color="secondary" component={RouterLink} to="/addons/capabilities/card-body-section">
                card_body_section
              </MuiLink>
              {' '}
              - iframe in the body of the open card
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              <MuiLink color="secondary" component={RouterLink} to="/addons/capabilities/card-facade-badges">
                card_facade_badges
              </MuiLink>
              {' '}
              - Card facade badges
            </Typography>
          </li>
        </ul>
        <Typography gutterBottom>
          These capabilities are keys of the object passed to the function
          {' '}
          <MuiLink color="secondary" component={RouterLink} to="/addons/initialization/addon-initialize">
            {'Addon.initialize({})'}
          </MuiLink>
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
        </Snippet>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Introduction;
