import React from 'react';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Typography,
  Box,
} from '@mui/material';
import { If, Then } from 'react-if';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import SectionTitle from './SectionTitle';
import InnerSchemaDialog from './AdditionalTable.js/InnerShema';

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const Description = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const Text = styled(Typography)({
  fontSize: '0.875rem',
});

const StyledTable = styled(Table)({
  minWidth: 600,
});

const Container = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const InnerSchemaWrapper = styled(Box)(
  ({ theme }) => ({
    marginLeft: theme.spacing(-1),
  }),
);

function renderTableCell(cellData) {
  if (_.isPlainObject(cellData)) {
    return (
      <InnerSchemaWrapper>
        <InnerSchemaDialog data={cellData.schema} name={cellData.name} />
      </InnerSchemaWrapper>
    );
  }
  if (_.isArray(cellData)) {
    return `[\n ${_.join(cellData, ',\n ')}\n]`;
  }
  return cellData;
}

function prepareSectionHash(tableName) {
  return `#${_.join(_.split(tableName, ' '), '_')}`;
}

function renderContent(columns, data) {
  return (_.map(data, (dataRow) => (
    <TableRow>
      {_.map(columns, (column, index) => (
        <TableCellContent>
          <Text component="pre">
            {renderTableCell(dataRow[index])}
          </Text>
        </TableCellContent>
      ))}
    </TableRow>
  )));
}

function renderTable(tableData) {
  const {
    tableName,
    columns,
    data,
    description,
  } = tableData;
  return (
    <Container elevation={0}>
      <TableHeader>
        <SectionTitle title={tableName} hash={prepareSectionHash(tableName)} />
      </TableHeader>
      <If condition={description}>
        <Then>
          <Description>{description}</Description>
        </Then>
      </If>
      <TableContainer>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              {_.map(columns, (column) => (<TableCellContent>{column}</TableCellContent>))}
            </TableRow>
          </TableHead>
          <TableBody>{renderContent(columns, data)}</TableBody>
        </StyledTable>
      </TableContainer>
    </Container>
  );
}

function AdditionalTables({ tables }) {
  return (
    <>{_.map(tables, (tableData) => renderTable(tableData))}</>
  );
}

AdditionalTables.propTypes = {
  tables: PropTypes.array,
};

AdditionalTables.defaultProps = {
  tables: [],
};

export default AdditionalTables;
