import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Typography,
  Box,
} from '@mui/material';
import _ from 'lodash';
import { If } from 'react-if';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import SectionTitle from './SectionTitle';
import InnerSchemaDialog from './Responses/InnerSchemaDialog';
import { get as registryGet } from '../lib/appRegistry';

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const InnerSchemaWrapper = styled(Box)(
  ({ theme }) => ({
    marginLeft: theme.spacing(-1),
  }),
);
const StyledTable = styled(Table)({
  minWidth: 600,
});

const renderContent = (res) => _.map(res.body, ({
  name, type, description, schema,
}) => {
  const { projectName } = registryGet('config');
  return (
    <TableRow key={name}>
      <TableCellContent>{name}</TableCellContent>
      <TableCellContent>
        <Typography variant="body2">{type}</Typography>
        <If condition={schema}>
          <InnerSchemaWrapper>
            <InnerSchemaDialog data={res} name={name} />
          </InnerSchemaWrapper>
        </If>
      </TableCellContent>
      <TableCellContent>{_.replace(description, /{{projectName}}/gm, projectName)}</TableCellContent>
    </TableRow>
  );
});

function ResponseDetails({ res }) {
  return (
    <Paper elevation={0}>
      <TableHeader>
        <SectionTitle title="Response Attributes" hash="#resAttributes" />
      </TableHeader>
      <TableContainer>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCellContent>Name</TableCellContent>
              <TableCellContent>Type</TableCellContent>
              <TableCellContent>Description</TableCellContent>
            </TableRow>
          </TableHead>
          <TableBody>{renderContent(res)}</TableBody>
        </StyledTable>
      </TableContainer>

    </Paper>
  );
}

ResponseDetails.propTypes = {
  res: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.array,
    valueType: PropTypes.string,
    code: PropTypes.number,
  }),
};

ResponseDetails.defaultProps = {
  res: null,
};

export default ResponseDetails;
