import React from 'react';
import _ from 'lodash';
import {
  Box,
  Link,
  Breadcrumbs as MuiBreadCrumbs,
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const ButtonLink = styled(Link)(
  () => ({
    fontSize: 14,
    '&[disabled]': {
      color: grey[400],
      cursor: 'default',
    },
  }),
);

const StyledBreadCrumbs = styled(MuiBreadCrumbs)({
  width: 'calc(100% - 150px)',

});

const renderBreadCrumbs = (path, setPath) => {
  if (!path) return;

  const routePath = [];
  return _.map(path, (part, index) => {
    routePath.push(part);
    const currentPath = [...routePath];
    const isNavigatable = index < path.length - 1;
    return (
      <ButtonLink
        onClick={isNavigatable ? () => setPath([...currentPath]) : _.noop}
        color={grey[700]}
        component="button"
        disabled={!isNavigatable}
        underline={isNavigatable ? 'always' : 'none'}
      >
        {_.isPlainObject(part) ? part.displayName : part}
      </ButtonLink>
    );
  });
};

function BreadCrumbs({
  path, setPath,
}) {
  return (
    <Box>
      <StyledBreadCrumbs>
        {renderBreadCrumbs(path, setPath)}
      </StyledBreadCrumbs>
    </Box>
  );
}

BreadCrumbs.propTypes = {
  path: PropTypes.array.isRequired,
  setPath: PropTypes.func.isRequired,
};

export default BreadCrumbs;
