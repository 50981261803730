import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { If } from 'react-if';

import _ from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Paper,
} from '@mui/material';
import SectionTitle from './SectionTitle';

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const StyledTable = styled(Table)(
  () => ({
    minWidth: 600,
  }),
);

const renderHeaders = (headers, headersDecriptions) => {
  const headersArr = _.keys(headers);
  return headersArr.map((header) => (
    <TableRow key={header}>
      <TableCellContent>{header}</TableCellContent>
      <TableCellContent>{headers[header]}</TableCellContent>
      <If condition={headersDecriptions[header]}>
        <TableCellContent>{headersDecriptions[header]}</TableCellContent>
      </If>
    </TableRow>
  ));
};

function HeaderList({ headers, headersDecriptions, sx }) {
  return (
    <Paper elevation={0} sx={{ ...sx }}>
      <TableHeader>
        <SectionTitle title="Headers" hash="#headers" />
      </TableHeader>
      <TableContainer>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCellContent>Name</TableCellContent>
              <TableCellContent>Value</TableCellContent>
              <If condition={!_.isEmpty(headersDecriptions)}>
                <TableCellContent>Description</TableCellContent>
              </If>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderHeaders(headers, headersDecriptions)}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Paper>

  );
}

HeaderList.propTypes = {
  headers: PropTypes.object,
  headersDecriptions: PropTypes.object,
  sx: PropTypes.object,
};

HeaderList.defaultProps = {
  headers: {},
  headersDecriptions: {},
  sx: {},
};

export default HeaderList;
