import _ from 'lodash';
import triggerTypes from './automationTriggerTypes';
import conditionTypes from './automationConditionTypes';
import automationTypes from './automationTypes';
import getConditionData from './automationConditionData';
import getActionsData from './automationActionsData';

const eventAutomations = [
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,
    ],
    type: triggerTypes.cardCreated,
    name: 'Card created',
    actions: getActionsData(automationTypes.onAction, triggerTypes.cardCreated),
    description: 'Triggered when card created',
    key: triggerTypes.cardCreated,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.cardCreated}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'true', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.newPath,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,
    ],
    name: 'Card moved to',
    actions: getActionsData(automationTypes.onAction, triggerTypes.cardMovedInPath),
    key: triggerTypes.cardMovedInPath,
    type: triggerTypes.cardMovedInPath,
    description: 'Triggered when card moved',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.cardMovedInPath}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.newPath,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    name: 'Card is moved to or created',
    actions: getActionsData(automationTypes.onAction, triggerTypes.cardMovedInPath),
    key: 'card_is_moved_to_or_created',
    type: triggerTypes.cardMovedInPath,
    description: 'Triggered when card moved or created',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.cardMovedInPath}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'true', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.newCardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.path,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.cardTypeChanged,
    actions: getActionsData(automationTypes.onAction, triggerTypes.cardTypeChanged),
    name: 'Card type is changed',
    key: triggerTypes.cardTypeChanged,
    description: 'Triggered when card type changed',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.cardTypeChanged}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.cardUserAdded,
    actions: getActionsData(automationTypes.onAction, triggerTypes.cardUserAdded),
    name: 'Card member added',
    description: 'Triggered when card member added',
    key: triggerTypes.cardUserAdded,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.cardUserAdded}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'userIds', entries: ['userIds', 'array', '', 'yes', 'Users ids. the trigger should take into account users from the list '] },
              ],
            },
          },
        },
      },
    },
  },
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.responsibleAdded,
    actions: getActionsData(automationTypes.onAction, triggerTypes.responsibleAdded),
    name: 'Card responsible member added',
    key: triggerTypes.responsibleAdded,
    description: 'Triggered when responsible added',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.responsibleAdded}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', '', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'userIds', entries: ['userIds', 'array', '', 'yes', 'Users ids. the trigger should take into account users from the list '] },
              ],
            },
          },
        },
      },
    },
  },
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.checklistsCompleted,
    actions: getActionsData(automationTypes.onAction, triggerTypes.checklistsCompleted),
    name: 'All checklists in a card are completed',
    description: 'Triggered when all checklist in card are completed',
    key: triggerTypes.checklistsCompleted,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.checklistsCompleted}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.checklistItemText,
      conditionTypes.checklistItemDueDate,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.checklistItemChecked,
    actions: getActionsData(automationTypes.onAction, triggerTypes.checklistItemChecked),
    name: 'Checklist item is checked',
    description: 'Triggered when checklist item checked',
    key: triggerTypes.checklistItemChecked,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.checklistItemChecked}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.comment,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.commentPosted,
    actions: getActionsData(automationTypes.onAction, triggerTypes.commentPosted),
    name: 'Comment is posted to a card',
    description: 'Triggered when card posted to card',
    key: triggerTypes.commentPosted,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.commentPosted}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.targetTag,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.tagAdded,
    actions: getActionsData(automationTypes.onAction, triggerTypes.tagAdded),
    name: 'Tag added',
    description: 'Triggered when tag added',
    key: triggerTypes.tagAdded,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.tagAdded}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.targetTag,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.tagRemoved,
    actions: getActionsData(automationTypes.onAction, triggerTypes.tagRemoved),
    name: 'Tag removed',
    description: 'Triggered when tag removed',
    key: triggerTypes.tagRemoved,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.tagRemoved}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.newCardState,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.cardStateChanged,
    actions: getActionsData(automationTypes.onAction, triggerTypes.cardStateChanged),
    name: 'Card state is changed',
    description: 'Triggered when card state changed',
    key: triggerTypes.cardStateChanged,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.cardStateChanged}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.targetCustomProperty,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.customPropertyChanged,
    actions: getActionsData(automationTypes.onAction, triggerTypes.customPropertyChanged),
    name: 'Property is changed',
    description: 'Triggered when property changed',
    key: triggerTypes.customPropertyChanged,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', `${triggerTypes.customPropertyChanged}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.newDueDate,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.dueDateChanged,
    actions: getActionsData(automationTypes.onAction, triggerTypes.dueDateChanged),
    name: 'Due date is changed',
    key: triggerTypes.dueDateChanged,
    description: 'Triggered when due date changed',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.dueDateChanged}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.newChildCardsState,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.childCardsStateChanged,
    actions: getActionsData(automationTypes.onAction, triggerTypes.childCardsStateChanged),
    name: 'Child cards state is changed',
    description: 'Triggered when child card state changed',
    key: triggerTypes.childCardsStateChanged,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.childCardsStateChanged}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.blocked,
    actions: getActionsData(automationTypes.onAction, triggerTypes.blocked),
    name: 'Card is blocked',
    description: 'Triggered when card blocked',
    key: triggerTypes.blocked,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.blocked}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.unblocked,
    actions: getActionsData(automationTypes.onAction, triggerTypes.unblocked),
    name: 'Card is unblocked',
    key: triggerTypes.unblocked,
    description: 'Triggered when card unblocked',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.unblocked}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.childCardsState,
      conditionTypes.customProperty,
      conditionTypes.dueDate,
      conditionTypes.updater,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.blockerAdded,
    actions: getActionsData(automationTypes.onAction, triggerTypes.blockerAdded),
    name: 'Blocker is added to a card',
    description: 'Triggered when blocker is added to a card',
    key: triggerTypes.blockerAdded,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.blockerAdded}`, 'yes', ''] },
          { key: 'hasToFireOnCardCreation', entries: ['hasToFireOnCardCreation', 'boolean', 'false', 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'no', 'Data not need for this type'] },
        ],
      },
    },
  },
];
const onDateAutomation = [
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.childCardsState,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.dueDateOnDate,
    actions: getActionsData(automationTypes.onDate, triggerTypes.dueDateOnDate),
    name: 'Card has a due date',
    description: 'Trigger if card has due date and its date meets the conditions',
    key: triggerTypes.dueDateOnDate,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.dueDateOnDate}`, 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'timezone', entries: ['timezone', 'string', '', 'yes', ' Example: "UTC" '] },
                { key: 'variant', entries: ['variant', 'enum', ['date_moment_came', 'date_day_came', 'time_left_before_date', 'time_passed_after_date'], 'yes', '"date_moment_came" - the date has come,\n "date_day_came" - the day of date has come,\n "time_left_before_date" - left before the date,\n "time_passed_after_date" - After the date has passed'] },
                { key: 'offset', entries: ['offset', 'number', '', 'only for variants: \n"time_left_before_date",\n "time_passed_after_date"', 'define deviation from date'] },
                { key: 'offset_units', entries: ['offset_units', 'enum', ['hour', 'day'], 'only for variants: \n "time_left_before_date",\n "time_passed_after_date"', 'define deviation units'] },
                { key: 'exact_time', entries: ['exact_time', 'string', '', 'no', 'for variants: \n "time_left_before_date",\n "time_passed_after_date"\n "date_day_came".\nSet if you need exact time. Example: Example: "21:00"'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    conditions: [
      conditionTypes.checklistItemCheckedState,
      conditionTypes.checklistItemText,
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.childCardsState,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.checklistItemDueDateOnDate,
    actions: getActionsData(automationTypes.onDate, triggerTypes.checklistItemDueDateOnDate),
    name: 'Checklist item has a due date',
    key: triggerTypes.checklistItemDueDateOnDate,
    description: 'Trigger if checklist has due date and its date meets the conditions',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.checklistItemDueDateOnDate}`, 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Required', 'Description'],
              data: [
                { key: 'timezone', entries: ['timezone', 'string', '', 'yes', ' Example: "UTC" '] },
                { key: 'variant', entries: ['variant', 'enum', ['date_day_came', 'time_left_before_date', 'time_passed_after_date'], 'yes', '"date_day_came" - the day of date has come,\n "time_left_before_date" - left before the date,\n "time_passed_after_date" - After the date has passed'] },
                { key: 'offset', entries: ['offset', 'number', '', 'only for variants: \n"time_left_before_date",\n "time_passed_after_date"', 'define deviation from date'] },
                { key: 'offset_units', entries: ['offset_units', 'enum', ['hour', 'day'], 'only for variants: \n "time_left_before_date",\n "time_passed_after_date"', 'define deviation units'] },
                { key: 'exact_time', entries: ['exact_time', 'string', '', 'no', 'for variants: \n "time_left_before_date",\n "time_passed_after_date"\n "date_day_came".\nSet if you need exact time. Example: Example: "21:00"'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    conditions: [
      conditionTypes.path,
      conditionTypes.cardType,
      conditionTypes.cardState,
      conditionTypes.tag,
      conditionTypes.customProperty,
      conditionTypes.childCardsState,
      conditionTypes.cardIsRequest,
      conditionTypes.cardAuthor,
      conditionTypes.blockStatus,
      conditionTypes.relations,
      conditionTypes.service,
      conditionTypes.size,

    ],
    type: triggerTypes.cpDateOnDate,
    actions: getActionsData(automationTypes.onDate, triggerTypes.cpDateOnDate),
    name: 'Field with the «Date» type has a date',
    description: 'Trigger if «Date» type property has date and its date meets the conditions',
    key: triggerTypes.cpDateOnDate,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${triggerTypes.cpDateOnDate}`, 'yes', ''] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Required', 'Description'],
              data: [
                { key: 'timezone', entries: ['timezone', 'string', '', 'yes', ' Example: "UTC" '] },
                { key: 'variant', entries: ['variant', 'enum', ['date_moment_came', 'date_day_came', 'time_left_before_date', 'time_passed_after_date'], 'yes', '"date_moment_came" - the date has come,\n "date_day_came" - the day of date has come,\n "time_left_before_date" - left before the date,\n "time_passed_after_date" - After the date has passed'] },
                { key: 'offset', entries: ['offset', 'number', '', 'only for variants: \n"time_left_before_date",\n "time_passed_after_date"', 'define deviation from date'] },
                { key: 'offset_units', entries: ['offset_units', 'enum', ['hour', 'day'], 'only for variants: \n "time_left_before_date",\n "time_passed_after_date"', 'define deviation units'] },
                { key: 'exact_time', entries: ['exact_time', 'string', '', 'no', 'for variants: \n "time_left_before_date",\n "time_passed_after_date"\n "date_day_came".\nSet if you need exact time. Example: Example: "21:00"'] },
                { key: 'propertyId', entries: ['propertyId', 'integer', '', 'yes', 'Property id'] },
              ],
            },
          },
        },
      },
    },
  },
];

const getTriggerData = (automationType) => {
  const attributes = {};
  const conditions = {};
  const actions = {};
  const tableData = [];
  const data = automationType === automationTypes.onAction ? eventAutomations : onDateAutomation;
  _.forEach(data, (triggerData) => {
    tableData.push({
      key: triggerData.key,
      entries: [triggerData.type, 'object', 'conditions', 'actions', triggerData.name, triggerData.description || ''],
    });
    attributes[triggerData.key] = triggerData.attributes;
    conditions[triggerData.key] = getConditionData(triggerData.conditions);
    actions[triggerData.key] = triggerData.actions;
  });
  return {
    schema: {
      columns: ['Type', 'Attributes', 'Allowed condition types', 'Allowed actions types', 'Name', 'Description'],
      data: tableData,
      linkField: ['Attributes', 'Allowed condition types', 'Allowed actions types'],
      postfix: {
        attributes: 'attributes',
        allowed_condition_types: 'conditions',
        allowed_actions_types: 'actions',
      },
      objectProperties: {
        attributes,
        allowed_condition_types: conditions,
        allowed_actions_types: actions,
      },
    },
    name: 'triggers',
  };
};

export default getTriggerData;
