import users from './users.json';
import getUsersResponse from './responseExamples/getUsers.json';
import getUserResponse from './responseExamples/getUser.json';
import createUser from './responseExamples/createUser.json';
import updateUser from './responseExamples/updateUser.json';

users.operations[0].responses[0].example = getUsersResponse;
users.operations[1].responses[0].example = getUserResponse;
users.operations[2].responses[0].example = createUser;
users.operations[3].responses[0].example = updateUser;

export default users;
