import groupEntities from './groupEntities.json';
import createSchema from '../schema/create/groupEntity.json';
import updateSchema from '../schema/update/groupEntity.json';
import postResponse from './responseExamples/200/post.json';
import removeResponse from './responseExamples/200/remove.json';
import getListResponse from './responseExamples/200/getList.json';
import requestExamples from './requestExamples.json';

groupEntities.operations[0].request.schema = createSchema;
groupEntities.operations[2].request.schema = updateSchema;

groupEntities.operations[0].responces[0].example = postResponse;
groupEntities.operations[1].responces[0].example = getListResponse;
groupEntities.operations[2].responces[0].example = postResponse;
groupEntities.operations[3].responces[0].example = removeResponse;

groupEntities.operations[0].request.examples = requestExamples;

export default groupEntities;
