import React from 'react';
import {
  Typography, Link as MuiLink,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import hljs from 'highlight.js';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import { get as registryGet } from '../../../lib/appRegistry';
import Footer from '../../Footer';
import { Wrapper, SectionTitleWrapper } from '../../../lib/Components/StyledComponents';

const example = `Addon.initialize({
  card_buttons: () => {
    return [
      text: 'My first button',
    ]
  },
});`;

function AddonInitialize() {
  const { projectName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title="Addon.initialize()" hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom>
          Initiates addon initialization. Called once in the main entry point (iframe_connector_url)
        </Typography>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          Accepts an object whose keys represent
          {' '}
          <MuiLink color="secondary" component={RouterLink} to="/addons/capabilities/introduction">
            plugin capabilities.
          </MuiLink>
          {' '}
          { `The object's values are functions that return an array with resulting objects used in ${projectName} for rendering a specific section`}
        </Typography>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          Example :
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
        </Snippet>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default AddonInitialize;
