import React from 'react';
import {
  Typography, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import hljs from 'highlight.js';
import NativeScrollContainer from '../NativeScrollContainer';
import Snippet from '../Snippet';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);
const BoldText = styled(Typography)({
  marginLeft: '3px',
  marginRight: '3px',
  fontWeight: 'bold',
});
const ItalicText = styled(Typography)({
  fontWeight: 'italic',
});
const MethodWrapper = styled(Box)({
  marginLeft: '4px',
  marginRight: '4px',
});
const StyledListItem = styled(Box)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);
const examples = {
  add: `{
    "event": action,
    "data": {
      "author": {...},
      ... // model keys, see examples below
    },
}`,
  update: `{
    "event": action,
    "data": {
      "old": {...},
      "changes": {...},
      "author": {...}
    }
}`,
  remove: `{
    "event": action,
    "data": {
      "author": {...},
      ... // model keys, see examples below
    }
}`,
};

function Introduction() {
  const { projectName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <Typography gutterBottom variant="h4" component="h1">
          {`${projectName} External Webhooks`}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          You can use webhooks to notify your app or web application about certain
          events occured in spaces with activated external webhook.
          For example, you want to alert your application when card
          in particular space is updated or a comment to card is added.
          By using webhooks you can make fewer API calls and make sure your apps are more efficient.
        </Typography>
        <Typography gutterBottom variant="h5" component="h1">
          Webhook installation
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          <Typography component="span">
            {`Creating a webhook is a two-step process. First you have to activate webhook in ${projectName}`}
          </Typography>
          <ItalicText component="span">(you can set up different webhooks for each space)</ItalicText>
          , and after that you have to set up your server to receive and manage the payload.
          <Typography component="span">{`To activate webhook in particular space in ${projectName} go to`}</Typography>
          <BoldText component="span" variant="subtitle1">space setting,</BoldText>
          choose
          <BoldText component="span" variant="subtitle1">external webhooks</BoldText>
          in Menu and type your URL, that will receive the webhook
          <MethodWrapper component="span">
            <Snippet inline removeGutters>
              POST
            </Snippet>
          </MethodWrapper>
          requests. To register a webhook click create.
          By default newly created webhook is active, but you can always turn it off in menu.
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Once installed, the webhook notification will be triggered each
          time available events occur. This notification contains a JSON payload.
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          The structure is simple and clear:
        </Typography>
        <Box component="ul">
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              add events
            </Typography>
          </StyledListItem>
        </Box>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(examples.add, { language: 'json' }).value }} />
        </Snippet>
        <Box component="ul">
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              update events
            </Typography>
          </StyledListItem>
        </Box>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(examples.update, { language: 'json' }).value }} />
        </Snippet>
        <Box component="ul">
          <StyledListItem component="li">
            <Typography gutterBottom variant="subtitle1">
              remove events
            </Typography>
          </StyledListItem>
        </Box>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(examples.remove, { language: 'json' }).value }} />
        </Snippet>

        <Typography gutterBottom variant="subtitle1">
          So far we support actions belonging to
          Spaces, Boards, Cards, Blocks, Comments, Time Logs, Tags, Files.
        </Typography>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Introduction;
