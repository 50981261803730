import children from './cardChilderen.json';
import createSchema from '../schema/create/cardChildren.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

children.operations[0].request.schema = createSchema;

children.operations[0].responces[0].example = postResponse;
children.operations[1].responces[0].example = getListResponse;
children.operations[2].responces[0].example = deleteResponse;

export default children;
