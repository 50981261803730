import subColumns from './subcolumn.json';
import createSchema from '../schema/create/subcolumn.json';
import updateSchema from '../schema/update/subcolumn.json';
import deleteSchema from '../schema/delete/force.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

subColumns.operations[0].request.schema = createSchema;
subColumns.operations[2].request.schema = updateSchema;
subColumns.operations[3].request.schema = deleteSchema;

subColumns.operations[0].responces[0].example = postResponse;
subColumns.operations[1].responces[0].example = getListResponse;
subColumns.operations[2].responces[0].example = postResponse;
subColumns.operations[3].responces[0].example = deleteResponse;

export default subColumns;
