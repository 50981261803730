import React from 'react';
import {
  Typography,
  Paper,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { If, Then, Else } from 'react-if';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import NativeScrollContainer from './NativeScrollContainer';

const SnippetWrapperInline = styled(Paper)(
  ({ theme }) => ({
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
    marginBottom: theme.spacing(2),
    backgroundColor: grey[100],
    overflow: 'auto',
  }),
);
const SnippetWrapper = styled(Paper)(
  ({ theme }) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} 20px`,
    marginBottom: theme.spacing(2),
    backgroundColor: grey[100],
    overflow: 'auto',
  }),
);

function Snippet({ children, inline, removeGutters }) {
  return (
    <If condition={removeGutters}>
      <Then>
        <Box component={inline ? 'span' : 'div'}>
          <SnippetWrapperInline
            elevation={0}
            component={inline ? 'span' : undefined}
          >
            <Typography
              color="text.secondary"
              component={inline ? 'span' : 'div'}
            >
              {children}
            </Typography>
          </SnippetWrapperInline>
        </Box>

      </Then>
      <Else>
        <Box component={inline ? 'span' : 'div'}>
          <SnippetWrapper
            elevation={0}
            component={inline ? 'span' : undefined}
          >
            <NativeScrollContainer>
              <Typography
                color="text.secondary"
                component={inline ? 'span' : 'div'}
              >
                {children}
              </Typography>
            </NativeScrollContainer>
          </SnippetWrapper>
        </Box>

      </Else>
    </If>
  );
}
Snippet.propTypes = {
  children: PropTypes.any.isRequired,
  inline: PropTypes.bool,
  removeGutters: PropTypes.bool,
};

Snippet.defaultProps = {
  inline: false,
  removeGutters: false,
};

export default Snippet;
