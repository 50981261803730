import columns from './columns.json';
import createSchema from '../schema/create/column.json';
import updateSchema from '../schema/update/column.json';
import deleteSchema from '../schema/delete/force.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

columns.operations[0].request.schema = createSchema;
columns.operations[2].request.schema = updateSchema;
columns.operations[3].request.schema = deleteSchema;

columns.operations[0].responces[0].example = postResponse;
columns.operations[1].responces[0].example = getListResponse;
columns.operations[2].responces[0].example = postResponse;
columns.operations[3].responces[0].example = deleteResponse;

export default columns;
