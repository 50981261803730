import axios from 'axios';

const makeRequest = (method, url, headers = {}, body = {}, query = {}) => axios({
  method,
  headers,
  data: body,
  url,
  params: query,
});

export default makeRequest;
