export default {
  cardCreated: 'card_created',
  cardMovedInPath: 'card_moved_in_path',
  commentPosted: 'comment_posted',
  cardUserAdded: 'card_user_added',
  responsibleAdded: 'responsible_added',
  cardTypeChanged: 'card_type_changed',
  checklistsCompleted: 'checklists_completed',
  tagAdded: 'tag_added',
  tagRemoved: 'tag_removed',
  cardStateChanged: 'card_state_changed',
  customPropertyChanged: 'custom_property_changed',
  dueDateChanged: 'due_date_changed',
  childCardsStateChanged: 'child_cards_state_changed',
  checklistItemChecked: 'checklist_item_checked',
  dueDateOnDate: 'due_date_on_date',
  checklistItemDueDateOnDate: 'checklist_item_due_date_on_date',
  cpDateOnDate: 'custom_property_date_on_date',
  blocked: 'blocked',
  unblocked: 'unblocked',
  blockerAdded: 'blocker_added',
};
