import initialization from './initialization';
import capabilities from './capabilities';
import webSdkDataFunctions from './sdk data functions';
import webSdkUiFunctions from './sdk ui functions';

const entities = [initialization, capabilities, webSdkDataFunctions, webSdkUiFunctions];

const api = {
  name: 'Addons',
  type: 'Addons',
  disableSearch: false,
  defaultRoute: '/addons',
  overview: [
    {
      id: 1,
      name: 'Introduction',
      path: '/addons',
    },
    {
      id: 2,
      name: 'Creating example addon',
      path: '/addons/creating-example-addon',
    },
    {
      id: 3,
      name: 'Styling',
      path: '/addons/styling',
    },
  ],
  entities,
};

export default api;
