import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { If, Then } from 'react-if';
import _ from 'lodash';
import Search from './Search';
import NavLists from './NavLists';
import NativeScrollContainer from './NativeScrollContainer';
import NavTabs from './NavTabs';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    borderBottom: 1,
    borderColor: 'divider',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      margin: `18px ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,

    },
  }),
);
const NavWrapper = styled('div')(
  ({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }),
);
const findRoutes = (searchParam, routes) => {
  const {
    api: { entities, overview },
  } = routes;
  const normalizedParam = _.trim(_.toLower(searchParam));
  if (!normalizedParam) return { entities, overview };
  const entitiesResult = _.reduce(
    entities,
    (acc, entity) => {
      if (_.includes(_.toLower(entity.name), normalizedParam)) {
        acc.push({ name: entity.name, operations: entity.operations });
        return acc;
      }
      const filteredOperations = _.filter(entity.operations, ({ name }) => (
        _.includes(_.toLower(name), normalizedParam)));
      if (filteredOperations.length > 0) {
        acc.push({ name: entity.name, operations: filteredOperations });
      }
      return acc;
    },
    [],
  );

  const overviewResult = _.includes(_.toLower('overview'), normalizedParam)
    ? overview
    : _.filter(overview, ({ name }) => _.includes(_.toLower(name), normalizedParam));
  return {
    entities: entitiesResult,
    overview: overviewResult,
  };
};

function Api({
  toggleNavList, scrollClassName, scroll,
  apies, showTabs, handleTabs, currentApi, apiKeys,
}) {
  const [searchValue, setSearchValue] = useState('');
  const routesData = apies[currentApi];

  const changeTerm = (e) => {
    setSearchValue(e.target.value);
  };
  const prefix = routesData.prefix || '';
  const currentLinks = findRoutes(routesData.api.disableSearch ? '' : searchValue, routesData);

  return (
    <>
      <If condition={showTabs}>
        <Wrapper>
          <NavTabs
            isScrollable
            value={currentApi}
            onChange={handleTabs}
            keys={apiKeys}
          />
        </Wrapper>

      </If>
      <If condition={!routesData.api.disableSearch}>
        <Then>
          <Search
            handleChange={changeTerm}
            value={searchValue}
          />
        </Then>
      </If>
      <NativeScrollContainer className={scrollClassName || 'nav-desktop'}>
        <NavWrapper>
          <NavLists
            prefix={prefix}
            links={currentLinks}
            match={searchValue}
            scroll={scroll}
            toggleNavList={toggleNavList}
          />
        </NavWrapper>
      </NativeScrollContainer>
    </>
  );
}

Api.propTypes = {
  scroll: PropTypes.bool.isRequired,
  apies: PropTypes.object.isRequired,
  showTabs: PropTypes.bool.isRequired,
  handleTabs: PropTypes.func.isRequired,
  currentApi: PropTypes.string.isRequired,
  apiKeys: PropTypes.array.isRequired,
  scrollClassName: PropTypes.string,
  toggleNavList: PropTypes.func,
};

Api.defaultProps = {
  scrollClassName: '',
  toggleNavList: _.noop,
};

export default Api;
