import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Footer from './Footer';

function WithFooter({ children, minHeight }) {
  return (
    <Box sx={{ minHeight, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mb: '100px' }}>
        {children}
      </Box>
      <Footer sx={{ mt: 'auto' }} />
    </Box>
  );
}

WithFooter.propTypes = {
  children: PropTypes.any.isRequired,
  minHeight: PropTypes.string.isRequired,
};

export default WithFooter;
