import companyUsers from './companyUsers.json';
import updateShema from '../schema/update/companyUsers.json';
import updateResponse from './responseExamples/200/update.json';
import getListResponse from './responseExamples/200/getList.json';
import requestExamples from './requestExamples.json';

companyUsers.operations[1].request.schema = updateShema;

companyUsers.operations[0].responces[0].example = getListResponse;
companyUsers.operations[1].responces[0].example = updateResponse;

companyUsers.operations[1].request.examples = requestExamples;

export default companyUsers;
