const api = {
  name: 'webhooks',
  type: 'WEBHOOK',
  disableSearch: true,
  defaultRoute: '/webhooks',
  overview: [
    {
      id: 300,
      name: 'Introduction',
      path: '/webhooks',
    },
  ],
  entities: [{
    name: 'Integrations',
    operations: [{
      name: 'Sentry',
    }],
  }],
};

export default api;
