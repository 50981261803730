import groupUsers from './groupUsers.json';
import createSchema from '../schema/create/groupUsers.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';

groupUsers.operations[0].request.schema = createSchema;

groupUsers.operations[0].responces[0].example = postResponse;
groupUsers.operations[1].responces[0].example = getListResponse;
groupUsers.operations[2].responces[0].example = postResponse;

export default groupUsers;
