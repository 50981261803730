import scoreValue from './customPropertyCollectiveScore.json';
import createSchema from '../schema/create/customPropertyCollectiveScore.json';
import updateSchema from '../schema/update/customPropertyCollectiveScore.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';

scoreValue.operations[0].request.schema = createSchema;
scoreValue.operations[2].request.schema = updateSchema;

scoreValue.operations[0].responces[0].example = postResponse;
scoreValue.operations[1].responces[0].example = getListResponse;
scoreValue.operations[2].responces[0].example = postResponse;

export default scoreValue;
