import items from './templateChecklistsItems.json';
import createSchema from '../schema/create/policyItem.json';
import updateSchema from '../schema/update/policyItem.json';
import postResponse from './responseExamples/200/post.json';
import deleteResponse from './responseExamples/200/delete.json';

items.operations[0].request.schema = createSchema;
items.operations[1].request.schema = updateSchema;

items.operations[0].responces[0].example = postResponse;
items.operations[1].responces[0].example = postResponse;
items.operations[2].responces[0].example = deleteResponse;

export default items;
