import props from './customProp.json';
import createSchema from '../schema/create/customProperty.json';
import updateSchema from '../schema/update/customProperty.json';
import requestExamples from './requestExamples.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import getResponse from './responseExamples/200/get.json';
import deleteResponse from './responseExamples/200/delete.json';

props.operations[0].request.schema = createSchema;
props.operations[0].request.examples = requestExamples;
props.operations[3].request.schema = updateSchema;

props.operations[0].responces[0].example = postResponse;
props.operations[1].responces[0].example = getListResponse;
props.operations[2].responces[0].example = getResponse;
props.operations[3].responces[0].example = getResponse;
props.operations[4].responces[0].example = deleteResponse;

export default props;
