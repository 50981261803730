import {
  Box,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import React from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';
import NativeScrollContainer from '../NativeScrollContainer';
import SectionTitle from '../SectionTitle';
import Snippet from '../Snippet';
import Operation from './Operation';
import operationData from './operationData.json';
import requestExample from './requestExample.json';
import requestSchema from './requestSchema.json';
import response200Example from './response200Example.json';

import endpointNotFoundImg from '../../images/userMetadata_endpointNotFound.png';
import invalidDataImg from '../../images/userMetadata_invalidData.png';
import responseWithErrorImg from '../../images/userMetadata_responseWithError.png';
import setupConnectionImg from '../../images/userMetadata_setupConnection.png';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

const SectionTitleWrapper = styled('div')(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

const WrapperVariant = styled('div')(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  }),
);

const LinkWrapper = styled(Box)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'inline-block',
  }),
);

const customPropertiesLinks = [
  {
    url: '/custom-properties/get-list-of-properties',
    description: 'get custom_property_id',
    section: 'rest',
  },
  {
    url: '/cards/create-new-card#examples',
    description: 'custom_property_id examples',
    section: 'rest',
  },
];

const operation = operationData;

operation.request.examples = requestExample;
operation.request.schema = requestSchema;
operation.responces[0].example = response200Example;

function UserMetadata() {
  const { projectName } = registryGet('config');

  const {
    changeApi, setScroll,
  } = useOutletContext();

  const handleLinkClick = (link) => {
    changeApi(null, link.section, link.url);
    setScroll();
  };

  const renderLink = (onClick, link = {}) => (
    <LinkWrapper
      sx={{ display: 'inline-block', my: 1 }}
      onClick={() => onClick(link)}
    >
      <MuiLink color="secondary" component={RouterLink} to={link.url}>
        {link.description}
      </MuiLink>
    </LinkWrapper>
  );

  const renderLinks = (onClick, links = []) => {
    const mappedLinks = _.map(links, (link) => (
      <div key={link.url}>
        {renderLink(onClick, link)}
      </div>
    ));
    return <div>{mappedLinks}</div>;
  };

  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <Typography gutterBottom variant="h4" component="h1">
          User Metadata Integration
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          User metadata integration provides the ability to add user metadata
          created through the support service to cards.
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          {projectName}
          {' '}
          requests metadata from your custom metadata service.
        </Typography>
        <SectionTitleWrapper>
          <SectionTitle variant="h5" title="Connection setup" hash="#setup" />
        </SectionTitleWrapper>
        <Typography component="span" gutterBottom variant="subtitle1">
          To setup go to
          {' '}
          <Snippet inline removeGutters>
            {'Service Desk -> Settings'}
          </Snippet>
          {' '}
          and enter url and token in block
          {' '}
          <Snippet inline removeGutters>
            Meta-information receive settings
          </Snippet>
          .
          <WrapperVariant>
            <img width="400" className="jpg" src={setupConnectionImg} alt="setup connection for user metadata" />
          </WrapperVariant>
        </Typography>
        <SectionTitleWrapper>
          <SectionTitle variant="h5" title="Endpoint description" hash="#endpoint-description" />
        </SectionTitleWrapper>
        <Operation operation={operation} />
        <Typography gutterBottom variant="subtitle1">
          If the response contains attributes other than those specified, they will be ignored.
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          If the response contains non-validated data, the metadata will not be added.
        </Typography>
        {renderLinks(handleLinkClick, customPropertiesLinks)}
        <SectionTitleWrapper>
          <SectionTitle variant="h6" title="Error handling" hash="#" />
        </SectionTitleWrapper>
        <Typography gutterBottom variant="subtitle1">
          Responses with an HTTP code other than 200 are stored in a comment to the request.
          If the data from the response does not pass the check,
          then the error message is also stored in the note to the request:
        </Typography>
        <WrapperVariant>
          <img width="400" className="jpg" src={endpointNotFoundImg} alt="endpoint not found" />
          <img width="400" className="jpg" src={responseWithErrorImg} alt="response with error" />
          <img width="400" className="jpg" src={invalidDataImg} alt="invalid data" />
        </WrapperVariant>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default UserMetadata;
