import automationEventComparators from './automationEventComparators';

const variants = {
  isLess: 'is_less',
  lte: 'lte',
  eq: 'eq',
  gte: 'gte',
  isMore: 'is_more',
};

const variantsDescription = {
  isLess: 'Value must be less than',
  lte: 'Value must be less than or equal to',
  eq: 'Value must equal to',
  gte: 'Value must be greater than or equal to',
  isMore: 'Value must be greater than',
};

const availableComparators = [
  automationEventComparators.hasValue,
  automationEventComparators.hasAverageValue,
  automationEventComparators.hasLength,
];

const tableData = {
  tableName: 'comparator variants for comparator type',
  columns: ['Comparator variant', 'Available for comparators', 'Description'],
  data: [
    {
      key: variants.isLess,
      entries: [
        variants.isLess,
        availableComparators,
        variantsDescription.isLess,
      ],
    },
    {
      key: variants.lte,
      entries: [
        variants.lte,
        availableComparators,
        variantsDescription.lte,
      ],
    },
    {
      key: variants.eq,
      entries: [
        variants.eq,
        availableComparators,
        variantsDescription.eq,
      ],
    },
    {
      key: variants.gte,
      entries: [
        variants.gte,
        availableComparators,
        variantsDescription.gte,
      ],
    },
    {
      key: variants.isMore,
      entries: [
        variants.isMore,
        availableComparators,
        variantsDescription.isMore,
      ],
    },
  ],
};

export default tableData;
