import React from 'react';
import _ from 'lodash';
import { If, Then, Else } from 'react-if';
import PropTypes from 'prop-types';
import {
  Box, Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from './SectionTitle';
import Example from './requestExamples/Example';
import CodeExampleContainer from './requestExamples/CodeExampleContainer';

const Header = styled('div')(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const StyledDivider = styled(Divider)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
);

function RequestExamples({
  examples,
  sx = {},
  setBody,
  openEditor,
  setScroll,
  Component,
  basePath,
}) {
  const { items: groups } = examples;
  const ExampleComponent = Component || Example;

  if (groups.length === 0) {
    return null;
  }
  return (
    <Box sx={{ ...sx }}>
      <Header>
        <SectionTitle title="Examples" hash="#examples" />
      </Header>
      {_.map(groups, (group, index) => (
        <>
          <If condition={index > 0}>
            <StyledDivider />
          </If>
          <If condition={group.codeExample}>
            <Then>
              <CodeExampleContainer
                example={group}
                basePath={basePath}
              />
            </Then>
            <Else>
              <ExampleComponent
                key={group.id}
                examples={group}
                setBody={setBody}
                openEditor={openEditor}
                setScroll={setScroll}
              />
            </Else>
          </If>

        </>

      ))}
    </Box>
  );
}

RequestExamples.propTypes = {
  sx: PropTypes.object,
  openEditor: PropTypes.func,
  setBody: PropTypes.func,
  setScroll: PropTypes.func,
  Component: PropTypes.string,
  basePath: PropTypes.string,
  examples: PropTypes.shape({
    items: PropTypes.array,
    description: PropTypes.string,
    link: PropTypes.shape({
      description: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

RequestExamples.defaultProps = {
  sx: {},
  examples: {},
  basePath: '',
  Component: undefined,
  openEditor: _.noop,
  setBody: _.noop,
  setScroll: _.noop,
};

export default RequestExamples;
