import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useParams } from 'react-router-dom';
import {
  List,
  ListSubheader,
  ListItem,
  Typography,
  ListItemButton,
  Box,
  Badge,
} from '@mui/material';
import { If, Then, Else } from 'react-if';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import OperationIcon from './OperationIcon';
import beatifyRoute from '../utils/beatifyRoute';

const StyledList = styled(List)({
  overflow: 'hidden',
});
const StyledSubheader = styled(ListSubheader)({
  lineHeight: '24px',
  marginTop: '12px',
  marginBottom: '12px',
});
const OverviewButton = styled(ListItemButton)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    paddingLeft: '4px',
  }),
);
const EntityButton = styled(ListItemButton)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    paddingLeft: '4px',
    paddingRight: '5px',
    display: 'flex',
  }),
);
const Text = styled(Typography)({
  width: '65%',
});
const IconWrapper = styled('span')({
  marginLeft: 'auto',
});
const SubheaderWrapper = styled(Box)({
  display: 'inline-block',
});
const StyledBadge = styled(Badge)(
  ({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -14,
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  }),
);

const applyActive = ({ isActive }) => (isActive ? 'link activeLink' : 'link');

const renderOperations = (operations, entity, params) => {
  const {
    match, activeRoute, ref, handleClick, prefix,
  } = params;
  return operations.map(({ name, type, routeName }, index) => {
    const linkPath = `${prefix}/${beatifyRoute(entity, routeName || name)}`;
    const higligted = _.replace(
      name,
      new RegExp(match, 'gmi'),
      (regexMatch) => `<span class='yellow'>${regexMatch}</span>`,
    );
    return (
      <ListItem
        onClick={handleClick}
        sx={{ mb: index < operations.length - 1 ? '5px' : 0 }}
        key={name}
        disablePadding
      >
        <NavLink
          ref={linkPath === activeRoute ? ref : null}
          to={linkPath}
          className={applyActive}
        >
          <EntityButton
            disableGutters
            component="span"
          >
            <Text
              variant="body2"
              sx={{ width: '65%' }}
              dangerouslySetInnerHTML={{ __html: higligted }}
            />
            <If condition={type}>
              <Then>
                <IconWrapper>
                  <OperationIcon type={type} />
                </IconWrapper>
              </Then>
            </If>
          </EntityButton>
        </NavLink>
      </ListItem>
    );
  });
};

const renderEntities = (entities, params) => {
  const { match } = params;
  return entities.map(({ name, operations, isBeta = false }) => {
    const higligtedHeader = _.replace(
      name,
      new RegExp(match, 'gmi'),
      (regexMatch) => `<span class='yellow'>${regexMatch}</span>`,
    );
    return (
      <Box key={name}>
        <StyledSubheader
          disableGutters
        >
          <If condition={isBeta}>
            <Then>
              <StyledBadge badgeContent="BETA" color="secondary">
                <SubheaderWrapper dangerouslySetInnerHTML={{ __html: higligtedHeader }} />
              </StyledBadge>
            </Then>
            <Else>
              <SubheaderWrapper dangerouslySetInnerHTML={{ __html: higligtedHeader }} />
            </Else>
          </If>

        </StyledSubheader>
        {renderOperations(operations, name, params)}
      </Box>
    );
  });
};

const renderOverview = (overview, params) => {
  const {
    match, activeRoute, ref, handleClick,
  } = params;
  if (overview.length === 0) return null;
  const higligtedHeader = _.replace(
    'Overview',
    new RegExp(match, 'gmi'),
    (regexMatch) => `<span class='yellow'>${regexMatch}</span>`,
  );
  return (
    <>
      <StyledSubheader
        dangerouslySetInnerHTML={{ __html: higligtedHeader }}
        disableGutters
      />
      {overview.map(({ path, name }, index) => {
        const higligted = _.replace(
          name,
          new RegExp(match, 'gmi'),
          (regexMatch) => `<span class='yellow'>${regexMatch}</span>`,
        );
        return (
          <ListItem
            onClick={handleClick}
            sx={{ mb: index < overview.length - 1 ? '5px' : 0 }}
            key="id"
            disablePadding
          >
            <NavLink
              ref={path === activeRoute ? ref : null}
              end
              to={path}
              className={applyActive}
            >
              <OverviewButton
                variant="outlined"
                disableGutters
                component="span"
              >
                <Typography
                  dangerouslySetInnerHTML={{ __html: higligted }}
                  variant="body2"
                />
              </OverviewButton>
            </NavLink>
          </ListItem>
        );
      })}
    </>
  );
};

function NavList({
  links, match, toggleNavList, prefix = '', scroll,
}) {
  const activeRef = useRef();
  const { entity, operation } = useParams();
  const activeRoute = `${prefix}/${entity}/${operation}`;
  const { entities = [], overview } = links;
  const handleClick = () => toggleNavList && toggleNavList();
  useEffect(() => {
    activeRef.current && activeRef.current.scrollIntoView({ block: 'center' });
  }, [scroll]);
  const params = {
    match,
    activeRoute,
    ref: activeRef,
    handleClick,
    prefix,
  };
  return (
    <Box component="nav">
      <StyledList dense disablePadding sx={{ overflow: 'hidden' }}>
        {renderOverview(overview, params)}
        {renderEntities(entities, params)}
      </StyledList>
    </Box>
  );
}

NavList.propTypes = {
  scroll: PropTypes.bool.isRequired,
  match: PropTypes.string.isRequired,
  toggleNavList: PropTypes.func,
  prefix: PropTypes.string,
  links: PropTypes.shape({
    entities: PropTypes.array,
    overview: PropTypes.array,
  }).isRequired,
};

NavList.defaultProps = {
  toggleNavList: _.noop,
  prefix: '',
};

export default React.memo(NavList);
