import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const colorMapping = {
  POST: 'secondary',
  GET: 'primary',
  PATCH: 'warning',
  PUT: 'warning',
  DELETE: 'error',
};

function OperationIcon({ type }) {
  const color = colorMapping[type];
  return (
    <Chip size="small" variant="outlined" label={type} color={color} />
  );
}

OperationIcon.propTypes = {
  type: PropTypes.oneOf(['POST', 'GET', 'PATCH', 'DELETE', 'PUT']),
};
OperationIcon.defaultProps = {
  type: undefined,
};

export default OperationIcon;
