import spaceUsers from './spaceUsers.json';
import createSchema from '../schema/create/spaceUser.json';
import updateSchema from '../schema/update/spaceUser.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import getResponse from './responseExamples/200/get.json';
import updateResponse from './responseExamples/200/update.json';
import deleteResponse from './responseExamples/200/delete.json';
import requestExamples from './requestExamples.json';

spaceUsers.operations[0].request.schema = createSchema;
spaceUsers.operations[3].request.schema = updateSchema;

spaceUsers.operations[0].responces[0].example = postResponse;
spaceUsers.operations[1].responces[0].example = getListResponse;
spaceUsers.operations[2].responces[0].example = getResponse;
spaceUsers.operations[3].responces[0].example = updateResponse;
spaceUsers.operations[4].responces[0].example = deleteResponse;

spaceUsers.operations[0].request.examples = requestExamples;

export default spaceUsers;
