import lanes from './lanes.json';
import createSchema from '../schema/create/lane.json';
import updateSchema from '../schema/update/lane.json';
import deleteSchema from '../schema/delete/force.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

lanes.operations[0].request.schema = createSchema;
lanes.operations[2].request.schema = updateSchema;
lanes.operations[3].request.schema = deleteSchema;

lanes.operations[0].responces[0].example = postResponse;
lanes.operations[1].responces[0].example = getListResponse;
lanes.operations[2].responces[0].example = postResponse;
lanes.operations[3].responces[0].example = deleteResponse;

export default lanes;
