export default `{
  card: {
    create: true,
    update: true,
    read: true,
    delete: true
    comment: true,
    move: true,
    properties: true,
  },
  board: { 
    create: true,
    update: true,
    read: true,
    delete: true
  } 
}`;
