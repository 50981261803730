import React from 'react';
import {
  Typography,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  Alert,
  Paper,
} from '@mui/material';
import hljs from 'highlight.js';
import { get as registryGet } from '../../../lib/appRegistry';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import Footer from '../../Footer';
import {
  Wrapper,
  SectionTitleWrapper,
  MainContent,
  StyledTable,
  TableCellContent,
  Caption,
  InnerSchemaWrapper,
} from '../../../lib/Components/StyledComponents';
import InnerSchemaDialog from '../../Responses/InnerSchemaDialog';
import iframeCardImg from '../../../images/iframe_in_the_card.png';

const example = `'card_body_section': (bodySectionContext) => {
  return [{
    title: '📝 Timer logs',
    content: {
      type: 'iframe',
      url: bodySectionContext.signUrl('./timeLogs.html'),
      height: 200,
    }
  }]
},`;

const contentData = {
  body: [
    {
      name: 'content',
      type: 'object',
      schema: [
        {
          name: 'type',
          type: 'enum',
          description: "At the moment, only 'iframe' are supported",
        },
        {
          name: 'url',
          type: 'string',
          description: "A the address of the iframe. Note - this address must be prepared by the 'signUrl' function",
        },
        {
          name: 'height',
          type: 'number',
          description: 'iframe height',
        },
      ],
    },

  ],
};

function CardBodySection() {
  const { projectName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <MainContent>
          <SectionTitleWrapper>
            <SectionTitle variant="h4" title="card_body_section" hash="#header" />
          </SectionTitleWrapper>
          <Typography gutterBottom sx={{ marginBotton: 2 }}>
            Iframes that will be displayed in the body of the open card.
          </Typography>
          <img width="600" src={iframeCardImg} alt="iframe in the card" />
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            <b>Function argument</b>
            {' '}
            - an object that provides all available functions of the web SDK.
          </Typography>
          <Typography gutterBottom sx={{ marginTop: 2, marginBottom: 2 }}>
            <b>Return value</b>
            {' '}
            - the function should return array of objects with the following keys:
          </Typography>
          <Paper elevation={0}>
            <TableContainer>
              <StyledTable size="small">
                <TableHead>
                  <TableRow>
                    <TableCellContent>Key</TableCellContent>
                    <TableCellContent>Type</TableCellContent>
                    <TableCellContent>Description</TableCellContent>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellContent>
                      <b>title</b>
                      <Caption
                        variant="caption"
                        color="error"
                      >
                        required
                      </Caption>
                    </TableCellContent>
                    <TableCellContent>string</TableCellContent>
                    <TableCellContent>Section Title</TableCellContent>
                  </TableRow>
                  <TableRow>
                    <TableCellContent>
                      content
                      <Caption
                        variant="caption"
                        color="error"
                      >
                        required
                      </Caption>
                    </TableCellContent>
                    <TableCellContent>
                      object
                      <InnerSchemaWrapper>
                        <InnerSchemaDialog name="content" data={contentData} />
                      </InnerSchemaWrapper>
                    </TableCellContent>
                    <TableCellContent>Section parameters</TableCellContent>
                  </TableRow>
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Paper>
          <Alert sx={{ marginTop: 2 }} severity="info">
            {`For the content.url, it is mandatory to use the 'signUrl' function (in case the iframe will interact with ${projectName}).`}
          </Alert>
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            Example :
          </Typography>
          <Snippet>
            <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
          </Snippet>
        </MainContent>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default CardBodySection;
