import _ from 'lodash';
import customPropertyTypes from './customPropertyTypes';
import automationComparatorsForType from './automationComparatorsForType';

const getDescription = (types) => _.join(_.map(types, (type) => `${type} - ${automationComparatorsForType.descriptionForTargetProperty[type]}`), '\n');

const tableData = {
  columns: ['Custom property type', 'Comparators', 'Description'],
  data: [
    {
      key: customPropertyTypes.string,
      entries: [
        customPropertyTypes.string,
        automationComparatorsForType.comparatorsForTargetPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.multylineString,
      entries: [
        customPropertyTypes.multylineString,
        automationComparatorsForType.comparatorsForTargetProperty,
        getDescription(automationComparatorsForType.comparatorsForTargetProperty),
      ],
    },
    {
      key: customPropertyTypes.number,
      entries: [
        customPropertyTypes.number,
        automationComparatorsForType.comparatorsForTargetPropertyWithComparativeType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithComparativeType),
      ],
    },
    {
      key: customPropertyTypes.date,
      entries: [
        customPropertyTypes.date,
        automationComparatorsForType.comparatorsForTargetProperty,
        getDescription(automationComparatorsForType.comparatorsForTargetProperty),
      ],
    },
    {
      key: customPropertyTypes.email,
      entries: [
        customPropertyTypes.email,
        automationComparatorsForType.comparatorsForTargetPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.checkbox,
      entries: [
        customPropertyTypes.checkbox,
        automationComparatorsForType.comparatorsForTargetProperty,
        getDescription(automationComparatorsForType.comparatorsForTargetProperty),
      ],
    },
    {
      key: customPropertyTypes.select,
      entries: [
        customPropertyTypes.select,
        automationComparatorsForType.comparatorsForTargetPropertyWithSelectType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithSelectType),
      ],
    },
    {
      key: customPropertyTypes.multySelect,
      entries: [
        customPropertyTypes.multySelect,
        automationComparatorsForType.comparatorsForTargetProperty,
        getDescription(automationComparatorsForType.comparatorsForTargetProperty),
      ],
    },
    {
      key: customPropertyTypes.url,
      entries: [
        customPropertyTypes.url,
        automationComparatorsForType.comparatorsForTargetPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.voteRating,
      entries: [
        customPropertyTypes.voteRating,
        automationComparatorsForType.comparatorsForTargetPropertyWithComparativeType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithComparativeType),
      ],
    },
    {
      key: customPropertyTypes.voteEmoji,
      entries: [
        customPropertyTypes.voteEmoji,
        automationComparatorsForType.comparatorsForTargetPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.collectiveVote,
      entries: [
        customPropertyTypes.collectiveVote,
        automationComparatorsForType.comparatorsForTargetPropertyWithCollectiveComparativeType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithCollectiveComparativeType),
      ],
    },
    {
      key: customPropertyTypes.catalog,
      entries: [
        customPropertyTypes.catalog,
        automationComparatorsForType.comparatorsForTargetPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.phone,
      entries: [
        customPropertyTypes.phone,
        automationComparatorsForType.comparatorsForTargetPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.user,
      entries: [
        customPropertyTypes.user,
        automationComparatorsForType.comparatorsForTargetPropertyWithSelectType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithSelectType),
      ],
    },
    {
      key: customPropertyTypes.multyUser,
      entries: [
        customPropertyTypes.multyUser,
        automationComparatorsForType.comparatorsForTargetPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForTargetPropertyWithSelectType),
      ],
    },
    {
      key: customPropertyTypes.attachment,
      entries: [
        customPropertyTypes.attachment,
        automationComparatorsForType.comparatorsForTargetProperty,
        getDescription(automationComparatorsForType.comparatorsForTargetProperty),
      ],
    },
  ],
};

export default tableData;
