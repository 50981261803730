import _ from 'lodash';

const tranfromApi = (api) => {
  const clone = _.cloneDeep(api.entities || []);
  const tranformedEntities = clone.map((elem) => {
    const newOper = elem.operations.map((el) => ({
      ...el,
      __name: el.routeName ? el.routeName.toLowerCase().replaceAll(' ', '-') : el.name.toLowerCase().replaceAll(' ', '-'),
    }));
    const keydOper = _.keyBy(newOper, '__name');
    return {
      ...elem,
      __name: elem.routeName ? elem.routeName.toLowerCase().replaceAll(' ', '-') : elem.name.toLowerCase().replaceAll(' ', '-'),
      operations: keydOper,
    };
  });
  const keydEntities = _.keyBy(tranformedEntities, '__name');
  return { api, keydEntities };
};

export default tranfromApi;
