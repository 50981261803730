import userRoles from './userRoles.json';
import createSchema from '../schema/create/userRole.json';
import updateSchema from '../schema/update/userRole.json';
import deleteSchema from '../schema/delete/userRole.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';

userRoles.operations[0].request.schema = createSchema;
userRoles.operations[3].request.schema = updateSchema;
userRoles.operations[4].request.schema = deleteSchema;

userRoles.operations[0].responces[0].example = postResponse;
userRoles.operations[1].responces[0].example = getListResponse;
userRoles.operations[2].responces[0].example = postResponse;
userRoles.operations[3].responces[0].example = postResponse;
userRoles.operations[4].responces[0].example = postResponse;

export default userRoles;
