import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CodeExample from '../CodeExample';

const Description = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
);

function CodeExampleContainer({ example, basePath }) {
  const {
    data: {
      path,
      bodyData,
      token,
      method,
      contentType,
    },
    description,
  } = example;
  return (
    <>
      <Description>
        {description}
      </Description>
      <CodeExample
        path={`${basePath}${path}`}
        body={{ ...bodyData, body: JSON.stringify(bodyData.body) }}
        token={token}
        method={method}
        contentType={contentType}
      />
    </>

  );
}

CodeExampleContainer.propTypes = {
  basePath: PropTypes.string.isRequired,
  example: PropTypes.shape({
    description: PropTypes.string,
    data: PropTypes.shape({
      path: PropTypes.string,
      contentType: PropTypes.string,
      method: PropTypes.string,
      token: PropTypes.string,
      bodyData: PropTypes.object,
    }),
  }).isRequired,
};

export default CodeExampleContainer;
