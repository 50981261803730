import React, { useState } from 'react';
import {
  Typography, Box, TextField, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WithFooter from './WithFooter';
import Snippet from './Snippet';
import NativeScrollContainer from './NativeScrollContainer';
import { get as registryGet } from '../lib/appRegistry';
import { addValue, getValue, companyDomainKey } from '../utils/storage';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
    overflowWrap: 'break-word',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

const SubHeader = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  }),
);

const StyledListItem = styled('li')(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

const BoldText = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '5px',
});

const DomainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing(1),
}));

const CompanyDomain = styled(TextField)({
  width: 300,
  '& fieldset': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const GetToken = styled(Button)({
  width: 300,
  paddingTop: '7.5px',
  paddingBottom: '7.5px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
});

function Introduction() {
  const [domain, setDomain] = useState(getValue(companyDomainKey) || '');
  const onChangeDomain = (event) => {
    addValue(companyDomainKey, event.target.value);
    setDomain(event.target.value);
  };
  const { projectName, hostName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <WithFooter minHeight="calc(100vh - 120px)">
          <Typography gutterBottom variant="h4" component="h1">
            {`${projectName} API`}
          </Typography>
          <Typography gutterBottom>JSON API version: 1</Typography>
          <Typography gutterBottom>
            {'Base URL: '}
            <Snippet inline removeGutters>{`https://<your_domain>.${hostName}/api/v1`}</Snippet>
          </Typography>
          <Typography gutterBottom>
            {'Latest API version is always available at '}
            <Snippet inline removeGutters>
              {`https://<your_domain>.${hostName}/api/latest`}
            </Snippet>
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            All requests must contain bearer auth header.
          </Typography>
          <Snippet removeGutters>{'Authorization: Bearer <token>'}</Snippet>
          <Typography variant="subtitle1" gutterBottom>
            Example:
          </Typography>
          <Snippet removeGutters>
            Authorization: Bearer 296a5709-99bc-49a7-96e0-c0a1b236091f
          </Snippet>
          <DomainWrapper>
            <CompanyDomain
              id="company"
              label="Company domain"
              value={domain}
              onChange={onChangeDomain}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <Typography color="textSecondary">{`.${hostName}`}</Typography>
                ),
              }}
            />
            <GetToken
              component="a"
              variant="contained"
              disableElevation
              target="_blank"
              href={`https://${domain}.${hostName}/profile/api-key`}
              disabled={!domain}
            >
              Get bearer token
            </GetToken>
          </DomainWrapper>
          <Typography gutterBottom>
            API consumes and produces application/json.
          </Typography>
          <SubHeader variant="h5">
            API Rate Limits
          </SubHeader>
          <Typography gutterBottom>
            To keep the load on our servers, API rate limits apply. In order to
            properly configure your API client consider the following response
            headers:
          </Typography>
          <Box component="ul">
            <StyledListItem>
              <Snippet inline removeGutters>X-RateLimit-Remaining</Snippet>
              <Typography component="span">
                {' '}
                — remaining number of requests until limit will be reached.
              </Typography>
            </StyledListItem>
            <li>
              <Snippet inline removeGutters>X-RateLimit-Reset</Snippet>
              <Typography component="span">
                {' '}
                — UTC epoch when the limit will be reset
              </Typography>
            </li>
          </Box>
          <BoldText>Current limit: 5 request per second.</BoldText>
          <Typography>
            {`If the limit is reached, ${projectName} will return 429 HTTP code.`}
          </Typography>
        </WithFooter>
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Introduction;
