export default `buttnCtx.openDialog({
  title: 'Fullscreen dialog example',
  url: './fullscreen-dialog.html',
  fullScreen: true,
  additionalActions: [{
    iconUrl: 'https://github.trello.services/images/icon.svg?color=42526e&quot',
    title: 'Simply opens url in new window',
    url: 'https://github.com',
  }, {
    iconUrl: 'https://on_purpose_wrong_url',
    title: 'Open snackbar action with delay',
    callback: async (ctx) => {
      await new Promise((resolve) => {
        setTimeout(() => resolve(), 700);
      });
      ctx.showSnackbar('Test icon clicked!');
    }
  }, {
    iconUrl: 'https://cdn.glitch.com/1b42d7fe-bda8-4af8-a6c8-eff0cea9e08a%2Frocket-ship.png',
    title: 'Open test popup',
    callback: async (ctx) => {
      return ctx.openPopup({
        type: 'iframe',
        title: 'Popup demostration',
        url: './secondPopup.html',
      }); 
    }
  }],
});`;
