import React from 'react';
import PropTypes from 'prop-types';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Footer from '../../Footer';
import {
  Wrapper,
  SectionTitleWrapper,
  MainContent,
} from '../../../lib/Components/StyledComponents';

import FunctionContent from './FunctionContent';

function Function({ data, name }) {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <MainContent>
          <SectionTitleWrapper>
            <SectionTitle variant="h4" title={name} hash="#header" />
          </SectionTitleWrapper>
          <FunctionContent data={data} />
        </MainContent>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

Function.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default Function;
