import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  Typography,
  IconButton,
  Dialog,
  ListItem,
  ListItemText,
  ListItemIcon,
  DialogTitle,
  DialogContent,
  Button,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { If, Then, Else } from 'react-if';
import hljs from 'highlight.js';

const ListWrapper = styled(ListItem)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    minHeight: '48px',
    paddingLeft: '14px',
    paddingRight: theme.spacing(1),
  }),
);

const ActionButton = styled(Button)({
  right: '8px',
  '&&:hover': { bgcolor: 'transparent' },
});

const TypeIcon = styled(Chip)({
  borderRadius: '50%',
  width: '10px',
  height: '10px',
});
const DialogTypeIcon = styled(Chip)(
  ({ theme }) => ({
    borderRadius: '50%',
    width: '10px',
    height: '10px',
    marginLeft: theme.spacing(2),
  }),
);
const ListIcon = styled(ListItemIcon)({
  minWidth: '20px',
});
const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
});
const StyledDialogContent = styled(DialogContent)({
  minWidth: '300px',
  fontSize: '12px',
});

const Title = styled(Typography)({
  marginTop: '-1px',
});

const Code = styled(Typography)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
  }),
);
const DialogButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});

const higligthCode = (code) => (typeof code === 'string'
  ? hljs.highlight(code, { language: 'json' })
  : hljs.highlight(JSON.stringify(code, null, ' '), { language: 'json' }));

function Response({
  res, button, header, variant, dialogTitleText,
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    if (!res.data) return;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const higligthed = res.data ? higligthCode(res.data) : '';
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <If condition={button}>
        <Then>
          <Button
            variant={variant}
            onClick={handleClickOpen}
          >
            {header}
          </Button>
        </Then>
        <Else>
          <ListWrapper
            button
            component="div"
            disableGutters
            disableRipple={!res.data}
            sx={{
              '&&:hover': !res.data && { bgcolor: 'transparent' },
              cursor: !res.data && 'default',
            }}
            onClick={handleClickOpen}
            secondaryAction={
              res.data && (
                <ActionButton disableRipple>expand</ActionButton>
              )
            }
          >
            <ListIcon>
              <TypeIcon
                color={res.type}
                component="span"
              />
            </ListIcon>
            <ListItemText primary={res.code} secondary={res.description} />
          </ListWrapper>
        </Else>
      </If>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <StyledDialogTitle>
          <Title variant="h6" component="span">
            {dialogTitleText || 'Response'}
          </Title>
          <If condition={res.code}>
            <Then>
              <>
                <DialogTypeIcon
                  color={res.type}
                  component="span"
                />
                <Code variant="h6" component="span">
                  {res.code}
                </Code>
              </>
            </Then>
          </If>
          <DialogButton
            onClick={handleClose}
          >
            <CloseIcon />
          </DialogButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          <pre dangerouslySetInnerHTML={{ __html: higligthed.value }} />
        </StyledDialogContent>
      </Dialog>
    </Box>
  );
}

Response.propTypes = {
  header: PropTypes.string,
  variant: PropTypes.string,
  button: PropTypes.bool,
  dialogTitleText: PropTypes.string,
  res: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
    description: PropTypes.string,
    code: PropTypes.number,
  }),
};

Response.defaultProps = {
  header: '',
  variant: 'text',
  button: false,
  res: null,
  dialogTitleText: 'Response',
};

export default Response;
