import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { If, Then } from 'react-if';
import { Box } from '@mui/material';
import HeaderList from './HeaderList';
import Schema from './Schema';

const Wrapper = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(3),
  }),
);

function Request({ data, sx = {} }) {
  const { schema, headers, headersDecriptions } = data;

  return (
    <Box sx={sx}>
      <If condition={headers}>
        <Then>
          <Wrapper>
            <HeaderList headers={headers} headersDecriptions={headersDecriptions} />
          </Wrapper>
        </Then>
      </If>
      <If condition={!_.isEmpty(schema)}>
        <Then>
          <Wrapper>
            <Schema schema={schema} />
          </Wrapper>
        </Then>
      </If>
    </Box>
  );
}

Request.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.shape({
    headers: PropTypes.object,
    headersDecriptions: PropTypes.object,
    schema: PropTypes.object,
  }),
};

Request.defaultProps = {
  sx: {},
  data: {},
};

export default Request;
