import Introduction from '../../../components/Addons/capabilities/Introduction';
import Settings from '../../../components/Addons/capabilities/Settings';
import CardButtons from '../../../components/Addons/capabilities/СardButtons';
import CardBodySection from '../../../components/Addons/capabilities/CardBodySection';
import CardFacadeBadges from '../../../components/Addons/capabilities/CardFacadeBadges';

export default {
  name: 'Capabilities',
  id: 7,
  operations: [
    {
      id: 8,
      name: 'Introduction',
      Component: Introduction,
    },
    {
      id: 9,
      name: 'settings',
      Component: Settings,
    },
    {
      id: 9,
      name: 'card_buttons',
      routeName: 'card buttons',
      Component: CardButtons,
    },
    {
      id: 9,
      name: 'card_body_section',
      routeName: 'card body section',
      Component: CardBodySection,
    },
    {
      id: 10,
      name: 'card_facade_badges',
      routeName: 'card facade badges',
      Component: CardFacadeBadges,
    },
  ],
};
