import React, {
  useContext, useState,
} from 'react';
import {
  useParams,
  Navigate,
  useOutletContext,
} from 'react-router-dom';
import {
  Typography,
  Box,
  TextField,
  Button,
  Drawer,
  Toolbar,
  IconButton,
  Alert,
} from '@mui/material';
import { If, Then, Else } from 'react-if';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import Request from './Request';
import ResponseList from './ResponseList';
import PathParams from './PathParams';
import Query from './Query';
import OperationIcon from './OperationIcon';
import Interactive from './Interactive';
import RequestExamples from './RequestExamples';
import NativeScrollContainer from './NativeScrollContainer';
import WithFooter from './WithFooter';
import { ApiContext } from '../context';
import { addValue, getValue, companyDomainKey } from '../utils/storage';
import { get as registryGet } from '../lib/appRegistry';
import AdditionalTables from './AdditionalTables';

const BlockWrapper = styled(Box)({
  display: 'flex',
});

const InfoContainer = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      width: '62%',
      borderRight: '1px solid',
      borderRightColor: 'rgba(0,0,0,.1)',
      paddingLeft: theme.spacing(2),
    },
  }),
);

const InfoWrapper = styled(Box)(
  ({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
  }),
);

const Header = styled(Typography)(
  ({ theme }) => ({
    marginBottom: '10px',
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const HeaderInfoWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const PathInfo = styled(Typography)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
    width: '80%',
    overflowWrap: 'break-word',
    color: theme.palette.text.secondary,
  }),
);

const Description = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const ComponentWrapper = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(3),
  }),
);

const MobileEditorHeader = styled(Toolbar)(
  ({ theme }) => ({
    borderBottom: '1px solid',
    borderBottomColor: 'rgba(0,0,0,.1)',
    height: '65px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
);

const CloseButton = styled(IconButton)({
  marginLeft: 'auto',
});

const MobileGetToken = styled(Button)(
  ({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      marginTop: 0,
      marginLeft: 'auto',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  }),
);
const GetToken = styled(Button)({
  width: '50%',
  marginLeft: 'auto',
  paddingTop: '7.5px',
  paddingBottom: '7.5px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
});
const StyledTextField = styled(TextField)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  }),
);

const InteractiveWrapper = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      width: '38%',
    },
  }),
);
const BoxWrapper = styled(Box)(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
  }),
);
const StyledAlert = styled(Alert)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  }),
);

function Info() {
  const { entity, operation } = useParams();
  const {
    editorState, openEditor, closeEditor, isTablet, api, setScroll,
  } = useOutletContext();
  const { keydEntities } = useContext(ApiContext)[api];
  const [state, setState] = useState({
    token: '',
    tokenError: false,
    domain: getValue(companyDomainKey) || '',
    body: '',
    bodyError: '',
  });
  const {
    token, tokenError, domain, domainError,
  } = state;
  const changeToken = (event) => setState((prevValue) => (
    { ...prevValue, token: event.target.value }));
  const changeDomain = (event) => {
    addValue(companyDomainKey, event.target.value);
    setState((prevValue) => ({
      ...prevValue,
      token: '',
      domain: event.target.value,
    }));
  };
  const currentOperation = _.get(keydEntities, [
    entity,
    'operations',
    operation,
  ]);
  if (!currentOperation) {
    return <Navigate to="/" />;
  }
  const isBetaEntity = keydEntities[entity].isBeta;
  const {
    request, id, pathParams, query, responces, name, type, path, description, additionalTables,
  } = currentOperation;
  const { hostName: host } = registryGet('config');
  const basePath = `https://${domain || 'example'}.${host}`;
  const currentPath = `${basePath}/api/latest${path}`;
  return (
    <BlockWrapper>
      <InfoContainer>
        <NativeScrollContainer className="info">
          <InfoWrapper>
            <WithFooter minHeight="calc(100vh - 85px)">
              <Header
                variant="h4"
                component="h4"
              >
                {name}
              </Header>
              <HeaderInfoWrapper>
                <If condition={type}>
                  <Then>
                    <OperationIcon type={type} />
                  </Then>
                </If>
                <If condition={path}>
                  <Then>
                    <PathInfo
                      component="span"
                    >
                      {currentPath}
                    </PathInfo>
                  </Then>
                </If>
              </HeaderInfoWrapper>
              <If condition={isBetaEntity}>
                <Then>
                  <StyledAlert severity="warning">This endpoint is currently under active development. Parameters, attributes, and response formats are subject to change</StyledAlert>
                </Then>
              </If>
              <If condition={description}>
                <Description>{description}</Description>
              </If>
              <If condition={pathParams}>
                <Then>
                  <ComponentWrapper>
                    <PathParams params={pathParams} />
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={request}>
                <Then>
                  <ComponentWrapper>
                    <Request data={request} />
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={query}>
                <Then>
                  <ComponentWrapper>
                    <Query query={query} />
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={additionalTables}>
                <Then>
                  <ComponentWrapper>
                    <AdditionalTables tables={additionalTables} />
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={responces && !_.isEmpty(responces)}>
                <Then>
                  <ComponentWrapper>
                    <ResponseList responses={responces} />
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={request && request.examples}>
                <Then>
                  <ComponentWrapper>
                    <RequestExamples
                      key={id}
                      examples={request && request.examples}
                      setBody={setState}
                      openEditor={openEditor}
                      setScroll={setScroll}
                      basePath={basePath}
                    />
                  </ComponentWrapper>
                </Then>
              </If>
            </WithFooter>
          </InfoWrapper>
        </NativeScrollContainer>
      </InfoContainer>
      <If condition={isTablet}>
        <Then>
          <Drawer
            open={editorState}
            anchor="right"
            onClose={closeEditor}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                width: {
                  xs: '100%',
                  sm: '500px',
                },
              },
            }}
          >
            <MobileEditorHeader>
              <Typography variant="h6">Editor</Typography>
              <CloseButton onClick={closeEditor} sx={{ ml: 'auto' }}>
                <CloseIcon />
              </CloseButton>
            </MobileEditorHeader>
            <NativeScrollContainer className="interactive">
              <BoxWrapper>
                <TextField
                  id="company"
                  label="Company domain"
                  sx={{
                    width: {
                      xs: '100%',
                      sm: '50%',
                    },
                    '& fieldset': domain && {
                      borderTopRightRadius: {
                        sm: 0,
                      },
                      borderBottomRightRadius: {
                        sm: 0,
                      },
                    },
                  }}
                  value={domain}
                  error={domainError}
                  onChange={changeDomain}
                  variant="outlined"
                  size="small"
                />
                <If condition={domain}>
                  <Then>
                    <MobileGetToken
                      component="a"
                      variant="contained"
                      disableElevation
                      target="_blank"
                      href={`https://${domain}.${host}/profile/api-key`}
                    >
                      Get bearer token
                    </MobileGetToken>
                    <StyledTextField
                      id="token"
                      label="Bearer token"
                      value={token}
                      error={tokenError}
                      onChange={changeToken}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Then>
                </If>
              </BoxWrapper>
              <Interactive
                id={id}
                info={state}
                changeInfo={setState}
                operation={currentOperation}
                basePath={basePath}
              />
            </NativeScrollContainer>
          </Drawer>
        </Then>
        <Else>
          <InteractiveWrapper>
            <NativeScrollContainer className="interactive">
              <BoxWrapper>
                <TextField
                  id="company"
                  label="Company domain"
                  sx={{
                    width: '50%',
                    '& fieldset': domain && {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    },
                  }}
                  value={domain}
                  error={domainError}
                  onChange={changeDomain}
                  variant="outlined"
                  size="small"
                />
                <If condition={domain}>
                  <Then>
                    <GetToken
                      component="a"
                      variant="contained"
                      disableElevation
                      target="_blank"
                      href={`https://${domain}.${host}/profile/api-key`}
                    >
                      Get bearer token
                    </GetToken>
                    <BoxWrapper>
                      <StyledTextField
                        id="token"
                        label="Bearer token"
                        value={token}
                        error={tokenError}
                        onChange={changeToken}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </BoxWrapper>
                  </Then>
                </If>
              </BoxWrapper>
              <Interactive
                id={id}
                info={state}
                changeInfo={setState}
                operation={currentOperation}
                basePath={basePath}
              />
            </NativeScrollContainer>
          </InteractiveWrapper>
        </Else>
      </If>
    </BlockWrapper>
  );
}

export default React.memo(Info);
