import React, { useContext } from 'react';
import { useParams, useOutletContext, Navigate } from 'react-router-dom';
import _ from 'lodash';

import { ApiContext } from '../../context';
import Colors from './Colors';
import ImportsFile from './File';

const componentMapping = {
  Colors,
  entities: ImportsFile,
};

function Pages() {
  const { entity, operation } = useParams();
  const { api } = useOutletContext();
  const { keydEntities } = useContext(ApiContext)[api];

  const currentOperation = _.get(keydEntities, [
    entity,
    'operations',
    operation,
  ]);

  if (!currentOperation) {
    return <Navigate to="/imports" />;
  }

  const Component = componentMapping[currentOperation.component || entity];
  return (
    <Component
      operation={currentOperation}
    />
  );
}

export default Pages;
