import comments from './cardComments.json';
import createSchema from '../schema/create/cardComment.json';
import updateSchema from '../schema/update/cardComment.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';
import requestExamplesPost from './requestExamplesPost.json';
import requestExamplesPatch from './requestExamplesPatch.json';

comments.operations[0].request.schema = createSchema;
comments.operations[2].request.schema = updateSchema;

comments.operations[0].request.examples = requestExamplesPost;
comments.operations[0].responces[0].example = postResponse;
comments.operations[1].responces[0].example = getListResponse;
comments.operations[2].responces[0].example = postResponse;
comments.operations[2].request.examples = requestExamplesPatch;
comments.operations[3].responces[0].example = deleteResponse;

export default comments;
