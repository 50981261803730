import React, { useState } from 'react';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Typography, Box, Paper, Button, Link as MuiLink, Select, MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { If, Then } from 'react-if';
import { grey } from '@mui/material/colors';
import hljs from 'highlight.js';

const StyledSelect = styled(Select)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'start',
      marginLeft: 0,
      minWidth: 100,
    },
  }),
);

const DescriptionWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
);

const Description = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
);

const ItemDescription = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
);

const Wrapper = styled(Paper)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  }),
);

const SnippetContent = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
  }),
);

const CopyButton = styled(Button)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    display: 'block',
  }),
);

const SnippetWrapper = styled(Paper)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: grey[100],
  }),
);
const LinkWrapper = styled(Box)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'inline-block',
  }),
);
function Snippet({ children, inline }) {
  return (
    <SnippetWrapper
      elevation={0}
      component={inline ? 'span' : undefined}
    >
      {children}
    </SnippetWrapper>
  );
}

Snippet.propTypes = {
  children: PropTypes.any.isRequired,
  inline: PropTypes.bool,
};

Snippet.defaultProps = {
  inline: false,
};

const renderLink = (handleClick, link = {}) => (
  <LinkWrapper
    sx={{ display: 'inline-block', my: 1 }}
    onClick={() => handleClick()}
  >
    <MuiLink color="secondary" component={RouterLink} to={link.url}>
      {link.description}
    </MuiLink>
  </LinkWrapper>
);

const renderLinks = (handleClick, links = []) => {
  const mappedLinks = _.map(links, (link) => (
    <div key={link.url}>
      {renderLink(handleClick, link)}
    </div>
  ));
  return <div>{mappedLinks}</div>;
};

const renderItemDescription = (text) => <ItemDescription>{text}</ItemDescription>;
const renderMenuItems = (examples) => _.map(examples, (example, index) => (
  <MenuItem key={example.name} value={index}>{example.name}</MenuItem>
));

function Example({
  examples,
  sx = {},
  setBody,
  openEditor,
  setScroll,
}) {
  const { items } = examples;
  if (items.length === 0) {
    return null;
  }
  const [current, setCurrent] = useState(0);
  const currentExample = items[current];
  const handleChange = (event) => {
    setCurrent(event.target.value);
  };
  const handleClick = () => {
    setBody((prev) => ({
      ...prev,
      body: JSON.stringify(currentExample.request, null, '  '),
    }));
    openEditor();
  };
  const requestBody = hljs.highlight(
    JSON.stringify(currentExample.request, null, '  '),
    { language: 'json' },
  );
  return (
    <Box sx={{ ...sx }}>
      <DescriptionWrapper>
        <Description variant="body1">
          {examples.description}
        </Description>
        <If condition={items.length > 1}>
          <Then>
            <StyledSelect size="small" variant="outlined" value={current} onChange={handleChange}>
              {renderMenuItems(items)}
            </StyledSelect>
          </Then>
        </If>
      </DescriptionWrapper>
      <Wrapper elevation={0}>
        <Snippet>
          <SnippetContent
            dangerouslySetInnerHTML={{ __html: requestBody.value }}
            component="pre"
          />
        </Snippet>
        <If condition={currentExample.description}>
          {renderItemDescription(currentExample.description && currentExample.description.text)}
        </If>
        <If condition={currentExample.links}>{renderLinks(setScroll, currentExample.links)}</If>
        <CopyButton onClick={handleClick} variant="outlined">
          Copy to editor
        </CopyButton>
      </Wrapper>
    </Box>
  );
}

Example.propTypes = {
  sx: PropTypes.object,
  openEditor: PropTypes.func.isRequired,
  setBody: PropTypes.func.isRequired,
  setScroll: PropTypes.func.isRequired,
  examples: PropTypes.shape({
    items: PropTypes.array,
    description: PropTypes.string,
    link: PropTypes.shape({
      description: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

Example.defaultProps = {
  sx: {},
  examples: {},
};

export default Example;
