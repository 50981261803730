import checklists from './cardsCheckList.json';
import createSchema from '../schema/old/create/cardCheckList.json';
import updateSchema from '../schema/update/cardCheckList.json';
import postResponse from './responseExamples/200/post.json';
import getResponse from './responseExamples/200/get.json';
import updateResponse from './responseExamples/200/update.json';
import deleteResponse from './responseExamples/200/delete.json';

checklists.operations[0].request.schema = createSchema;
checklists.operations[2].request.schema = updateSchema;

checklists.operations[0].responces[0].example = postResponse;
checklists.operations[1].responces[0].example = getResponse;
checklists.operations[2].responces[0].example = updateResponse;
checklists.operations[3].responces[0].example = deleteResponse;

export default checklists;
