/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import {
  Typography,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  Toolbar,
  TableRow,
  Box,
} from '@mui/material';
import ComplexData from './ComplexData';
import SectionTitle from './SectionTitle';
import InnerSchemaDialog from './Schema/InnerSchemaDialog';
import {
  parseConstraints,
  strigifyData,
  parseDesc,
  parseSimpleType,
} from './Schema/helpers';

const Caption = styled(Typography)({
  display: 'block',
});

const InnerSchemaWrapper = styled(Box)(
  ({ theme }) => ({
    marginLeft: theme.spacing(-1),
  }),
);

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    whiteSpace: 'pre-line',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const MainText = styled(Typography)({
  fontSize: '0.875rem',
});

const DefaultDataContainer = styled(Typography)({
  marginLeft: 2,
});
const CustomDefaultDataContainer = styled(Typography)(
  ({ theme }) => ({
    marginLeft: 2,
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const CustomDefaultDataHeader = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const Header = styled(Box)({
  marginLeft: 'auto',
});

const StyledTable = styled(Table)(
  () => ({
    minWidth: 600,
  }),
);

const parseType = (data, name) => {
  if ((_.includes(data.type, 'object') && (data.properties || data.patternProperties))
    || (_.includes(data.type, 'array') && data.items?.type === 'object')
  ) {
    return (
      <>
        <Typography variant="body2">{parseSimpleType(data)}</Typography>
        <InnerSchemaWrapper>
          <InnerSchemaDialog data={data} name={name} />
        </InnerSchemaWrapper>

      </>
    );
  }
  if (data.oneOf) {
    return data.oneOf.map(parseSimpleType).join(' | ');
  }
  return parseSimpleType(data);
};

const renderProperties = (props, reqData = []) => _.keys(props).map((name) => {
  const propData = props[name];
  const type = parseType(propData, name);
  const required = reqData.indexOf(name) >= 0;
  const desc = parseDesc(propData);
  const constrains = parseConstraints(propData, type);
  const types = propData.oneOf || [];
  const filtered = _.uniqBy(types, 'description');
  const typesCount = filtered.length;
  if (typesCount < 2) {
    return (
      <TableRow key={name}>
        <TableCellContent>
          {name}
          <Caption
            variant="caption"
            color="error"
          >
            {required ? 'required' : ''}
          </Caption>
        </TableCellContent>
        <TableCellContent>
          <MainText component="div">
            {type}
          </MainText>
          {_.has(propData, 'default') && (
          <>
            <Typography variant="body2" component="span">
              default:
            </Typography>
            <DefaultDataContainer color="error" component="span">
              {strigifyData(propData.default)}
            </DefaultDataContainer>
          </>
          )}
        </TableCellContent>
        <TableCellContent>
          <MainText component="pre">
            {constrains}
          </MainText>
        </TableCellContent>
        <TableCellContent>{desc}</TableCellContent>
      </TableRow>
    );
  }
  return filtered.map((elem, index) => {
    const filteredType = parseType(elem, name);
    return (
      <TableRow key={`${name}${index}`}>
        {index === 0 && (
        <TableCellContent rowSpan={typesCount}>
          {name}
          <Caption
            variant="caption"
            color="error"
          >
            {required ? 'required' : ''}
          </Caption>
        </TableCellContent>
        )}
        <TableCellContent>
          <MainText component="div">
            {filteredType}
          </MainText>
          {_.has(elem, 'default') && (
          <>
            <CustomDefaultDataHeader
              variant="body2"
              component="span"
            >
              default:
            </CustomDefaultDataHeader>
            <CustomDefaultDataContainer
              color="error"
              component="span"
            >
              {strigifyData(elem.default)}
            </CustomDefaultDataContainer>
          </>
          )}
        </TableCellContent>
        <TableCellContent>
          <MainText component="pre">
            {parseConstraints(elem, filteredType)}
          </MainText>
        </TableCellContent>
        <TableCellContent>{parseDesc(elem)}</TableCellContent>
      </TableRow>
    );
  });
});

function Schema({ schema }) {
  return (
    <Box>
      <Paper elevation={0}>
        <TableHeader>
          <SectionTitle title="Attributes" hash="#attributes" />
          <If condition={schema.$schema}>
            <Then>
              <Header>
                <ComplexData
                  data={schema}
                  structName="schema"
                  dataName="Schema"
                />
              </Header>
            </Then>
          </If>
        </TableHeader>
        <TableContainer>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCellContent>Name</TableCellContent>
                <TableCellContent>Type</TableCellContent>
                <TableCellContent>Constraints</TableCellContent>
                <TableCellContent>Description</TableCellContent>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderProperties(schema.properties, schema.required)}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>
    </Box>
  );
}

Schema.propTypes = {
  schema: PropTypes.shape({
    $schema: PropTypes.string,
    properties: PropTypes.object,
    required: PropTypes.array,
  }),
};

Schema.defaultProps = {
  schema: {},
};

export default Schema;
