import automations from './automations.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import getAutomationData from './additionalTables';
import requestExamplesPost from './requestExamplesPost.json';
import requestExamplesPatch from './requestExamplesPatch.json';

automations.operations[0].responces[0].example = postResponse;
automations.operations[1].responces[0].example = getListResponse;
automations.operations[2].responces[0].example = postResponse;
automations.operations[0].additionalTables = [getAutomationData('POST')];
automations.operations[2].additionalTables = [getAutomationData('PATCH')];
automations.operations[0].request.examples = requestExamplesPost;
automations.operations[2].request.examples = requestExamplesPatch;

export default automations;
