import automationTypes from './automationTypes';
import getAutomationTriggers from './automationTriggerData';

export default {
  schema: {
    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
    data: [
      { key: 'name', entries: ['name', 'string', 'maxLength: 256', 'no', 'Automation name'] },
      { key: 'status', entries: ['status', 'enum', '[active,disabled]', 'no', 'Automation status'] },
      { key: 'trigger', entries: ['trigger', 'object', '', 'no', 'Automation trigger.\nExplain automation triggers conditions, actions and it attributes by type'] },
      { key: 'conditions', entries: ['conditions', 'array of objects', '', 'no', 'Automation conditions.\nSee trigger field, for availible conditions by trigger type and its attributes'] },
      { key: 'actions', entries: ['actions', 'array of objects', 'minItems: 1\nmaxItems: 10', 'no', 'Automation actions.\nSee trigger field, for availible actions by trigger type and its attributes'] },
    ],
    linkField: 'Type',
    objectProperties: {
      trigger: getAutomationTriggers(automationTypes.onAction),
    },
  },
  name: 'Event automation',
};
