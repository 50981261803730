import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as muiColors from '@mui/material/colors';
import {
  Typography,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import NativeScrollContainer from '../NativeScrollContainer';
import SectionTitle from '../SectionTitle';
import {
  SectionTitleWrapper,
  StyledTable,
  TableCellContent,
  Wrapper,
} from '../../lib/Components/StyledComponents';

const Example = styled('div')(
  ({ theme }) => ({
    width: theme.spacing(5),
    height: theme.spacing(5),
  }),
);

function Colors({ operation }) {
  const colors = operation.data;

  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title="Colors" hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom variant="subtitle1">
          List of available colors for custom fields options
        </Typography>
        <TableContainer>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCellContent>ID</TableCellContent>
                <TableCellContent>Name</TableCellContent>
                <TableCellContent>Hex value</TableCellContent>
                <TableCellContent>Example</TableCellContent>
              </TableRow>
            </TableHead>
            <TableBody>
              {colors.map(({ id, code, name }) => {
                const color = _.get(muiColors, code)[100];
                return (
                  <TableRow key={id}>
                    <TableCellContent>{id}</TableCellContent>
                    <TableCellContent>{name}</TableCellContent>
                    <TableCellContent>{color}</TableCellContent>
                    <TableCellContent>
                      <Example
                        style={{
                          backgroundColor: color,
                        }}
                      />
                    </TableCellContent>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Wrapper>
    </NativeScrollContainer>
  );
}

Colors.propTypes = {
  operation: PropTypes.shape({
    data: PropTypes.array.isRequired,
    id: PropTypes.number,
    name: PropTypes.string,
    component: PropTypes.string,
  }).isRequired,
};

export default Colors;
