import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  TableContainer,
  Tab,
  Tabs,
  Divider,
  Typography,
} from '@mui/material';
import { If, Then, Else } from 'react-if';
import _ from 'lodash';
import Response from './Response';
import ResponseDetails from './ResponseDetails';
import SectionTitle from './SectionTitle';
import ResponseCode from './Responses/ResponseCode';

const ResponseWrapper = styled('div')(
  ({ theme }) => ({
    marginLeft: theme.spacing(-1),
  }),
);

const Wrapper = styled(Box)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    width: '33.3%',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const StyledDivider = styled(Divider)(
  ({ theme }) => ({
    marginTop: '-1.515px',
    marginBottom: theme.spacing(3),
  }),
);

const SchemaWrapper = styled(Box)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  }),
);

const StyledTable = styled(Table)({
  minWidth: 600,
});

const renderTabs = (responses) => _.map(responses, ({ code, type }, index) => (
  <Tab key={code} value={index} label={<ResponseCode code={code} type={type} />} />
));

const NoSchemaText = styled(Typography)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  }),
);

function ResponseList({ responses }) {
  const [current, setCurrent] = useState(0);
  const currentResponse = responses[current];
  const handleChange = (_event, newValue) => setCurrent(newValue);
  useEffect(() => {
    setCurrent(0);
  }, [responses]);
  if (!currentResponse) return null;

  return (
    <>
      <Wrapper>
        <SectionTitle title="Responses" hash="#responses" />
      </Wrapper>
      <Tabs
        value={current}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {renderTabs(responses)}
      </Tabs>
      <StyledDivider />
      <Paper elevation={0}>
        <TableContainer>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCellContent>Description</TableCellContent>
                <TableCellContent>Response type</TableCellContent>
                <TableCellContent>Example</TableCellContent>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellContent>
                  {currentResponse.type === 'success' ? 'Success' : _.capitalize(currentResponse.description)}
                </TableCellContent>
                <TableCellContent>
                  {currentResponse.valueType}
                </TableCellContent>
                <TableCellContent>
                  {currentResponse.example
                    ? (
                      <ResponseWrapper>
                        <Response header="Open" res={{ ...currentResponse, data: currentResponse.example }} button />
                      </ResponseWrapper>
                    )
                    : 'Response body does not exist'}
                </TableCellContent>
              </TableRow>
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Paper>

      <If condition={currentResponse.body}>
        <Then>
          <SchemaWrapper>
            <ResponseDetails res={currentResponse} />
          </SchemaWrapper>
        </Then>
        <Else>
          <NoSchemaText variant="body1">A schema has not been defined for this response code.</NoSchemaText>
        </Else>
      </If>
    </>
  );
}

ResponseList.propTypes = {
  responses: PropTypes.array.isRequired,
};

export default ResponseList;
