import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Toolbar,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SectionTitle from './SectionTitle';

const Caption = styled(Typography)({
  display: 'block',
});

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const renderParams = (params) => params.map(({
  name, type, required, reference,
}) => (
  <TableRow key={name}>
    <TableCellContent>
      {name}
      <Caption variant="caption" color="error">{required ? 'required' : ''}</Caption>
    </TableCellContent>
    <TableCellContent>{type}</TableCellContent>
    <TableCellContent>{reference}</TableCellContent>
  </TableRow>
));

function PathParams({ params, sx = {} }) {
  return (
    <Paper elevation={0} sx={{ ...sx }}>
      <TableHeader>
        <SectionTitle title="Path parameters" hash="#parameters" />
      </TableHeader>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellContent>Name</TableCellContent>
            <TableCellContent>Type</TableCellContent>
            <TableCellContent>Reference</TableCellContent>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderParams(params)}
        </TableBody>
      </Table>
    </Paper>
  );
}

PathParams.propTypes = {
  sx: PropTypes.object,
  params: PropTypes.array,
};

PathParams.defaultProps = {
  sx: {},
  params: [],
};

export default PathParams;
