/* globals window */
const addValue = (key, value) => {
  window.localStorage.setItem(key, value);
};

const getValue = (key) => window.localStorage.getItem(key);

const companyDomainKey = 'CompanyDomain';

export {
  addValue,
  getValue,
  companyDomainKey,
};
