import automationTypes from './automationTypes';
import getActionsData from './automationActionsData';

export default {
  schema: {
    columns: ['Field name', 'Type', 'Constraint', 'Required', 'Description'],
    data: [
      { key: 'name', entries: ['name', 'string', 'maxLength: 256', 'yes', 'Automation Name'] },
      { key: 'type', entries: ['type', 'string', automationTypes.onDemand, 'yes', 'Automation Type'] },
      { key: 'actions', entries: ['actions', 'array of objects', 'minItems: 1\nmaxItems: 10', 'yes', 'Automation actions'] },
    ],
    linkField: 'Type',
    objectProperties: {
      actions: getActionsData(automationTypes.onAction),
    },
  },
  name: 'Button automation',
};
