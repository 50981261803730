import spaces from './spaces';
import spaceBoards from './spaceBoards';
import boards from './boards';
import columns from './columns';
import columnsSub from './subColumns';
import lanes from './lanes';
import users from './users';
import cards from './cards';
import cardBlockers from './cardBlockers';
import cardTags from './cardTags';
import cardComments from './cardComments';
import cardExternalLinks from './cardExternalLinks';
import cardChildren from './cardChildren';
import cardTimeLogs from './cardTimeLogs';
import cardMembers from './cardMembers';
import tags from './tags';
import cardCheckList from './cardCheckLists';
import cardCheckListItems from './cardCheckListItems';
import cardTypes from './cardTypes';
import spaceUsers from './spaceUsers';
import templateChecklist from './templateChecklist';
import templateChecklistItems from './templateChecklistItems';
import timeLogs from './timelogs';
import customProperties from './companyCustomProp';
import customPropertiesSelect from './companyPropertySelect';
import customPropertiesCatalog from './companyPropertyCatalog';
import customPropertiesCollectiveScore from './customPropertyCollectiveScore';
import customPropertiesCollectiveVote from './customPropertyCollectiveVote';
import cardFiles from './cardFiles';
import serviceDeckServices from './serviceDeckServices';
import checklists from './checklists';
import checklistsItems from './checklistItems';
import allowedUsers from './allowedUsers';
import sdExternalRecipient from './sdEternalRecipients';
import userRoles from './userRoles';
import automations from './automations';
import groups from './groups';
import groupEntities from './groupEntities.js';
import groupUsers from './groupUsers';
import companyUsers from './companyUsers';
import treeEntityRoles from './treeEntityRoles';
import treeEntities from './treeEntities';

const entities = [spaces, spaceBoards, boards, columns, columnsSub, lanes, users,
  userRoles, spaceUsers, companyUsers, templateChecklist,
  templateChecklistItems, cards, cardBlockers, cardTags, cardComments, cardExternalLinks, cardChildren,
  cardMembers, sdExternalRecipient, cardTimeLogs, cardCheckList, cardCheckListItems,
  tags, cardTypes, timeLogs, customProperties, customPropertiesSelect, customPropertiesCatalog,
  customPropertiesCollectiveScore, customPropertiesCollectiveVote, cardFiles, serviceDeckServices,
  checklists, checklistsItems, allowedUsers, automations, groups, groupUsers,
  groupEntities, treeEntityRoles, treeEntities,
];

const api = {
  name: 'api v1',
  type: 'REST',
  entities,
  defaultRoute: '/',
  overview: [
    {
      id: 300,
      name: 'Introduction',
      path: '/',
    },
  ],
};

export default api;
