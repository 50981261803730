/* global navigator */
import React, { useState } from 'react';
import _ from 'lodash';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  Paper,
  IconButton,
  Link as MuiLink,
  Select,
  MenuItem,
  Popper,
  Fade,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { If, Then } from 'react-if';
import { grey } from '@mui/material/colors';
import hljs from 'highlight.js';

const CopyButton = styled(IconButton)({
  position: 'absolute',
  right: 20,
  top: 20,
});

const StyledSelect = styled(Select)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'start',
      marginLeft: 0,
      minWidth: 100,
    },
  }),
);

const DescriptionWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  }),
);

const Description = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
);

const ItemDescription = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
);

const Wrapper = styled(Paper)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  }),
);

const SnippetContent = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
  }),
);

const SnippetWrapper = styled(Paper)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: grey[100],
  }),
);
const LinkWrapper = styled(Box)(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'inline-block',
  }),
);
function Snippet({ children, inline }) {
  return (
    <SnippetWrapper
      elevation={0}
      component={inline ? 'span' : undefined}
    >
      {children}
    </SnippetWrapper>
  );
}

const SuccessIcon = styled(CheckCircleIcon)({
  color: 'green',
  paddingRight: '2px',
});

const SuccessIconWrapper = styled(Paper)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }),
);

Snippet.propTypes = {
  children: PropTypes.any.isRequired,
  inline: PropTypes.bool,
};

Snippet.defaultProps = {
  inline: false,
};

const addIndent = (str, indent) => {
  const indentedStr = str.replaceAll('\n', `\n${' '.repeat(indent)}`);
  return indentedStr.replaceAll(' }', '}');
};

const renderLink = (onClick, link = {}) => (
  <LinkWrapper
    sx={{ display: 'inline-block', my: 1 }}
    onClick={() => onClick(link)}
  >
    <MuiLink color="secondary" component={RouterLink} to={link.url}>
      {link.description}
    </MuiLink>
  </LinkWrapper>
);

const renderLinks = (onClick, links = []) => {
  const mappedLinks = _.map(links, (link) => (
    <div key={link.url}>
      {renderLink(onClick, link)}
    </div>
  ));
  return <div>{mappedLinks}</div>;
};

const getExampleText = (requestData) => {
  const normalizedData = addIndent(requestData, 3);
  return `const axios = require("axios");

const options = {
  method: "POST",
  url: 'your webhook url',
  headers: {
    Accept: "application/json",
  },
  data: ${normalizedData}
}
axios.request(options).then(function (response) {
  console.log(response.data);
  }).catch(function (error) {
    console.error(error);
});`;
};

const renderItemDescription = (text) => <ItemDescription>{text}</ItemDescription>;
const renderMenuItems = (examples) => _.map(examples, (example, index) => (
  <MenuItem key={example.name} value={index}>{example.name}</MenuItem>
));

function Example({
  examples,
  sx = {},
}) {
  const { items } = examples;
  if (items.length === 0) {
    return null;
  }
  const [current, setCurrent] = useState(0);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const {
    changeApi, setScroll,
  } = useOutletContext();

  const handleLinkClick = (link) => {
    changeApi(null, link.section, link.url);
    setScroll();
  };
  const currentExample = items[current];
  const handleChange = (event) => {
    setCurrent(event.target.value);
  };

  const exampleText = getExampleText(JSON.stringify(currentExample.request, null, ' '.repeat(3)));
  const requestBody = hljs.highlight(
    exampleText,
    { language: 'javascript' },
  );

  const copy = (event) => {
    navigator.clipboard.writeText(exampleText).then(() => {
      setTimeout(() => {
        setOpen(false);
      }, 1300);
      setAnchorEl(event.target);
      setOpen(true);
    });
  };

  return (
    <Box sx={{ ...sx }}>
      <DescriptionWrapper>
        <Description variant="body1">
          {examples.description}
        </Description>
        <If condition={items.length > 1}>
          <Then>
            <StyledSelect size="small" variant="outlined" value={current} onChange={handleChange}>
              {renderMenuItems(items)}
            </StyledSelect>
          </Then>
        </If>
      </DescriptionWrapper>
      <Wrapper elevation={0}>
        <Popper open={open} anchorEl={anchorEl} placement="top" transition style={{ zIndex: '5000' }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <SuccessIconWrapper>
                <SuccessIcon />
                <Typography component="span">Copied</Typography>
              </SuccessIconWrapper>
            </Fade>
          )}
        </Popper>
        <CopyButton onClick={copy}>
          <ContentCopyIcon fontSize="small" />
        </CopyButton>
        <Snippet>
          <SnippetContent
            dangerouslySetInnerHTML={{ __html: requestBody.value }}
            component="pre"
          />
        </Snippet>
        <If condition={currentExample.description}>
          {renderItemDescription(currentExample.description && currentExample.description.text)}
        </If>
        <If condition={currentExample.links}>
          {renderLinks(handleLinkClick, currentExample.links)}
        </If>
      </Wrapper>
    </Box>
  );
}

Example.propTypes = {
  sx: PropTypes.object,
  openEditor: PropTypes.func.isRequired,
  setBody: PropTypes.func.isRequired,
  setScroll: PropTypes.func.isRequired,
  examples: PropTypes.shape({
    items: PropTypes.array,
    description: PropTypes.string,
    link: PropTypes.shape({
      description: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

Example.defaultProps = {
  sx: {},
  examples: {},
};

export default Example;
