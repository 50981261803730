/* global navigator */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import {
  IconButton,
  Popper,
  Fade,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import Snippet from './Snippet';

const Wrapper = styled(Box)({
  position: 'relative',
});

const CopyButton = styled(IconButton)({
  position: 'absolute',
  right: 4,
  top: 4,
});
const SuccessIcon = styled(CheckCircleIcon)({
  color: 'green',
  paddingRight: '2px',
});

const SuccessIconWrapper = styled(Paper)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }),
);

function SnippetWithCopy(props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const { code, ...restProps } = props;

  const copy = (event) => {
    navigator.clipboard.writeText(code).then(() => {
      setTimeout(() => {
        setOpen(false);
      }, 1300);
      setAnchorEl(event.target);
      setOpen(true);
    });
  };

  return (
    <Wrapper>
      <Popper open={open} anchorEl={anchorEl} placement="top" transition style={{ zIndex: '5000' }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <SuccessIconWrapper>
              <SuccessIcon />
              <Typography component="span">Copied</Typography>
            </SuccessIconWrapper>
          </Fade>
        )}
      </Popper>
      <CopyButton onClick={copy}>
        <ContentCopyIcon fontSize="small" />
      </CopyButton>
      <Snippet {...restProps} />
    </Wrapper>
  );
}

SnippetWithCopy.propTypes = {
  code: PropTypes.string.isRequired,
};

export default SnippetWithCopy;
