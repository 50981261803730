import _ from 'lodash';
import automationConditionTypes from './automationConditionTypes';
import automationOperatorsMapData from './automationOperatorsMapData';
import automationComparatorsForTargetCustomProperties from './automationComparatorsForTargetCustomProperties';
import automationComparatorsForCustomProperties from './automationComparatorsForCustomProperties';
import automationChildCardsAdditionalConditions from './automationChildCardsAdditionalConditions';
import automationComparisonVariants from './automationComparisonVariants';
import customPropertiesDataTypes from './customPropertiesDataType';
import automationComparatorsForType from './automationComparatorsForType';

const getDescription = (types) => _.join(_.map(types, (type) => `${type} - ${automationComparatorsForType.descriptionForTargetProperty[type]}`), '\n');

const data = {
  [automationConditionTypes.tag]: {
    type: automationConditionTypes.tag,
    name: 'Card tag',
    key: automationConditionTypes.tag,
    description: 'Checks for card tags',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.tag}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.tag].operators, 'yes', automationOperatorsMapData[automationConditionTypes.tag].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'tagIds', entries: ['tagIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'yes', 'Tags Ids.'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.targetTag]: {
    type: automationConditionTypes.targetTag,
    name: 'Tag',
    key: automationConditionTypes.targetTag,
    description: 'When tag added or deleted from card. Check for tag',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.targetTag}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.targetTag].operators, 'yes', automationOperatorsMapData[automationConditionTypes.targetTag].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'tagIds', entries: ['tagIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'yes', 'Tags Ids.'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.cardType]: {
    type: automationConditionTypes.cardType,
    name: 'Card type',
    key: automationConditionTypes.cardType,
    description: 'Checks for card type',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.cardType}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.cardType].operators, 'yes', automationOperatorsMapData[automationConditionTypes.cardType].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'typeIds', entries: ['typeIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'yes', 'Type Ids.'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.newCardType]: {
    type: automationConditionTypes.newCardType,
    name: 'New card type',
    key: automationConditionTypes.newCardType,
    description: 'Checks for new card type when card type changed',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.newCardType}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.newCardType].operators, 'yes', automationOperatorsMapData[automationConditionTypes.newCardType].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'typeIds', entries: ['typeIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'yes', 'Type Ids.'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.updater]: {
    type: automationConditionTypes.updater,
    name: 'Event author',
    key: automationConditionTypes.updater,
    description: 'Checks for author, who initiated event',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.updater}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.updater].operators, 'yes', automationOperatorsMapData[automationConditionTypes.updater].description] },
          { key: 'data', entries: ['data', 'object', '', 'required for all, except in_company, not_in_company operators', 'Send empty object if data not required'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'userIds', entries: ['userIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'for eq, ne,\n contains, not_contains operators', 'userIds'] },
                { key: 'roles', entries: ['roles', 'array of enums', '[\n card_owner,\n responsible,\ncard_member\n]', 'for in_role, not_in_role operators', 'Role names'] },
                { key: 'groupIds', entries: ['groupIds', 'array of integers', '', 'ro in_groups, not_in_groups operators', 'Group ids'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.path]: {
    type: automationConditionTypes.path,
    name: 'Card path',
    key: automationConditionTypes.path,
    description: 'Checks for card path',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.path}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.path].operators, 'yes', automationOperatorsMapData[automationConditionTypes.path].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'boardId', entries: ['boardId', 'integer', '', 'yes', 'Board id'] },
                { key: 'columnId', entries: ['columnId', 'integer | null', '', 'yes', 'Column id. null - for any'] },
                { key: 'laneId', entries: ['laneId', 'integer', '', 'yes', 'Lane id. null - for any'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.newPath]: {
    type: automationConditionTypes.newPath,
    name: 'New card path',
    key: automationConditionTypes.newPath,
    description: 'Checks for new card path, when card moved',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.newPath}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.newPath].operators, 'yes', automationOperatorsMapData[automationConditionTypes.newPath].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'boardId', entries: ['boardId', 'integer', '', 'yes', 'Board id'] },
                { key: 'columnId', entries: ['columnId', 'integer | null', '', 'yes', 'Column id. null - for any'] },
                { key: 'laneId', entries: ['laneId', 'integer', '', 'yes', 'Lane id. null - for any'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.cardState]: {
    type: automationConditionTypes.cardState,
    name: 'Card state',
    key: automationConditionTypes.cardState,
    description: 'Checks for card states. queue, in progress, done',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.cardState}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.cardState].operators, 'yes', automationOperatorsMapData[automationConditionTypes.cardState].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'cardStates', entries: ['cardStates', 'enum', [1, 2, 3], 'yes', ' 1 - queue, 2 – in progress, 3 – done'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.newCardState]: {
    type: automationConditionTypes.newCardState,
    name: 'New card state',
    key: automationConditionTypes.newCardState,
    description: 'Checks for card states, when card state changed. queue, in progress, done',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.newCardState}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.newCardState].operators, 'yes', automationOperatorsMapData[automationConditionTypes.newCardState].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'cardStates', entries: ['cardStates', 'enum', [1, 2, 3], 'yes', ' 1 - queue, 2 – in progress, 3 – done'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.comment]: {
    type: automationConditionTypes.comment,
    name: 'Comment',
    key: automationConditionTypes.comment,
    description: 'Checks for comment text',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.comment}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.comment].operators, 'yes', automationOperatorsMapData[automationConditionTypes.comment].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'command', entries: ['command', 'string', '', 'yes', 'Text for check'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.targetCustomProperty]: {
    type: automationConditionTypes.targetCustomProperty,
    name: 'Changed property',
    key: automationConditionTypes.targetCustomProperty,
    description: 'Checks for changed property',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.targetCustomProperty}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.targetCustomProperty].operators, 'yes', automationOperatorsMapData[automationConditionTypes.targetCustomProperty].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'value', entries: ['value', 'string| number | array', '', 'yes', 'number with numbers types, array - for select, catalog, users,\nrest types-string'] },
                { key: 'propertyIds', entries: ['propertyIds', 'array of integers', 'maxLength - 1', 'yes', 'Property id'] },
                { key: 'comparator', entries: ['comparator', 'enum', '', 'yes', 'Includes allowed comparator for each type'] },
                { key: 'comparisonVariant', entries: ['comparisonVariant', 'enum', '', 'Only for comparators:\n"has_value",\n"has_length",\n"has_average_value" ', 'Comparison variant for specific comparators.\nRequired only for specific comparators'] },
              ],
              linkField: 'Type',
              objectProperties: {
                value: {
                  schema: customPropertiesDataTypes,
                },
                comparator: {
                  schema: automationComparatorsForTargetCustomProperties,
                },
                comparisonVariant: {
                  schema: automationComparisonVariants,
                },
              },
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.customProperty]: {
    type: automationConditionTypes.customProperty,
    name: 'Changed property',
    key: automationConditionTypes.customProperty,
    description: 'Checks for custom property',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.customProperty}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.customProperty].operators, 'yes', automationOperatorsMapData[automationConditionTypes.customProperty].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'value', entries: ['value', 'string| number | array', '', 'yes', 'number with numbers types, array - for select, catalog, users,\nrest types-string'] },
                { key: 'propertyIds', entries: ['propertyIds', 'array of integers', 'maxLength - 1', 'yes', 'Property id'] },
                { key: 'comparator', entries: ['comparator', 'enum', '', 'yes', 'Includes allowed comparator for each type'] },
                { key: 'comparisonVariant', entries: ['comparisonVariant', 'enum', '', 'Only for comparators:\n"has_value",\n"has_length",\n"has_average_value"', 'Comparison variant for specific comparators.\nRequired only for specific comparators'] },
              ],
              linkField: 'Type',
              objectProperties: {
                value: {
                  schema: customPropertiesDataTypes,
                },
                comparator: {
                  schema: automationComparatorsForCustomProperties,
                },
                comparisonVariant: {
                  schema: automationComparisonVariants,
                },
              },
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.dueDate]: {
    type: automationConditionTypes.dueDate,
    name: 'Due date',
    key: automationConditionTypes.dueDate,
    description: 'Checks for due date',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.dueDate}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.dueDate].operators, 'yes', automationOperatorsMapData[automationConditionTypes.dueDate].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'date', entries: ['date', 'string', '', 'for "exact" dateVariant', 'ISO 8601 format'] },
                { key: 'dateVariant', entries: ['dateVariant', 'enum', ['exact', 'current', 'custom', 'within_a_week', 'within_a_month'], 'for "set" comparator ', ' exact - exact date,\n current - event date,\n within_a_week - date within_a_week\n within_a_month - date within_a_month,\n custom - set deviation for is_less, is_more operators,\n  set period for is_between operator'] },
                { key: 'comparator', entries: ['comparator', 'enum', ['set', 'cleared'], 'yes', 'Check due date set or not'] },
                { key: 'deviation', entries: ['deviation', 'enum', ['plus', 'minus'], '"within_a_month", "within_a_week", "custom" dateVariant', 'plus - after event, minus - before event'] },
                { key: 'dateUnit', entries: ['dateUnit', 'enum', ['days', 'weeks', 'months'], 'For "custom" dateVariant', 'Deviation date units'] },
                { key: 'dateValue0', entries: ['dateValue0', 'number', '', 'For "custom" dateVariant and,\n"is_between", "is_more", "is_less" operator', 'First deviation number'] },
                { key: 'dateValue1', entries: ['dateValue1', 'number', '', 'For "custom" dateVariant and,\n"is_between" operator', 'Second deviation number'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.newDueDate]: {
    type: automationConditionTypes.newDueDate,
    name: 'Due date',
    key: automationConditionTypes.newDueDate,
    description: 'Checks for new due date, when it changed',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.newDueDate}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.newDueDate].operators, 'yes', automationOperatorsMapData[automationConditionTypes.newDueDate].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'date', entries: ['date', 'string', '', 'for "exact" dateVariant', 'ISO 8601 format'] },
                { key: 'dateVariant', entries: ['dateVariant', 'enum', ['exact', 'current', 'custom', 'within_a_week', 'within_a_month'], 'for "set", "changed" comparator ', ' exact - exact date,\n current - event date,\n within_a_week - date within_a_week\n within_a_month - date within_a_month,\n custom - set deviation for is_less, is_more operators,\n  set period for is_between operator'] },
                { key: 'comparator', entries: ['comparator', 'enum', ['set', 'cleared', 'changed'], 'yes', 'Check due date'] },
                { key: 'deviation', entries: ['deviation', 'enum', ['plus', 'minus'], '"within_a_month", "within_a_week", "custom" dateVariant', 'plus - after event, minus - before event'] },
                { key: 'dateUnit', entries: ['dateUnit', 'enum', ['days', 'weeks', 'months'], 'For "custom" dateVariant', 'Deviation date units'] },
                { key: 'dateValue0', entries: ['dateValue0', 'number', '', 'For "custom" dateVariant and,\n"is_between", "is_more", "is_less" operator', 'First deviation number'] },
                { key: 'dateValue1', entries: ['dateValue0', 'number', '', 'For "custom" dateVariant and,\n"is_between" operator', 'Second deviation number'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.newChildCardsState]: {
    type: automationConditionTypes.newChildCardsState,
    name: 'Change in child cards',
    key: automationConditionTypes.newChildCardsState,
    description: 'Checks for child card when their states changed',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.newChildCardsState}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.newChildCardsState].operators, 'yes', automationOperatorsMapData[automationConditionTypes.newChildCardsState].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'childCardsStates', entries: ['childCardsStates', 'array of enums', '[\n all_child_cards_done,\n all_child_cards_in_queue,\n child_cards_count_changed,\n child_cards_count_increased,\n child_cards_count_decreased,\n child_cards_path_changed\n]\nMaxLength - 1', 'yes', ''] },
                { key: 'childrenPathEntryOperator', entries: ['childrenPathEntryOperator', 'enum', ['all', 'one_of'], 'for "child_cards_path_changed"  childCardsState', 'all - all cards, one of - at least one card.\nCheck, when all cards or at least one will move to'] },
                { key: 'path', entries: ['path', 'object', '', 'for "child_cards_path_changed"  childCardsState', 'Target path data'] },
                { key: 'childCardsAdditionalConditions', entries: ['childCardsAdditionalConditions', 'array of objects', '', 'for "child_cards_path_changed"  childCardsState', 'Additional child card conditions\nExplain available conditions and its attributes'] },
              ],
              linkField: 'Type',
              objectProperties: {
                path: {
                  schema: {
                    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
                    data: [
                      { key: 'boardId', entries: ['boardId', 'integer', '', 'yes', 'Board id'] },
                      { key: 'columnId', entries: ['columnId', 'integer', '', 'yes', 'Column id'] },
                      { key: 'laneId', entries: ['laneId', 'integer', '', 'yes', 'Lane id. null - for any'] },
                      { key: 'spaceId', entries: ['spaceId', 'integer', '', 'yes', 'Space id'] },
                    ],
                  },
                },
                childCardsAdditionalConditions: automationChildCardsAdditionalConditions,
              },
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.childCardsState]: {
    type: automationConditionTypes.childCardsState,
    name: 'Child cards state',
    key: automationConditionTypes.childCardsState,
    description: 'Checks for child card states',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.childCardsState}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.childCardsState].operators, 'yes', automationOperatorsMapData[automationConditionTypes.childCardsState].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'childCardsStates', entries: ['childCardsStates', 'array of enums', '[\n all_child_cards_done,\n all_child_cards_in_queue,\n]\nMaxLength - 1', 'yes', ''] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.checklistItemText]: {
    type: automationConditionTypes.checklistItemText,
    name: 'Checklist item text',
    key: automationConditionTypes.checklistItemText,
    description: 'Checks for checklist item text',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.checklistItemText}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.checklistItemText].operators, 'yes', automationOperatorsMapData[automationConditionTypes.checklistItemText].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'text', entries: ['text', 'string', '', 'yes', 'Text for check'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.checklistItemDueDate]: {
    type: automationConditionTypes.checklistItemDueDate,
    name: 'Checklist item due date',
    key: automationConditionTypes.checklistItemDueDate,
    description: 'Checks for checklist item due date',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.checklistItemDueDate}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.checklistItemDueDate].operators, 'yes', automationOperatorsMapData[automationConditionTypes.checklistItemDueDate].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'timezone', entries: ['timezone', 'string', '', 'yes', 'Timezone. Example: "Africa/Abidjan"'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.checklistItemCheckedState]: {
    type: automationConditionTypes.checklistItemCheckedState,
    name: 'Checklist item state',
    key: automationConditionTypes.checklistItemCheckedState,
    description: 'Is checklist item checked',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.checklistItemCheckedState}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.checklistItemCheckedState].operators, 'yes', automationOperatorsMapData[automationConditionTypes.checklistItemCheckedState].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'checked', entries: ['checked', 'boolean', '', 'yes', 'Checked flag'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.cardIsRequest]: {
    type: automationConditionTypes.cardIsRequest,
    name: 'Card is a request',
    key: automationConditionTypes.cardIsRequest,
    description: 'Is card a request',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.cardIsRequest}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.cardIsRequest].operators, 'yes', automationOperatorsMapData[automationConditionTypes.cardIsRequest].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Empty data'] },
        ],
      },
    },
  },
  [automationConditionTypes.cardAuthor]: {
    type: automationConditionTypes.cardAuthor,
    name: 'Request author',
    key: automationConditionTypes.cardAuthor,
    description: `Checks for request author.\nOnly works in conjunction with condition type ${automationConditionTypes.cardIsRequest}`,
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.cardAuthor}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.cardAuthor].operators, 'yes', automationOperatorsMapData[automationConditionTypes.cardAuthor].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'organizationUids', entries: ['organizationUids', 'array of string', '', 'yes', 'Organization uuids'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.blockStatus]: {
    type: automationConditionTypes.blockStatus,
    name: 'Card block status',
    key: automationConditionTypes.blockStatus,
    description: 'Checks for card block status',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.blockStatus}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.blockStatus].operators, 'yes', automationOperatorsMapData[automationConditionTypes.blockStatus].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'blocked', entries: ['blocked', 'boolean', '', 'yes', 'Card blocked flag'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.relations]: {
    type: automationConditionTypes.relations,
    name: 'Card relations',
    key: automationConditionTypes.relations,
    description: 'Checks for card relations',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.relations}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.relations].operators, 'yes', automationOperatorsMapData[automationConditionTypes.relations].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'parentsComparator', entries: ['parentsComparator', 'enum', ['absent', 'present', 'ignore'], 'yes', 'absent - no parents card, present - has parents cards, ignore - ignore parents cards'] },
                { key: 'childComparator', entries: ['childComparator', 'enum', ['absent', 'present', 'ignore'], 'yes', 'absent - no parents card, present - has parents cards, ignore - ignore parents cards'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.service]: {
    type: automationConditionTypes.service,
    name: 'Card services',
    key: automationConditionTypes.service,
    description: 'Checks for card linked services ',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.service}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.service].operators, 'yes', automationOperatorsMapData[automationConditionTypes.service].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'serviceIds', entries: ['serviceIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'yes', 'serviceIds'] },
              ],
            },
          },
        },
      },
    },
  },
  [automationConditionTypes.size]: {
    type: automationConditionTypes.size,
    name: 'Card size',
    key: automationConditionTypes.size,
    description: 'Checks for card size value ',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.size}`, 'yes', 'Matches value \'size\''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'string', 'eq', 'yes', 'The only available comparator'] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'size', entries: ['size', 'string| number', '', 'yes', 'Size value (in case comparator is "has_value")'] },
                { key: 'comparator', entries: ['comparator', 'enum', '', 'yes', 'Includes allowed comparator for each type'] },
                { key: 'comparisonVariant', entries: ['comparisonVariant', 'enum', '', 'Only for comparators:\n"has_value",\n"has_length",\n"has_average_value"', 'Comparison variant for specific comparators.\nRequired only for specific comparators'] },
              ],
              linkField: 'Type',
              objectProperties: {
                comparator: {
                  schema: {
                    columns: ['Comparators', 'Description'],
                    data: [
                      {
                        entries: [
                          automationComparatorsForType.comparatorsForSizeType,
                          getDescription(automationComparatorsForType.comparatorsForSizeType),
                        ],
                      },
                    ],
                  },
                },
                comparisonVariant: {
                  schema: automationComparisonVariants,
                },
              },
            },
          },
        },
      },
    },
  },
};

const getConditionData = (conditions) => {
  const conditionsData = _.map(conditions, (conditionType) => data[conditionType]);
  const attributes = {};
  const tableData = [];
  _.forEach(conditionsData, (conditionData) => {
    tableData.push({
      key: conditionData.key,
      entries: [conditionData.type, 'object', conditionData.name, conditionData.description || ''],
    });
    attributes[conditionData.key] = conditionData.attributes;
  });
  return {
    schema: {
      columns: ['Condition Type', 'Condition attributes', 'Condition Name', 'Condition description'],
      data: tableData,
      linkField: 'Condition attributes',
      objectProperties: attributes,
    },
  };
};

export default getConditionData;
