import cards from './cards.json';
import createSchema from '../schema/create/card.json';
import updateSchema from '../schema/old/update/card.json';
import batchUpdateSchema from '../schema/update/card-update-in-batch.json';
import requestExamples from './requestExamples.json';
import requestExamplesPost from './requestExamplesPost.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import getResponse from './responseExamples/200/get.json';
import updateResponse from './responseExamples/200/update.json';
import batchUpdateResponse from './responseExamples/200/batchUpdate.json';
import deleteResponse from './responseExamples/200/delete.json';
import getLocationHistoryResponse from './responseExamples/200/getLocationHistory.json';
import getBaselinesResponse from './responseExamples/200/getBaselines.json';

cards.operations[0].request.schema = createSchema;
cards.operations[3].request.schema = updateSchema;
cards.operations[4].request.schema = batchUpdateSchema;
cards.operations[0].request.examples = requestExamplesPost;
cards.operations[3].request.examples = requestExamples;

cards.operations[0].responces[0].example = postResponse;
cards.operations[1].responces[0].example = getListResponse;
cards.operations[2].responces[0].example = getResponse;
cards.operations[3].responces[0].example = updateResponse;
cards.operations[4].responces[0].example = batchUpdateResponse;
cards.operations[5].responces[0].example = deleteResponse;
cards.operations[6].responces[0].example = getLocationHistoryResponse;
cards.operations[7].responces[0].example = getBaselinesResponse;

export default cards;
