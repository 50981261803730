import automationTypes from './automationTypes';
import getActionsData from './automationActionsData';

export default {
  schema: {
    columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
    data: [
      { key: 'name', entries: ['name', 'string', 'maxLength: 256', 'no', 'Automation Name'] },
      { key: 'status', entries: ['status', 'enum', '[active,disabled]', 'no', 'Automation status'] },
      { key: 'actions', entries: ['actions', 'array of objects', 'minItems: 1\nmaxItems: 10', 'no', 'Automation actions'] },
    ],
    linkField: 'Type',
    objectProperties: {
      actions: getActionsData(automationTypes.onAction),
    },
  },
  name: 'Button automation',
};
