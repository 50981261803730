import AddonInitialize from '../../../components/Addons/Initialize/AddonInitialize';
import IframeInitialize from '../../../components/Addons/Initialize/IframeInitialize';

export default {
  name: 'Initialization',
  id: 4,
  operations: [
    {
      id: 5,
      name: 'Addon.initialize()',
      routeName: 'Addon initialize',
      Component: AddonInitialize,
    },
    {
      id: 6,
      name: 'Addon.iframe()',
      routeName: 'Iframe initialize',
      Component: IframeInitialize,
    },
  ],
};
