import React from 'react';
import {
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import hljs from 'highlight.js';
import NativeScrollContainer from '../NativeScrollContainer';
import SectionTitle from '../SectionTitle';
import cardResponseExample from '../../api/webhooks/cardResponse.json';
import Response from '../Response';
import attributes from '../../api/webhooks/attributes.json';
import Schema from '../Schema';
import Snippet from '../Snippet';
import SnippetWithCopy from '../SnippetWithCopy';
import createWebhook from '../../images/webhooks.gif';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';
import RequestExamples from '../RequestExamples';
import Example from './Example';
import examples from '../../api/webhooks/requestExamples.json';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

const SectionTitleWrapper = styled('div')(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);
const SectionTitleWrapperVariant = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }),
);
const WrapperVariant = styled('div')(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  }),
);
const ExampleWrapper = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  }),
);
const TextVariant = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  }),
);

const example = `const axios = require("axios");

const options = {
  method: "POST",
  url: 'your webhook url',
  headers: {
    Accept: "application/json",
  },
  data: {
    "title": "Card title",
    "description": "Сard Description",
    "asap": false,
    "due_date": "2022-05-04T00:00:00Z",
    "owner_id": 1,
    "members": [1, 2],
    "tags":["bug"],
    "links": [
       {
           "url": "https://example.com",
           "description": "Link description"
       }
    ]
  }
};
axios.request(options).then(function (response) {
  console.log(response.data);
  }).catch(function (error) {
    console.error(error);
});`;

function Intro() {
  const { projectName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title={`${projectName} Webhooks`} hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom variant="subtitle1">
          {`Webhooks is a simple way to create a card in ${projectName}.`}
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          By creating a webhook you get a unique
          URL and can send a JSON payload with the card attributes to it.
        </Typography>
        <TextVariant gutterBottom variant="subtitle1">
          {`Webhooks supports ${projectName}, Tilda, Jira, Airbrake, Sentry, Crashlytics payload formats.`}
        </TextVariant>
        <SectionTitleWrapper>
          <SectionTitle variant="h5" title="Webhook setup" hash="#setup" />
        </SectionTitleWrapper>
        To create a webhook go to
        {' '}
        <Snippet inline removeGutters>
          {'space settings -> webhooks'}
        </Snippet>
        {' '}
        and follow instructions.
        <WrapperVariant>
          <img className="gif" src={createWebhook} alt="webhook install" />
        </WrapperVariant>
        <SectionTitleWrapperVariant>
          <SectionTitle title="Request Example" hash="#request-example" />
        </SectionTitleWrapperVariant>
        <ExampleWrapper>
          <SnippetWithCopy code={example}>
            <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
          </SnippetWithCopy>
        </ExampleWrapper>
        <Response
          variant="outlined"
          res={{ data: cardResponseExample }}
          button
          header="Response Example"
          dialogTitleText="Response Example"
        />
        <WrapperVariant>
          <Schema schema={attributes} />
        </WrapperVariant>
        <RequestExamples examples={examples} Component={Example} />
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Intro;
