import React from 'react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Link, Typography } from '@mui/material';

const StyledTable = styled('table')(({ theme }) => ({
  width: '100%',
  borderCollapse: 'collapse',
  margin: theme.spacing(2.25, 0, 2.5),
  textAlign: 'left',
}));

const StyledTh = styled('th')(({ theme }) => ({
  backgroundColor: grey[100],
  color: theme.palette.text.primary,
  padding: theme.spacing(1.5, 2),
}));

const StyledTd = styled('td')(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${grey[100]}`,
}));

const StyledTr = styled('tr')({
  '&:nth-of-type(even)': {
    backgroundColor: grey[100],
  },
  '&:last-of-type': {
    borderBottom: `2px solid ${grey[100]}`,
  },
});

const StyledTableContainer = styled('div')(({ theme }) => ({
  overflowX: 'auto',
  marginBottom: theme.spacing(2.5),
}));

const StyledDescriptionContainer = styled('li')(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

function extractType(specs) {
  if (specs.type) {
    return Array.isArray(specs.type) ? specs.type.join(', ') : specs.type;
  } if (specs.anyOf) {
    return specs.anyOf.map(extractType).join(' | ');
  } if (specs.allOf) {
    return specs.allOf.map(extractType).join(' & ');
  }
  return 'Unknown';
}

function createLinkForComplexType(field, specs, definitions) {
  const objectTypedSpec = specs?.anyOf?.filter((spec) => spec.type === 'object');
  const arrayTypedSpec = specs?.anyOf?.filter((spec) => spec.type === 'array');

  if (specs.$ref) {
    const refName = specs.$ref.replace('#/definitions/', '');
    if (definitions.includes(refName)) {
      return <Link href={`#${refName}`}>{refName}</Link>;
    }
    return null;
  }
  if (specs.docMapType === 'array' && arrayTypedSpec?.length && arrayTypedSpec[0].items?.$ref && definitions.length) {
    const refName = arrayTypedSpec[0].items.$ref.replace('#/definitions/', '');
    if (definitions.includes(refName)) {
      return <Link href={`#${refName}`}>{`${refName}[]`}</Link>;
    }
    return null;
  }
  if (specs.docMapType === 'object' && objectTypedSpec?.length && objectTypedSpec[0].items?.$ref) {
    const refName = objectTypedSpec[0].items.$ref.replace('#/definitions/', '');
    return refName ? <Link href={`#${refName}`}>{refName}</Link> : extractType(specs);
  }
  return extractType(specs);
}

export default function generateJsxTable(properties, requiredFields, definitions) {
  return (
    <StyledTableContainer>
      <StyledTable>
        <thead>
          <StyledTr>
            <StyledTh>Field Name</StyledTh>
            <StyledTh>Type</StyledTh>
            <StyledTh>Required</StyledTh>
            <StyledTh>Description</StyledTh>
          </StyledTr>
        </thead>
        <tbody>
          {Object.entries(properties).map(([field, specs]) => {
            const fieldType = createLinkForComplexType(field, specs, definitions);
            const isRequired = `${requiredFields.includes(field) ? 'Yes' : 'No'} ${specs.note ? '*' : ''}`;

            return (
              <StyledTr key={field}>
                <StyledTd>{field}</StyledTd>
                <StyledTd>{fieldType}</StyledTd>
                <StyledTd>{isRequired}</StyledTd>
                <StyledTd>{specs.description || ''}</StyledTd>
              </StyledTr>
            );
          })}
        </tbody>
      </StyledTable>
    </StyledTableContainer>
  );
}

export function generateDetailedDescriptions(properties, notes) {
  const allNotes = [
    ...Object.entries(properties)
      .map(([, specs]) => specs.note)
      .filter(Boolean),
  ];

  if (notes?.length) {
    allNotes.push(...notes);
  }

  return (
    <>
      <ul>
        {Object.entries(properties).map(([field, specs]) => (
          <StyledDescriptionContainer key={field}>
            <Typography fontWeight="bold">{`${field}:`}</Typography>
            <Typography gutterBottom>{specs.detailedDescription}</Typography>
          </StyledDescriptionContainer>
        ))}
      </ul>
      {allNotes.map((note) => (
        <Typography fontWeight="bold" key={note}>
          {note}
        </Typography>
      ))}
    </>
  );
}
