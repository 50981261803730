import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import hljs from 'highlight.js';

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  }),
);
const Title = styled(Typography)({
  marginTop: '-1px',
});
const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
});
const StyledDialogContent = styled(DialogContent)({
  minWidth: '300px',
  fontSize: '12px',
});
function ComplexData({
  data, dataName, structName,
}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const higligthed = hljs.highlight(JSON.stringify(data, null, ' '), { language: 'json' });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      <Button onClick={handleClickOpen}>
        {structName}
      </Button>
      <Dialog fullScreen={fullScreen} maxWidth="md" open={open} onClose={handleClose}>
        <StyledDialogTitle>
          <Title variant="h6" component="span">
            {dataName}
          </Title>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </StyledDialogTitle>
        <StyledDialogContent>
          <pre dangerouslySetInnerHTML={{ __html: higligthed.value }} />
        </StyledDialogContent>
      </Dialog>
    </Box>
  );
}

ComplexData.propTypes = {
  data: PropTypes.object.isRequired,
  dataName: PropTypes.string.isRequired,
  structName: PropTypes.string.isRequired,

};

export default ComplexData;
