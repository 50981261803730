export default {
  tag: 'tag',
  targetTag: 'target_tag',
  cardType: 'card_type',
  newCardType: 'new_card_type',
  updater: 'updater',
  path: 'path',
  newPath: 'new_path',
  cardState: 'card_state',
  newCardState: 'new_card_state',
  comment: 'comment',
  targetCustomProperty: 'target_custom_property',
  customProperty: 'custom_property',
  dueDate: 'due_date',
  newDueDate: 'new_due_date',
  newChildCardsState: 'new_child_cards_state',
  childCardsState: 'child_cards_state',
  checklistItemText: 'checklist_item_text',
  checklistItemDueDate: 'checklist_item_due_date',
  checklistItemCheckedState: 'checklist_item_checked_state',
  cardIsRequest: 'card_is_request',
  cardAuthor: 'card_author',
  blockStatus: 'block_status',
  relations: 'relations',
  service: 'service',
  size: 'size',
};
