/* globals window, navigator */
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Box, IconButton, Typography, Tooltip,
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

const CopyButton = styled(IconButton)({
  opacity: 0,
  visibility: 'hidden',
});

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '&:hover .copyButton': {
    opacity: 1,
    visibility: 'visible',
  },
});

function SectionTitle({
  title, hash, variant,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const sectionRef = useRef();
  useEffect(() => {
    if (hash === location.hash) {
      sectionRef.current.scrollIntoView();
    }
  }, [location.hash]);
  const handleClick = () => {
    const path = `${location.pathname}${hash}`;
    navigator.clipboard.writeText(`${window.location.origin}${path}`).then(() => {
      navigate(path);
      sectionRef.current.scrollIntoView();
    });
  };
  return (
    <Container
      ref={sectionRef}
    >
      <Typography variant={variant}>{title}</Typography>
      <Tooltip title="copy link">
        <CopyButton
          className="copyButton"
          onClick={handleClick}
        >
          <LinkIcon />
        </CopyButton>
      </Tooltip>

    </Container>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

SectionTitle.defaultProps = {
  variant: 'h6',
};

export default SectionTitle;
