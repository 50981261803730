import links from './cardExternalLinks.json';
import createSchema from '../schema/create/cardExternalLink.json';
import updateSchema from '../schema/update/cardExternalLink.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

links.operations[0].request.schema = createSchema;
links.operations[2].request.schema = updateSchema;

links.operations[0].responces[0].example = postResponse;
links.operations[1].responces[0].example = getListResponse;
links.operations[2].responces[0].example = postResponse;
links.operations[3].responces[0].example = deleteResponse;

export default links;
