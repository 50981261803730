export default {
  string: 'string',
  multylineString: 'multilineString',
  number: 'number',
  date: 'date',
  email: 'email',
  checkbox: 'checkbox',
  select: 'select',
  multySelect: 'multy select',
  formula: 'formula',
  url: 'url',
  collectiveScore: 'collective_score',
  voteRating: 'vote with "rating" variant',
  voteEmoji: 'vote with "emoji set" variant',
  collectiveVote: 'collective_vote',
  catalog: 'catalog',
  phone: 'phone',
  user: 'user',
  multyUser: 'user with multy select',
  attachment: 'attachment',
};
