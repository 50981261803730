import React from 'react';
import _ from 'lodash';
import {
  Typography,
} from '@mui/material';

import { get as registryGet } from '../../lib/appRegistry';

const constraints = {
  string: ['minLength', 'maxLength'],
  integer: ['minimum', 'maximum'],
};
const maxLength = 4;
const parseConstraints = (data, type) => {
  let currentData = data;
  let currentType = type;
  if (type === 'enum' || (data.type === 'array' && _.has(data, 'items.enum'))) {
    const enumField = type === 'enum' ? data.enum : data.items.enum;
    return enumField.length < maxLength
      ? `[${enumField}]`
      : `[\n  ${enumField.join(',\n  ')}\n]`;
  }
  if (data.oneOf) {
    [currentData] = data.oneOf;
    currentType = data.oneOf[0].type;
  }
  const constrArr = constraints[currentType];
  if (!constrArr) return '';
  return constrArr
    .map((elem) => (currentData[elem] ? `${elem}: ${currentData[elem]}` : null))
    .filter((elem) => elem !== null)
    .join('\n');
};
const strigifyData = (data) => {
  if (typeof data === 'boolean') {
    return data ? 'true' : 'false';
  }
  return data;
};
const parseDesc = (data, onClose) => {
  const { projectName } = registryGet('config');
  const description = _.isString(data.description)
    ? _.replace(data.description, /{{projectName}}/gm, projectName)
    : data.description;
  if (data.link && description) {
    return (
      <>
        <Typography variant="body2">{description}</Typography>
        <a onClick={onClose} href={data.link.url}>{data.link.description}</a>
      </>
    );
  }
  if (description) return <Typography variant="body2">{description}</Typography>;

  if (data.oneOf) {
    return _.isString(data.oneOf[0].description)
      ? _.replace(data.oneOf[0].description, /{{projectName}}/gm, projectName)
      : data.oneOf[0].description;
  }
  return '';
};
const parseSimpleType = (data) => {
  if (data.type) {
    if (Array.isArray(data.type)) return data.type.join(' | ');
    return data.type === 'array' ? `array ${data.items?.type ? `of ${data.items.type}s` : ''}` : data.type;
  }
  if (data.enum) return 'enum';
  return null;
};

export {
  parseConstraints,
  strigifyData,
  parseDesc,
  parseSimpleType,
};
