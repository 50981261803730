import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, Navigate, useOutletContext } from 'react-router-dom';
import {
  Typography,
  Box,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { If, Then } from 'react-if';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import NativeScrollContainer from '../NativeScrollContainer';
import { ApiContext } from '../../context';
import WithFooter from '../WithFooter';

import ResponseList from '../ResponseList';
import Request from '../Request';
import Query from '../Query';
import OperationIcon from '../OperationIcon';
import { getValue, companyDomainKey } from '../../utils/storage';
import { get as registryGet } from '../../lib/appRegistry';

const SnippetWrapper = styled(Paper)(
  ({ theme }) => ({
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(1)}`,
    marginBottom: theme.spacing(2),
    backgroundColor: grey[100],
    overflow: 'auto',
  }),
);
const Container = styled(Box)({
  display: 'flex',
});
const OuterWrapper = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const InnerWrapper = styled(Box)(
  ({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
  }),
);
const Title = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    marginBottom: '10px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const HeaderInfoWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const PathInfo = styled(Typography)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
    width: '80%',
    overflowWrap: 'break-word',
    color: theme.palette.text.secondary,
  }),
);
const Description = styled(Typography)(
  ({ theme }) => ({
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const ComponentWrapper = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(3),
  }),
);

function Snippet({ children, inline }) {
  return (
    <SnippetWrapper
      elevation={0}
      component={inline ? 'span' : undefined}
    >
      <NativeScrollContainer>
        <Typography
          color="text.secondary"
          component={inline ? 'span' : 'div'}
        >
          {children}
        </Typography>
      </NativeScrollContainer>

    </SnippetWrapper>
  );
}

Snippet.propTypes = {
  children: PropTypes.any.isRequired,
  inline: PropTypes.bool,
};

Snippet.defaultProps = {
  inline: false,
};

function Info() {
  const { entity, operation } = useParams();
  const { api } = useOutletContext();
  const { keydEntities } = useContext(ApiContext)[api];

  const currentOperation = _.get(keydEntities, [
    entity,
    'operations',
    operation,
  ]);

  if (!currentOperation) {
    return <Navigate to="/scim" />;
  }

  const {
    name,
    description,
    type,
    path,
    responses,
    query,
    request,
  } = currentOperation;

  const domain = getValue(companyDomainKey) || '';
  const { hostName: host } = registryGet('config');
  const basePath = `https://${domain || 'example'}.${host}`;
  const currentPath = `${basePath}/scim/v2${path}`;

  return (
    <Container>
      <OuterWrapper>
        <NativeScrollContainer className="info">
          <InnerWrapper>
            <WithFooter minHeight="calc(100vh - 86px)">
              <Title
                variant="h4"
                component="h4"
              >
                {name}
              </Title>
              <HeaderInfoWrapper>
                <If condition={type}>
                  <Then>
                    <OperationIcon type={type} />
                  </Then>
                </If>
                <If condition={path}>
                  <Then>
                    <PathInfo
                      component="span"
                    >
                      {currentPath}
                    </PathInfo>
                  </Then>
                </If>
              </HeaderInfoWrapper>
              <If condition={request}>
                <Then>
                  <ComponentWrapper>
                    <Description>{description}</Description>
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={request}>
                <Then>
                  <ComponentWrapper>
                    <Request data={request} />
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={query}>
                <Then>
                  <ComponentWrapper>
                    <Query query={query} />
                  </ComponentWrapper>
                </Then>
              </If>
              <If condition={responses && !_.isEmpty(responses)}>
                <Then>
                  <ComponentWrapper>
                    <ResponseList responses={responses} />
                  </ComponentWrapper>
                </Then>
              </If>
            </WithFooter>
          </InnerWrapper>
        </NativeScrollContainer>
      </OuterWrapper>
    </Container>
  );
}

export default Info;
