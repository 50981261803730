import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  Toolbar,
  Paper,
} from '@mui/material';
import { If, Then } from 'react-if';
import { styled } from '@mui/material/styles';
import SectionTitle from './SectionTitle';

const Caption = styled(Typography)({
  display: 'block',
});

const StyledTable = styled(Table)({
  minWidth: 600,
});

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const renderQuery = (query) => query.map(({
  name, type, description, example, constraints, required,
}) => (
  <TableRow key={name}>
    <TableCellContent>
      {name}
      <Caption
        variant="caption"
        color="error"
      >
        {required ? 'required' : ''}
      </Caption>
    </TableCellContent>
    <TableCellContent>{type}</TableCellContent>
    <TableCellContent>{constraints}</TableCellContent>
    <TableCellContent>
      {description}
      <If condition={example}>
        <Then>
          <Typography variant="body2" color="text.secondary">
            {`Example: ${example}`}
          </Typography>
        </Then>
      </If>
    </TableCellContent>
  </TableRow>
));

function Query({ query }) {
  return (
    <Paper elevation={0}>
      <TableHeader>
        <SectionTitle title="Query" hash="#query" />
      </TableHeader>
      <TableContainer>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCellContent>Name</TableCellContent>
              <TableCellContent>Type</TableCellContent>
              <TableCellContent>Constraints</TableCellContent>
              <TableCellContent>Description</TableCellContent>
            </TableRow>
          </TableHead>
          <TableBody>{renderQuery(query)}</TableBody>
        </StyledTable>
      </TableContainer>

    </Paper>
  );
}

Query.propTypes = {
  query: PropTypes.array,
};

Query.defaultProps = {
  query: [],
};

export default Query;
