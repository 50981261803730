import automationEventComparators from './automationEventComparators';

const descriptionForTargetProperty = {
  [automationEventComparators.set]: 'is set',
  [automationEventComparators.cleared]: 'is cleared',
  [automationEventComparators.any]: 'any change of property value',
  [automationEventComparators.eq]: 'is set to',
  [automationEventComparators.anyOf]: 'is set to any of',
  [automationEventComparators.noneOf]: 'is set to none of',
  [automationEventComparators.hasValue]: 'is set to be',
  [automationEventComparators.hasAverageValue]: 'the average score is set to',
  [automationEventComparators.hasLength]: 'has the number of votes',
};
const descriptionForProperty = {
  [automationEventComparators.set]: 'is set',
  [automationEventComparators.cleared]: 'not set',
  [automationEventComparators.any]: 'any change of property value',
  [automationEventComparators.eq]: 'is',
  [automationEventComparators.anyOf]: 'is any of',
  [automationEventComparators.noneOf]: 'is none of',
  [automationEventComparators.hasValue]: 'has value',
  [automationEventComparators.hasAverageValue]: 'has the average score ',
  [automationEventComparators.hasLength]: 'has the number of votes',
};

const comparatorsForPropertyWithComparativeType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.hasValue,
];

const comparatorsForTargetPropertyWithComparativeType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.any,
  automationEventComparators.hasValue,
];

const comparatorsForTargetProperty = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.any,
];

const comparatorsForTargetPropertyWithEqType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.any,
  automationEventComparators.eq,
];

const comparatorsForTargetPropertyWithSelectType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.any,
  automationEventComparators.eq,
  automationEventComparators.anyOf,
  automationEventComparators.noneOf,
];

const comparatorsForProperty = [
  automationEventComparators.set,
  automationEventComparators.cleared,
];

const comparatorsForPropertyWithEqType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.eq,
];

const comparatorsForPropertyWithSelectType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.eq,
  automationEventComparators.anyOf,
  automationEventComparators.noneOf,
];
const comparatorsForPropertyWithCollectiveComparativeType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.hasLength,
  automationEventComparators.hasAverageValue,
];

const comparatorsForTargetPropertyWithCollectiveComparativeType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.any,
  automationEventComparators.hasValue,
  automationEventComparators.hasAverageValue,
  automationEventComparators.hasLength,
];

const comparatorsForSizeType = [
  automationEventComparators.set,
  automationEventComparators.cleared,
  automationEventComparators.hasValue,
];

export default {
  comparatorsForProperty,
  comparatorsForTargetProperty,
  comparatorsForPropertyWithEqType,
  comparatorsForPropertyWithSelectType,
  comparatorsForTargetPropertyWithEqType,
  comparatorsForTargetPropertyWithSelectType,
  descriptionForTargetProperty,
  descriptionForProperty,
  comparatorsForPropertyWithComparativeType,
  comparatorsForTargetPropertyWithComparativeType,
  comparatorsForPropertyWithCollectiveComparativeType,
  comparatorsForTargetPropertyWithCollectiveComparativeType,
  comparatorsForSizeType,
};
