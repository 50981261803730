import restApi from './v1';
import externalWebHooksApi from './externalWebhooks';
import webhooksApi from './webhooks';
import scimApi from './scim';
import userMetadata from './userMetadata';
import addons from './addons';
import imports from './imports';

export default {
  restApi, externalWebHooksApi, webhooksApi, scimApi, userMetadata, addons, imports,
};
