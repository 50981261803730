import {
  Box,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { If, Then } from 'react-if';
import OperationIcon from '../OperationIcon';
import PathParams from '../PathParams';
import Query from '../Query';
import Request from '../Request';
import ResponseList from '../ResponseList';

const BlockWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
  }),
);

const InfoContainer = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    paddingLeft: theme.spacing(1),
  }),
);

const InfoWrapper = styled(Box)(
  ({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    },
  }),
);

const Header = styled(Typography)(
  ({ theme }) => ({
    marginBottom: '10px',
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const HeaderInfoWrapper = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const PathInfo = styled(Typography)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
    width: '80%',
    overflowWrap: 'break-word',
    color: theme.palette.text.secondary,
  }),
);

const Description = styled(Typography)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const ComponentWrapper = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(3),
  }),
);

function Operation({ operation }) {
  const {
    request, pathParams, query, responces, name, type, path, description,
  } = operation;
  return (
    <BlockWrapper>
      <InfoContainer>
        <InfoWrapper>
          <Header
            variant="h4"
            component="h4"
          >
            {name}
          </Header>
          <HeaderInfoWrapper>
            <OperationIcon type={type} />
            <If condition={path}>
              <PathInfo
                component="span"
              >
                {path}
              </PathInfo>
            </If>
          </HeaderInfoWrapper>
          <If condition={description}>
            <Description>{description}</Description>
          </If>
          <If condition={pathParams}>
            <Then>
              <ComponentWrapper>
                <PathParams params={pathParams} />
              </ComponentWrapper>
            </Then>
          </If>
          <If condition={request}>
            <Then>
              <ComponentWrapper>
                <Request data={request} />
              </ComponentWrapper>
            </Then>
          </If>
          <If condition={query}>
            <Then>
              <ComponentWrapper>
                <Query query={query} />
              </ComponentWrapper>
            </Then>
          </If>
          <If condition={responces && !_.isEmpty(responces)}>
            <Then>
              <ComponentWrapper>
                <ResponseList responses={responces} />
              </ComponentWrapper>
            </Then>
          </If>
        </InfoWrapper>
      </InfoContainer>
    </BlockWrapper>
  );
}

Operation.propTypes = {
  operation: PropTypes.object.isRequired,
};

export default React.memo(Operation);
