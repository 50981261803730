import select from './select.json';
import createSchema from '../schema/create/customPropertySelectValue.json';
import updateSchema from '../schema/update/customPropertySelectValue.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

select.operations[0].request.schema = createSchema;
select.operations[3].request.schema = updateSchema;

select.operations[0].responces[0].example = postResponse;
select.operations[1].responces[0].example = getListResponse;
select.operations[2].responces[0].example = postResponse;
select.operations[3].responces[0].example = postResponse;
select.operations[4].responces[0].example = deleteResponse;

export default select;
