const api = {
  name: 'user metadata',
  type: 'WEBHOOK',
  disableSearch: true,
  defaultRoute: '/user-metadata',
  overview: [
    {
      id: 300,
      name: 'Introduction',
      path: '/user-metadata',
    },
  ],
};

export default api;
