import cardTypes from './cardTypes.json';
import createSchema from '../schema/old/create/cardType.json';
import updateSchema from '../schema/old/update/cardType.json';
import deleteSchema from '../schema/delete/cardType.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';

cardTypes.operations[0].request.schema = createSchema;
cardTypes.operations[3].request.schema = updateSchema;
cardTypes.operations[4].request.schema = deleteSchema;

cardTypes.operations[0].responces[0].example = postResponse;
cardTypes.operations[1].responces[0].example = getListResponse;
cardTypes.operations[2].responces[0].example = postResponse;
cardTypes.operations[3].responces[0].example = postResponse;
cardTypes.operations[4].responces[0].example = postResponse;

export default cardTypes;
