export default {
  addAssignee: 'add_assignee',
  removeAssignee: 'remove_assignee',
  moveToPath: 'move_to_path',
  moveOnBoard: 'move_on_board',
  addTag: 'add_tag',
  removeTags: 'remove_tags',
  addCardUsers: 'add_card_users',
  addProperty: 'add_property',
  addChildCard: 'add_child_card',
  addParentCard: 'add_parent_card',
  connectParentCard: 'connect_parent_card',
  removeCardUsers: 'remove_card_users',
  changeAsap: 'change_asap',
  addUserGroups: 'add_user_groups',
  addSize: 'add_size',
  addDueDate: 'add_due_date',
  removeDueDate: 'remove_due_date',
  completeChecklists: 'complete_checklists',
  sortCards: 'sort_cards',
  addComment: 'add_comment',
  addSla: 'card_add_sla',
  removeSla: 'card_remove_sla',
  archive: 'archive',
  changeCustomPropertyValue: 'change_custom_property_value',
  addPropertyToChildCards: 'property_add_to_child_card',
  changeType: 'change_type',
  changeService: 'change_service',
};
