import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  Paper,
} from '@mui/material';

const TableHeader = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const TableCellContent = styled(TableCell)(
  ({ theme }) => ({
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);

const renderDescription = (data) => data.map(({ name, description, example }) => (
  <TableRow key={name}>
    <TableCellContent>{name}</TableCellContent>
    <TableCellContent>{description}</TableCellContent>
    <TableCellContent>{example}</TableCellContent>
  </TableRow>
));

function PayloadDescription({ data, sx = {} }) {
  return (
    <Paper elevation={0} sx={{ ...sx }}>
      <TableHeader>
        <Typography variant="h6">Payload Description</Typography>
      </TableHeader>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellContent>Name</TableCellContent>
            <TableCellContent>Description</TableCellContent>
            <TableCellContent>Example</TableCellContent>
          </TableRow>
        </TableHead>
        <TableBody>{renderDescription(data)}</TableBody>
      </Table>
    </Paper>
  );
}

PayloadDescription.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.object,
};

PayloadDescription.defaultProps = {
  sx: {},
  data: {},
};

export default PayloadDescription;
