import timelogs from './cardTimeLogs.json';
import createSchema from '../schema/create/cardTimeLog.json';
import updateSchema from '../schema/update/cardTimeLog.json';
import postResponse from './responseExamples/200/post.json';
import getListResponse from './responseExamples/200/getList.json';
import deleteResponse from './responseExamples/200/delete.json';

timelogs.operations[0].request.schema = createSchema;
timelogs.operations[2].request.schema = updateSchema;

timelogs.operations[0].responces[0].example = postResponse;
timelogs.operations[1].responces[0].example = getListResponse;
timelogs.operations[2].responces[0].example = postResponse;
timelogs.operations[3].responces[0].example = deleteResponse;

export default timelogs;
