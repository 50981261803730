import Function from '../../../components/Addons/SdkFunctions/Function';
import getPermissionsSnippet from './examples/getPermissions';
import getCardSnippet from './examples/getCard';

const getAllDataSnippet = `// Here's an example value after resolving the promise

{ card: { private: {...}, shared: {...} } }
`;

const setSettingsSnippet = `Addon.initialize({
  settings: async (settingsContext) => {
   await settingsContext.setSettings({value: 'test'})
    return settingsContext.openPopup({
      type: 'iframe',
      title: 'Timer settings',
      url: './settings.html',
      height: 200,
      width: 300
    });
  },
})`;

const getSettingsSnippet = `[
  {
    value: 'test',
  },
];`;
const getContextSnippet = `addon_id: "f4af358b-1e65-45dd-a9e0-8b2b43519979",
board_id: 9,
card_id: 516,
company_id: 1,
permissions: {...},
user_id: 1`;

const getCardPropertiesSnippet = `{
  "id": 1,
  "color": 1,
  "letter": "C",
  "name": "Card"
}`;

const getCurrentUserSnippet = `{
  "id": 1,
  "uid": "438c9d13-26df-491c-bdab-524683aadee4",
  "username": "admin",
  "full_name": "Johnny Doe"
}`;

const signUrlSnippet = ` 'card_body_section': (bodySectionContext) => {
  return [{
    title: '📝 Timer logs',
    content: {
      type: 'iframe',
      url: bodySectionContext.signUrl('./timeLogs.html'),
      height: 200,
    }
  }]
},`;

export default {
  name: 'Web Sdk Data related function',
  id: 11,
  operations: [
    {
      id: 12,
      name: 'setData',
      routeName: 'set data',
      Component: Function,
      data: {
        description: {
          head: "context.setData('scope', 'visibility', 'key', 'value')",
          text: ' - storing addon data in a specific invocation context (currently, all available sections of the add-ons are within a card, so the data is saved only in the context of the card)',
        },
        returnValue: {
          type: 'Promise',
        },
        arguments: [
          {
            name: 'scope',
            type: 'string',
            description: "Invocation context. Currently, only 'card'.",
          },
          {
            name: 'visibility',
            type: 'string',
            description: "One of - private or shared:\n private - data is saved only for the current user, other users won't see the data.\n shared - data is saved for all users and will be visible to any authorized user.",
          },
          {
            name: 'key',
            type: 'string',
            description: 'The key under which the data will be saved',
          },
          {
            name: 'value',
            type: 'any',
            description: 'Value',
          },
        ],
        additionalInfo: {
          text: 'Instead of a key and value, you can also pass an object to save more than one value at a time.',
        },
        alert: {
          severity: 'info',
          text: 'Note: to save data, the current user must have permission to update the current context. In the case of a card, context.getPermissions().card.update must be set to true.',
        },
        snippet: {
          lng: 'javascript',
          content: "context.setData('card', 'private', { first: 1, second: 2 })",
        },
      },
    },
    {
      id: 13,
      name: 'getData',
      routeName: 'get data',
      Component: Function,
      data: {
        description: {
          head: "context.getData('scope', 'visibility', 'key')",
          text: ' - Retrieving addon data within a specific call context.',
        },
        returnValue: {
          type: 'Promise',
        },
        arguments: [
          {
            name: 'scope',
            type: 'string',
            description: "Invocation context. Currently, only 'card'.",
          },
          {
            name: 'visibility',
            type: 'string',
            description: "One of - private or shared:\n private - data is saved only for the current user, other users won't see the data.\n shared - data is saved for all users and will be visible to any authorized user.",
          },
          {
            name: 'key',
            type: 'string',
            description: 'The key of the desired value.',
          },
        ],
        snippet: {
          lng: 'javascript',
          content: "context.getData('card', 'private', 'timerLog')",
        },
      },
    },
    {
      id: 14,
      name: 'getAllData',
      routeName: 'get all data',
      Component: Function,
      data: {
        description: {
          head: 'context.getAllData()',
          text: ' - Retrieving all saved addon data within the current context.',
        },
        returnValue: {
          type: 'Promise',
        },
        arguments: [],
        snippet: {
          lng: 'javascript',
          content: getAllDataSnippet,
        },
      },
    },
    {
      id: 15,
      name: 'setSettings',
      routeName: 'set settings',
      Component: Function,
      data: {
        description: {
          head: 'context.setSettings(settings)',
          text: ' - Saves settings for the current workspace. Available only within the settings capability context (i.e., in any iframe opened from the addon settings within the workspace).',
        },
        returnValue: {
          type: 'Promise',
        },
        arguments: [
          {
            name: 'settings',
            type: 'object',
            description: 'settings to save',
          },
        ],
        snippet: {
          lng: 'javascript',
          content: setSettingsSnippet,
        },
      },
    },
    {
      id: 16,
      name: 'getSettings',
      routeName: 'get settings',
      Component: Function,
      data: {
        description: {
          head: 'context.getSettings()',
          text: ' - Retrieve addon settings for the workspaces where the context entity is present. Can be called from any context.".',
        },
        returnValue: {
          type: 'Promise that resolves to an array containing the settings available for the current entity.',
        },
        arguments: [],
        snippet: {
          exampleText: 'An example of the returned value:',
          lng: 'javascript',
          content: getSettingsSnippet,
        },
      },
    },
    {
      id: 17,
      name: 'getPermissions',
      routeName: 'get Permissions',
      Component: Function,
      data: {
        description: {
          head: 'context.getPermissions()',
          text: ' - Returns an object with user access levels.".',
        },
        returnValue: {
          type: 'Promise that resolves to an object with user access levels..',
        },
        arguments: [],
        snippet: {
          exampleText: 'An example of the returned object:',
          lng: 'javascript',
          content: getPermissionsSnippet,
        },
      },
    },
    {
      id: 18,
      name: 'getContext',
      routeName: 'get Context',
      Component: Function,
      data: {
        description: {
          head: 'context.getContext()',
          text: ' - Returns context".',
        },
        returnValue: {
          type: 'Promise that resolves to an object with current context data',
        },
        arguments: [],
        snippet: {
          exampleText: 'An example of the returned object:',
          lng: 'javascript',
          content: getContextSnippet,
        },
      },
    },
    {
      id: 19,
      name: 'getCard',
      routeName: 'get card',
      Component: Function,
      data: {
        description: {
          head: 'context.getCard()',
          text: ' - Retrieve the card of the current context. Available only within the context of card sections(card_buttons, card_body_section, card_facade_badges).',
        },
        returnValue: {
          type: 'Promise that resolves to an object with card data',
        },
        arguments: [],
        snippet: {
          exampleText: 'An example of the returned object:',
          lng: 'json',
          content: getCardSnippet,
        },
      },
    },
    {
      id: 20,
      name: 'getCardProperties',
      routeName: 'get Card Properties',
      Component: Function,
      data: {
        description: {
          head: 'context.getCardProperties(subject)',
          text: ' - Retrieve additional card data. Available only within the context of card sections(card_buttons, card_body_section, card_facade_badges).',
        },
        returnValue: {
          type: 'Promise that resolves to an object with subject data',
        },
        arguments: [
          {
            name: 'subject',
            required: true,
            type: 'enum',
            description: 'Subject data to retrieve.\n type - card type\n members- card members\n tags- card tags\n customProperties - card custom properties',
          },
        ],
        snippet: {
          exampleText: 'An example of the returned object for subject "type":',
          lng: 'json',
          content: getCardPropertiesSnippet,
        },
      },
    },
    {
      id: 21,
      name: 'getCurrentUser',
      routeName: 'get Current User',
      Component: Function,
      data: {
        description: {
          head: 'context.getCurrentUser()',
          text: ' - Retrieve current user data.',
        },
        returnValue: {
          type: 'Promise that resolves to an object with current user data',
        },
        arguments: [],
        snippet: {
          exampleText: 'An example of the returned object:',
          lng: 'javascript',
          content: getCurrentUserSnippet,
        },
      },
    },
    {
      id: 22,
      name: 'signUrl',
      routeName: 'sign Url',
      Component: Function,
      data: {
        description: {
          head: 'context.signUrl(url, args)',
          text: ' - Accepts a URL (relative or absolute) and prepares this URL (by adding necessary values to the URL hash) to initialize it when opening an iframe.',
        },
        returnValue: {
          type: 'String',
        },
        arguments: [
          {
            name: 'url',
            required: true,
            type: 'string',
            description: 'String to prepare',
          },
          {
            name: 'args',
            type: 'object',
            description: 'This object will be passed to the iframe, and the values of this object can be obtained from the iframe using the function "iframe.arg(key)"',
          },
        ],
        alert: {
          severity: 'info',
          text: [
            "It's mandatory to use card_body_section within the iframe to prepare the URL (in case the iframe is not only for display)",
            "When opening popups, there's no need to call signUrl since this function will be called internally within the openPopup function.",
          ],
        },
        snippet: {
          exampleText: 'Example :',
          lng: 'javascript',
          content: signUrlSnippet,
        },
      },
    },
  ],
};
