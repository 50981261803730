import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  styled,
} from '@mui/material/styles';

const StyledTabs = styled(Tabs)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

function NavTabs(props) {
  const {
    value,
    onChange,
    keys,
    isScrollable,
  } = props;

  const Component = isScrollable ? Tabs : StyledTabs;

  return (
    <Component
      value={value}
      onChange={onChange}
      variant={isScrollable ? 'scrollable' : 'standard'}
    >
      {keys.map((key) => (
        <Tab
          sx={{ fontSize: '12px' }}
          key={key}
          label={key}
          value={key}
        />
      ))}
    </Component>
  );
}

NavTabs.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  keys: PropTypes.array.isRequired,
  isScrollable: PropTypes.bool.isRequired,
};

export default NavTabs;
