import eventAutomationDataPost from './data/eventAutomationDataPost';
import onDateAutomationDataPost from './data/onDateAutomationDataPost';
import onDemandAutomationDataPost from './data/onDemandAutomationDataPost';

import eventAutomationDataPatch from './data/eventAutomationDataPatch';
import onDateAutomationDataPatch from './data/onDateAutomationDataPatch';
import onDemandAutomationDataPatch from './data/onDemandAutomationDataPatch';

const getAutomationData = (method) => {
  const tableData = {
    tableName: 'Automation types and attributes',
    description: 'Explain automation types and its attributes',
    columns: ['Automation type', 'Attributes', 'Description'],
    data: [
      ['Event automation', method === 'POST' ? eventAutomationDataPost : eventAutomationDataPatch, 'Automation that triggered by events.\nExample: When card created on the board N  - add tags '],
      ['Date automation', method === 'POST' ? onDateAutomationDataPost : onDateAutomationDataPatch, 'Automation that triggered by date.\nExample: When 2 days left before card due dates - add sla'],
      ['Button automation', method === 'POST' ? onDemandAutomationDataPost : onDemandAutomationDataPatch, 'Automation which are triggered manually.\nThrough a button "Automation" in the card interface'],
    ],
  };
  return tableData;
};

export default getAutomationData;
