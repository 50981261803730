import timelogs from './timelogs.json';
import addResponse from './payloadExamples/add.json';
import updateResponse from './payloadExamples/update.json';
import removeResponse from './payloadExamples/remove.json';

timelogs.operations[0].payloadExample = addResponse;
timelogs.operations[1].payloadExample = updateResponse;
timelogs.operations[2].payloadExample = removeResponse;

export default timelogs;
