import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import { If, Then } from 'react-if';
import { get as registryGet } from '../lib/appRegistry';

const HeaderContainer = styled(AppBar)({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: '1px solid',
  borderBottomColor: 'rgba(0,0,0,.1)',
});

const Wrapper = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20px',
      paddingRight: '30px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  }),
);
const MenuButton = styled(IconButton)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
);
const ProjectName = styled(Typography)(
  ({ theme }) => ({
    marginLeft: '10px',
    fontWeight: 'medium',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'baseline',
  }),
);
const BoxWrapper = styled(Box)(
  ({ theme }) => ({
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
  }),
);
const StyledTooltip = styled(Tooltip)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
);
const isEditorAllowed = (pathName, isTablet) => {
  if (pathName.startsWith('/external-webhooks')
    || pathName.startsWith('/webhooks')
    || pathName.startsWith('/scim')) {
    return false;
  }
  return isTablet && pathName !== '/';
};

function Header({
  toggleNavList, openEditor, isMobile, isTablet,
}) {
  const { pathname } = useLocation();
  const { projectName, logoUrl } = registryGet('config');
  return (
    <HeaderContainer
      position="static"
    >
      <Container disableGutters maxWidth="xl">
        <Wrapper disableGutters>
          <If condition={isTablet}>
            <Then>
              <MenuButton onClick={toggleNavList}>
                <MenuIcon />
              </MenuButton>
            </Then>
          </If>
          <Link to="/" className=" logo-link">
            <img className="logo" src={logoUrl} alt="logo" />
            <ProjectName
              component="h6"
              variant="h6"
            >
              {projectName}
              <If condition={!isMobile}>
                <Then>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={{ color: 'text.secondary', ml: 1 }}
                  >
                    Developers
                  </Typography>
                </Then>
              </If>
            </ProjectName>
          </Link>
          <BoxWrapper>
            <If condition={isEditorAllowed(pathname, isTablet)}>
              <Then>
                <StyledTooltip title="Editor">
                  <IconButton onClick={openEditor}>
                    <EditIcon />
                  </IconButton>
                </StyledTooltip>
              </Then>
            </If>
          </BoxWrapper>
        </Wrapper>
      </Container>
    </HeaderContainer>
  );
}

Header.propTypes = {
  toggleNavList: PropTypes.func.isRequired,
  openEditor: PropTypes.func.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Header;
