/* eslint-disable react/jsx-props-no-spreading */
/* global navigator */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import {
  Button,
  Paper,
  IconButton,
  Popper,
  Fade,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import hljs from 'highlight.js';
import hljsCurl from 'highlightjs-curl';

const SuccessIconWrapper = styled(Paper)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }),
);

const SuccessIcon = styled(CheckCircleIcon)({
  color: 'green',
  paddingRight: '2px',
});

const Wrapper = styled(Paper)({
  position: 'relative',
});

const ButtonsRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const CopyButton = styled(IconButton)({
  marginLeft: 'auto',
});

const ContentWrapper = styled(Box)({
  overflow: 'auto',
  fontSize: '12px',
  padding: '5px 10px 5px 10px',
  maxWidth: '700px',
});

hljs.registerLanguage('curl', hljsCurl);

const addIndent = (str, indent) => {
  const indentedStr = str.replaceAll('\n', `\n${' '.repeat(indent)}`);
  return indentedStr.replaceAll(' }', '}');
};

const parseBody = (body) => {
  try {
    return JSON.parse(body);
  } catch (_err) {
    return null;
  }
};

const multipartCode = {
  curl: (params) => {
    const {
      method, path, token, contentType, body: bodyData,
    } = params;
    const { body, files, filesData } = bodyData;
    const parsedBody = parseBody(body);
    const dataFields = _.map(_.entries(parsedBody), ([key, value]) => `--form '${key}=${value}'`);
    let fileNumber = 1;
    const formFilesFields = _.map(files, (fileName) => {
      const currentFiles = filesData[files];
      return _.map(currentFiles, () => {
        const result = `--form '${fileName}=@/path/to/file${currentFiles.length > 1 ? fileNumber : ''}'`;
        fileNumber += 1;
        return result;
      }).join(`\\ \n${_.repeat(' ', 5)}`);
    });
    return `curl --request ${method} \\
     --url '${path}' \\
     --header 'Accept: application/json' \\
     --header 'Content-Type: ${contentType}'${token ? ` \\
     --header 'Authorization: Bearer ${token}'` : ''}${parsedBody ? ` \\
     ${dataFields.join('\\ \n')}` : ''}${!_.isEmpty(formFilesFields) ? ` \\
     ${formFilesFields.join('\\ \n')}` : ''}
     `;
  },

  node: (params) => {
    const {
      method, path, body: bodyData, token,
    } = params;
    const { body, files, filesData } = bodyData;
    const parsedBody = parseBody(body);
    const dataFields = _.map(_.entries(parsedBody), ([key, value]) => {
      const formattedValue = _.isNumber(value) || _.isBoolean(value) ? value : `"${value}"`;
      return `form.append('${key}', ${formattedValue});`;
    }).join('/n');
    let fileNumber = 1;
    const formFields = files.map((name) => {
      const currentFiles = filesData[files];
      return _.map(currentFiles, () => {
        const result = `form.append('${name}', file${currentFiles.length > 1 ? fileNumber : ''});`;
        fileNumber += 1;
        return result;
      }).join('\n');
    }).join('\n');
    return `const FormData = require("form-data");
const axios = require("axios");

const form = new FormData();
${parsedBody ? `${dataFields}` : ''}
${formFields}

const options = {
  method: "${method}",
  url: "${path}",
  headers: {${token ? `\n    Authorization: "Bearer ${token}",` : ''}
    ...form.getHeaders(),
  },
  data: form,
};

axios
  .request(options)
  .then(function (response) {
    console.log(response.data);
  })
  .catch(function (error) {
    console.error(error);
  });`;
  },
  php: (params) => {
    const {
      method, path, token, body: bodyData,
    } = params;
    const { body, files, filesData } = bodyData;
    const parsedBody = parseBody(body);
    const dataFields = _.map(_.entries(parsedBody), ([key, value]) => `[
        'name'     => '${key}',
        'contents' => '${value}'
     ],`);
    let fileNumber = 1;
    const formFields = files.map((fileName) => {
      const currentFiles = filesData[files];
      return _.map(currentFiles, () => {
        const result = `[
        'name'     => '${fileName}',
        'contents' => Psr7\\Utils::tryFopen('/path/to/file${currentFiles.length > 1 ? fileNumber : ''}', 'r')
     ],`;
        fileNumber += 1;
        return result;
      }).join(`\n${_.repeat(' ', 5)}`);
    });
    const isEmptyFormFields = _.isEmpty(formFields);
    return (
      `<?php
require_once('vendor/autoload.php');
use GuzzleHttp\\Psr7;

$client = new \\GuzzleHttp\\Client();

$response = $client->request(
  '${method}',
  '${path}',
  [
    ${token ? `'headers' => [
      'Authorization' => 'Bearer ${token}',
    ]${!isEmptyFormFields && ','}\n   ` : ''} ${!isEmptyFormFields ? `'multipart' => [
     ${parsedBody ? `${dataFields.join('\n')}` : ''} 
     ${formFields.join(', \n')}
    ]` : ''}
   
  ]);

echo $response->getBody();`);
  },
};

const codeMapping = {
  curl: (params) => {
    const {
      method, path, body, token, contentType,
    } = params;
    const bodyData = JSON.stringify(body);
    const result = bodyData.replaceAll('\\n', '').replaceAll('\\', '');
    return `curl --request ${method} \\
     --url '${path}' \\
     --header 'Accept: application/json' \\
     --header 'Content-Type: ${contentType}'${token ? ` \\
     --header 'Authorization: Bearer ${token}'` : ''}${body ? ` \\
     --data '${result.slice(1, -1)}'` : ''}
    `;
  },
  node: (params) => {
    const {
      method, path, body, token, contentType,
    } = params;
    const indent = 3;
    const bodyData = body && addIndent(body, indent);
    return `const axios = require("axios");

const options = {
  method: '${method}',
  url: '${path}',
  headers: {
    Accept: 'application/json',
    'Content-Type': '${contentType}' ${token ? `,
    Authorization: 'Bearer ${token}'` : ''}
  }${body ? `,
  data: ${bodyData}` : ''}
};
axios.request(options).then(function (response) {
  console.log(response.data);
  }).catch(function (error) {
    console.error(error);
});`;
  },
  php: (params) => {
    const {
      method, path, body, token, contentType,
    } = params;
    const indent = 5;
    const bodyData = body && addIndent(body, indent);
    return `<?php
require_once('vendor/autoload.php');

$client = new \\GuzzleHttp\\Client();

$response = $client->request(
  '${method}',
  '${path}',
  [${body ? `
    'body' => '${bodyData}',` : ''}
    'headers' => [
      'Accept' => 'application/json',${body ? `
      'Content-Type' => '${contentType}',` : ''}${token ? `
      'Authorization' => 'Bearer ${token}',` : ''}
    ],
  ]);

echo $response->getBody();`;
  },
};

const languageMapping = {
  curl: 'curl',
  node: 'javascript',
  php: 'php',
};

const addRowNumbers = (code) => {
  let rowNum = 1;
  const result = code.replaceAll('\n', (match) => {
    const currRowNum = String(rowNum += 1);
    const paddingBefore = currRowNum.length > 1 ? 1 : 2;
    return `${match}<span class ='grey'>${' '.repeat(
      paddingBefore,
    )}${currRowNum}${'  '}</span>`;
  });
  return `<span class='grey'>  1  </span>${result}`;
};

const higlightCode = (code, currentLng) => {
  const hlCode = hljs.highlight(code, {
    language: languageMapping[currentLng],
  }).value;
  return hlCode;
};

function CodeExample({
  path,
  method,
  token = '',
  body,
  contentType,
  children,
}) {
  const [currenLng, setLng] = useState('curl');
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const params = {
    method,
    path,
    body,
    token,
    contentType,
  };
  const code = contentType === 'multipart/form-data' ? multipartCode[currenLng](params) : codeMapping[currenLng](params);
  const hlCode = higlightCode(code, currenLng);
  const result = addRowNumbers(hlCode);
  const handleClick = (lng) => () => {
    setLng(lng);
  };
  const copy = (event) => {
    navigator.clipboard.writeText(code).then(() => {
      setTimeout(() => {
        setOpen(false);
      }, 1300);
      setAnchorEl(event.target);
      setOpen(true);
    });
  };
  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="top" transition style={{ zIndex: '5000' }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <SuccessIconWrapper>
              <SuccessIcon />
              <Typography component="span">Copied</Typography>
            </SuccessIconWrapper>
          </Fade>
        )}
      </Popper>
      <ButtonsRow>
        <Button disabled={currenLng === 'curl'} onClick={handleClick('curl')}>
          Curl
        </Button>
        <Button disabled={currenLng === 'node'} onClick={handleClick('node')}>
          NodeJs
        </Button>
        <Button disabled={currenLng === 'php'} onClick={handleClick('php')}>
          php
        </Button>
        <CopyButton onClick={copy}>
          <ContentCopyIcon fontSize="small" />
        </CopyButton>
      </ButtonsRow>
      <Wrapper variant="outlined">
        <ContentWrapper>
          <pre dangerouslySetInnerHTML={{ __html: result }} />
        </ContentWrapper>
        <Divider />
        {children}
      </Wrapper>
    </>
  );
}

CodeExample.propTypes = {
  path: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  token: PropTypes.string,
  body: PropTypes.string || PropTypes.array,
  contentType: PropTypes.string,
  children: PropTypes.any,
};

CodeExample.defaultProps = {
  token: '',
  body: null,
  contentType: '',
  children: undefined,
};
export default CodeExample;
