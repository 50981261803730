import files from './cardFiles.json';
import putResponse from './responseExamples/200/put.json';
import updateResponse from './responseExamples/200/update.json';
import deleteResponse from './responseExamples/200/delete.json';
import putSchema from '../schema/put/cardFile.json';
import updateSchema from '../schema/update/cardFile.json';

files.operations[0].request.schema = putSchema;
files.operations[1].request.schema = updateSchema;

files.operations[0].responces[0].example = putResponse;
files.operations[1].responces[0].example = updateResponse;
files.operations[2].responces[0].example = deleteResponse;

export default files;
