import _ from 'lodash';
import customPropertyTypes from './customPropertyTypes';
import automationComparatorsForType from './automationComparatorsForType';

const getDescription = (types) => _.join(_.map(types, (type) => `${type} - ${automationComparatorsForType.descriptionForProperty[type]}`), '\n');

const tableData = {
  tableName: 'Condition comparators for custom_property condition',
  columns: ['Custom property type', 'Availible condition comparators', 'Description'],
  data: [
    {
      key: customPropertyTypes.string,
      entries: [
        customPropertyTypes.string,
        automationComparatorsForType.comparatorsForPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.multylineString,
      entries: [
        customPropertyTypes.multylineString,
        automationComparatorsForType.comparatorsForProperty,
        getDescription(automationComparatorsForType.comparatorsForProperty),
      ],
    },
    {
      key: customPropertyTypes.number,
      entries: [
        customPropertyTypes.number,
        automationComparatorsForType.comparatorsForPropertyWithComparativeType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithComparativeType),
      ],
    },
    {
      key: customPropertyTypes.date,
      entries: [
        customPropertyTypes.date,
        automationComparatorsForType.comparatorsForProperty,
        getDescription(automationComparatorsForType.comparatorsForProperty),
      ],
    },
    {
      key: customPropertyTypes.email,
      entries: [
        customPropertyTypes.email,
        automationComparatorsForType.comparatorsForPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.checkbox,
      entries: [
        customPropertyTypes.checkbox,
        automationComparatorsForType.comparatorsForProperty,
        getDescription(automationComparatorsForType.comparatorsForProperty),
      ],
    },
    {
      key: customPropertyTypes.select,
      entries: [
        customPropertyTypes.select,
        automationComparatorsForType.comparatorsForPropertyWithSelectType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithSelectType),
      ],
    },
    {
      key: customPropertyTypes.multySelect,
      entries: [
        customPropertyTypes.multySelect,
        automationComparatorsForType.comparatorsForProperty,
        getDescription(automationComparatorsForType.comparatorsForProperty),
      ],
    },
    {
      key: customPropertyTypes.url,
      entries: [
        customPropertyTypes.url,
        automationComparatorsForType.comparatorsForPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.voteRating,
      entries: [
        customPropertyTypes.voteRating,
        automationComparatorsForType.comparatorsForPropertyWithComparativeType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithComparativeType),
      ],
    },
    {
      key: customPropertyTypes.voteEmoji,
      entries: [
        customPropertyTypes.voteEmoji,
        automationComparatorsForType.comparatorsForPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.collectiveVote,
      entries: [
        customPropertyTypes.collectiveVote,
        automationComparatorsForType.comparatorsForPropertyWithCollectiveComparativeType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithCollectiveComparativeType),
      ],
    },
    {
      key: customPropertyTypes.catalog,
      entries: [
        customPropertyTypes.catalog,
        automationComparatorsForType.comparatorsForPropertyWithSelectType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithSelectType),
      ],
    },
    {
      key: customPropertyTypes.phone,
      entries: [
        customPropertyTypes.phone,
        automationComparatorsForType.comparatorsForPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.user,
      entries: [
        customPropertyTypes.user,
        automationComparatorsForType.comparatorsForPropertyWithSelectType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithSelectType),
      ],
    },
    {
      key: customPropertyTypes.multyUser,
      entries: [
        customPropertyTypes.multyUser,
        automationComparatorsForType.comparatorsForPropertyWithEqType,
        getDescription(automationComparatorsForType.comparatorsForPropertyWithEqType),
      ],
    },
    {
      key: customPropertyTypes.attachment,
      entries: [
        customPropertyTypes.attachment,
        automationComparatorsForType.comparatorsForProperty,
        getDescription(automationComparatorsForType.comparatorsForProperty),
      ],
    },
  ],
};

export default tableData;
