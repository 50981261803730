/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState, useContext } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Container,
  Box,
  Drawer,
  Toolbar,
  Typography,
  IconButton,
} from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import {
  ThemeProvider, useTheme, styled,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { If, Then, Else } from 'react-if';
import Header from './components/Header';
import './App.css';
import Api from './components/Api';
import Head from './components/Head';
import { get as registryGet } from './lib/appRegistry';
import Footer from './components/Footer';
import NavTabs from './components/NavTabs';
import { ApiContext } from './context';

const ColumnWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const Wrapper = styled(Box)({
  display: 'flex',
});
const NavWrapper = styled(Box)(
  ({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      width: '21%',
      borderRight: '1px solid',
      borderRightColor: 'rgba(0,0,0,.1)',
    },
  }),
);
const OutletWrapper = styled(Box)(
  ({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '79%',
    },
  }),
);
const StyledToolbar = styled(Toolbar)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
);
const ProjectName = styled(Typography)(
  ({ theme }) => ({
    marginLeft: '10px',
    display: 'inline-block',
    fontWeight: 'medium',
    color: theme.palette.text.primary,
  }),
);
const ToggleButton = styled(IconButton)({
  marginLeft: 'auto',
});
const StyledContainer = styled(Container)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),
);

function App({ api }) {
  const apies = useContext(ApiContext);

  const [navState, setNavState] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [currentApi, setCurrentApi] = useState(api);
  const navigate = useNavigate();

  const toggleNavList = () => setNavState(!navState);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [editorState, setEditorState] = useState(false);
  const { projectName, logoUrl } = registryGet('config');
  const openEditor = () => {
    if (!isTablet) return;
    setEditorState(true);
  };
  const closeEditor = () => setEditorState(false);
  const handleScroll = () => setScroll(!scroll);

  const handleChange = (_event, newValue, route) => {
    const newRoute = route || apies[newValue].api.defaultRoute;
    setCurrentApi(newValue);
    navigate(newRoute);
  };

  const apiKeys = _.keys(apies);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Head />
        <Box>
          <Header
            toggleNavList={toggleNavList}
            openEditor={openEditor}
            isTablet={isTablet}
            isMobile={isMobile}
          />
          <StyledContainer disableGutters maxWidth="xl">
            <Box>
              <If condition={!isTablet}>
                <NavTabs
                  isScrollable={isTablet}
                  value={currentApi}
                  onChange={handleChange}
                  keys={apiKeys}
                />
              </If>
              <Wrapper>
                <NavWrapper>
                  <If condition={isTablet}>
                    <Then>
                      <Drawer
                        open={navState}
                        anchor="left"
                        onClose={toggleNavList}
                        PaperProps={{
                          sx: {
                            width: {
                              xs: '100%',
                              sm: '400px',
                            },
                          },
                        }}
                      >
                        <StyledToolbar>
                          <Link to="/" className=" logo-link">
                            <img className="logo" src={logoUrl} alt="logo" />
                            <ProjectName
                              component="h6"
                              variant="h6"
                            >
                              {projectName}
                            </ProjectName>
                          </Link>
                          <ToggleButton onClick={toggleNavList}>
                            <KeyboardDoubleArrowLeftIcon />
                          </ToggleButton>
                        </StyledToolbar>
                        <Api
                          showTabs
                          scrollClassName="nav-mobile"
                          toggleNavList={toggleNavList}
                          scroll={scroll}
                          apies={apies}
                          apiKeys={apiKeys}
                          currentApi={currentApi}
                          handleTabs={handleChange}
                        />
                      </Drawer>
                    </Then>
                    <Else>
                      <Api
                        showTabs={false}
                        scroll={scroll}
                        apies={apies}
                        apiKeys={apiKeys}
                        currentApi={currentApi}
                        handleTabs={handleChange}
                      />
                    </Else>
                  </If>
                </NavWrapper>
                <OutletWrapper>
                  <Box>
                    <Outlet
                      context={{
                        editorState,
                        openEditor,
                        closeEditor,
                        isTablet,
                        api,
                        setScroll: handleScroll,
                        changeApi: handleChange,
                      }}
                    />
                  </Box>
                </OutletWrapper>
              </Wrapper>
            </Box>
          </StyledContainer>
        </Box>
      </ThemeProvider>
    </>
  );
}

App.propTypes = {
  api: PropTypes.string.isRequired,
};

export default App;
