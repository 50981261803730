import Function from '../../../components/Addons/SdkFunctions/Function';
import ShowSnackbar from '../../../components/Addons/SdkFunctions/ShowSnackbar';
import openPopup from '../../../components/Addons/SdkFunctions/openPopup';
import closePopupSnippet from './examples/closePopup';
import openDialogSnippet from './examples/openDialog';
import dialog1Img from '../../../images/dialog_1.png';
import dialog2Img from '../../../images/dialog_2.png';

const renderSnippet = `const iframe = Addon.iframe();

const testLogField = document.getElementById('logText');

iframe.render(() => {
  iframe.getData('card', 'private', 'timerLog')
    .then((response) => {
      if (response) {
        testLogField.value = response;
      }
    });
});

// In this case, if addon data within the card context changes, the value of the DOM element #logText will be updated`;

const fitSizeSnippet = `const iframe = Addon.iframe();

iframe.fitSize('#timerLogContent');`;

export default {
  name: 'Web Sdk UI functions',
  id: 23,
  operations: [
    {
      id: 24,
      name: 'render',
      Component: Function,
      data: {
        description: {
          head: 'iframe.render(() => {})',
          text: " - Every time you display a visible iframe, we want to ensure that it doesn't flicker or flash when there are changes in related data",
          additionalTexts: [
            "A good example of this is the 'card_body_section' (iframe within the body of an open card). When addon data changes within the card context, you may need to adjust the display, but we don't want to reload your iframe as it could cause visual artifacts.",
            "Instead, you should ensure that any logic updating your user interface resides within 'iframe.render'. We'll call iframe.render whenever something changes that, in our view, affects the iframe's display.",
          ],
        },
        returnValue: {
          type: 'Void',
        },
        arguments: [],
        snippet: {
          lng: 'javascript',
          content: renderSnippet,
        },
      },
    },
    {
      id: 24,
      name: 'openPopup',
      routeName: 'open Popup',
      Component: openPopup,
    },
    {
      id: 26,
      name: 'closePopup',
      routeName: 'close Popup',
      Component: Function,
      data: {
        description: {
          head: 'context.closePopup()',
          text: ' - Close the current open popup.',
        },
        returnValue: {
          type: 'Void',
        },
        arguments: [],
        alert: {
          severity: 'info',
          text: 'If there are nested popups open in the current popup, calling this function from any popup will close the entire popup (including all nested ones).',
        },
        snippet: {
          lng: 'javascript',
          content: closePopupSnippet,
        },
      },
    },
    {
      id: 27,
      name: 'openDialog',
      routeName: 'open Dialog',
      Component: Function,
      data: {
        description: {
          head: 'context.openDialog(params)',
          text: ' - Triggers the appearance of a modal window.',
        },
        returnValue: {
          type: 'Promise',
        },
        arguments: [
          {
            name: 'params',
            type: 'object',
            description: 'params for modal',
            properties: {
              url: {
                type: 'string',
                required: true,
                description: 'The URL of the page to be displayed within the modal body in an iframe.',
              },
              title: {
                type: 'string',
                description: 'The title of the modal window.',
              },
              height: {
                type: 'integer',
                description: 'The height of the modal window',
              },
              width: {
                enum: ['xs', 'sm', 'md', 'lg', 'xl'],
                description: 'The width of the modal window',
              },
              fullScreen: {
                type: 'boolean',
                description: 'If the value is true, the dialog will occupy the entire available width and height (height and width will be ignored in this case).',
              },
              primaryActionCallback: {
                type: 'function',
                description: 'If the function is defined, a primary button will be displayed at the bottom of the modal window. Clicking the button will trigger this function.',
              },
              primaryActionLabel: {
                type: 'string',
                description: 'The text of the primary action button.',
              },
              secondaryActionCallback: {
                type: 'function',
                description: 'If the function is defined, a secondary button will be displayed at the bottom of the modal window. Clicking the button will trigger this function.',
              },
              secondaryActionLabel: {
                type: 'string',
                description: 'The text of the secondary action button.',
              },
              onCloseCallback: {
                type: 'function',
                description: 'The function that will be called when the modal is closed.',
              },
              additionalActions: {
                type: 'array',
                description: 'The icons/buttons that will be displayed in the top right corner of the modal, next to the close button.',
                items: {
                  type: 'object',
                  properties: {
                    iconUrl: {
                      type: 'string',
                      description: 'The URL of the icon that will be displayed on the button.',
                    },
                    title: {
                      type: 'string',
                      description: 'The text that will be shown in the tooltip when hovering over the button.',
                    },
                    url: {
                      type: 'string',
                      description: 'The address that will be opened in a new window when the button is clicked.\n Either a url or a callback must be defined.',
                    },
                    callback: {
                      type: 'function',
                      description: 'The function that will be called when the button is clicked..\n Either a url or a callback must be defined.',
                    },
                  },
                  required: ['iconUrl'],
                },
              },
            },
            required: ['url'],
          },
        ],
        images: [
          {
            src: dialog1Img,
            width: '100%',
            alt: 'dialog screen 1',
          },
          {
            src: dialog2Img,
            width: 600,
            alt: 'dialog screen 2',
          },
        ],
        snippet: {
          lng: 'javascript',
          content: openDialogSnippet,
        },
      },
    },
    {
      id: 28,
      name: 'closeDialog',
      routeName: 'close Dialog',
      Component: Function,
      data: {
        description: {
          head: 'context.closeDialog()',
          text: ' - Close the current open modal.',
        },
        returnValue: {
          type: 'Promise',
        },
        arguments: [],
      },
    },
    {
      id: 29,
      name: 'showSnackbar',
      routeName: 'show Snackbar',
      Component: ShowSnackbar,
    },
    {
      id: 30,
      name: 'fitSize',
      routeName: 'fit Size',
      Component: Function,
      data: {
        description: {
          head: "context.fitSize('#myElementId')",
          text: " - Available only within the iframe context. Calculates the actual height of the provided element and adjusts the iframe's available height to match the actual height (for example, when used with the 'card_body_section' iframe, using this function will adjust the iframe's height to eliminate scrolling or empty space).",
        },
        returnValue: {
          type: 'Promise',
        },
        arguments: [
          {
            name: '#myElementId',
            type: 'string | domElement | number',
            description: 'Accepts the class name or ID of an element, or a DOM element, or a number (height).',
          },
        ],
        snippet: {
          lng: 'javascript',
          content: fitSizeSnippet,
        },
      },
    },
  ],
};
