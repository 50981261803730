import React from 'react';
import {
  Typography,
} from '@mui/material';
import hljs from 'highlight.js';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import Footer from '../../Footer';
import { Wrapper, SectionTitleWrapper } from '../../../lib/Components/StyledComponents';

const example = `const iframe = Addon.iframe();

iframe.fitSize('#timerLogContent');

iframe.getCard().then((response) => {
  console.log(response);
});`;

function IframeInitialize() {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title="Addon.iframe()" hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom>
          Used in additional iframes (which are rendered either in popups or in sections supporting iframes).
          When this function is called, an object is retrieved that provides access to all methods available in the web SDK.
        </Typography>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          Example :
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
        </Snippet>

        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default IframeInitialize;
