/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'auto',
    scrollbarColor: '#999 rgba(0, 0, 0, .15)',
    '&::-webkit-scrollbar': {
      height: 8,
      width: 8,
    },
    '&::-webkit-scrollbar-button': {
      display: 'block',
      height: 2,
      width: 2,
    },
    '&::-webkit-scrollbar-track-piece': {
      background: 'rgba(0, 0, 0, .15)',
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#999',
      borderRadius: 4,
    },
    '& > div': {
      listStyle: 'none',
    },
  },
}));

const NativeScrollContainer = React.forwardRef((props, ref) => {
  const {
    className,
    children,
  } = props;

  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={cn(classes.root, className)}
    >
      {children}
    </div>
  );
});

NativeScrollContainer.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

NativeScrollContainer.defaultProps = {
  className: undefined,
};

export default NativeScrollContainer;
