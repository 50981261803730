import customPropertyTypes from './customPropertyTypes';

const tableData = {
  tableName: 'Custom property value types',
  columns: ['Custom property type', 'Value type', 'Description'],
  data: [
    {
      key: customPropertyTypes.string,
      entries: [
        customPropertyTypes.string,
        'string | null',
        '',
      ],
    },
    {
      key: customPropertyTypes.multylineString,
      entries: [
        customPropertyTypes.multylineString,
        'null',
        '',
      ],
    },
    {
      key: customPropertyTypes.number,
      entries: [
        customPropertyTypes.number,
        'number | null',
        '',
      ],
    },
    {
      key: customPropertyTypes.date,
      entries: [
        customPropertyTypes.date,
        'null',
        '',
      ],
    },
    {
      key: customPropertyTypes.email,
      entries: [
        customPropertyTypes.email,
        'string | null',
        '',
      ],
    },
    {
      key: customPropertyTypes.checkbox,
      entries: [
        customPropertyTypes.checkbox,
        'null',
        '',
      ],
    },
    {
      key: customPropertyTypes.select,
      entries: [
        customPropertyTypes.select,
        'array of integers | null',
        'Array must contain ids of select options',
      ],
    },
    {
      key: customPropertyTypes.multySelect,
      entries: [
        customPropertyTypes.multySelect,
        'null',
        '',
      ],
    },
    {
      key: customPropertyTypes.url,
      entries: [
        customPropertyTypes.url,
        'string | null',
        '',
      ],
    },
    {
      key: customPropertyTypes.voteRating,
      entries: [
        customPropertyTypes.voteRating,
        'number | null',
        '',
      ],
    },
    {
      key: customPropertyTypes.voteEmoji,
      entries: [
        customPropertyTypes.voteEmoji,
        'string | null',
        'Emoji to compare.',
      ],
    },
    {
      key: customPropertyTypes.collectiveVote,
      entries: [
        customPropertyTypes.collectiveVote,
        'number | null',
        '',
      ],
    },
    {
      key: customPropertyTypes.catalog,
      entries: [
        customPropertyTypes.catalog,
        'array of strings | null',
        'Array must contain ids of catalog options',
      ],
    },
    {
      key: customPropertyTypes.phone,
      entries: [
        customPropertyTypes.phone,
        'string | null',
        '',
      ],
    },
    {
      key: customPropertyTypes.user,
      entries: [
        customPropertyTypes.user,
        'array of strings | null',
        'Array must contain users uuid ',
      ],
    },
    {
      key: customPropertyTypes.multyUser,
      entries: [
        customPropertyTypes.multyUser,
        'array of strings | null',
        'Array must contain users uuid ',
      ],
    },
    {
      key: customPropertyTypes.attachment,
      entries: [
        customPropertyTypes.attachment,
        'null',
        '',
      ],
    },
  ],
};

export default tableData;
