import React from 'react';
import {
  Typography, Link as MuiLink,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import hljs from 'highlight.js';
import NativeScrollContainer from '../../NativeScrollContainer';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import Footer from '../../Footer';
import { Wrapper, SectionTitleWrapper, MainContent } from '../../../lib/Components/StyledComponents';
import newAddonImg from '../../../images/new_addon.png';

const example = `settings: (settingsContext) => {
  return settingsContext.openPopup({
    title: 'Timer settings',
    url: './settings.html',
    height: 200,
    width: 300
  });
}`;

function Settings() {
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <MainContent>
          <SectionTitleWrapper>
            <SectionTitle variant="h4" title="Settings" hash="#header" />
          </SectionTitleWrapper>
          <Typography gutterBottom sx={{ marginBotton: 2 }}>
            Configuring the add-on for a specific space.
            If this option is selected in the add-on settings and the corresponding function is defined in the
            {' '}
            <MuiLink color="secondary" component={RouterLink} to="/addons/initialization/addon-initialize">
              initialize
            </MuiLink>
            {' '}
            function, the add-on settings popover in the space will display the &apos;Add-on Settings&apos; menu item.
          </Typography>
          <img width="400" src={newAddonImg} alt="new addon" />
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            Example :
          </Typography>
          <Snippet>
            <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(example, { language: 'javascript' }).value }} />
          </Snippet>
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            <b>Function argument</b>
            {' '}
            - an object that provides all available functions of the web SDK.
          </Typography>
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            <b>Return value</b>
            {' '}
            -  void or any other value.
          </Typography>
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            (Standard behavior - opening a popup with the necessary settings)
          </Typography>
        </MainContent>
        <Footer sx={{ marginTop: 'auto' }} />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Settings;
