import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Typography,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Alert,
  Box,
  Paper,
} from '@mui/material';
import { If, Then } from 'react-if';
import hljs from 'highlight.js';
import { styled } from '@mui/material/styles';
import SectionTitle from '../../SectionTitle';
import Snippet from '../../Snippet';
import {
  StyledTable,
  TableCellContent,
  TableHeader,
  Caption,
  InnerSchemaWrapper,
} from '../../../lib/Components/StyledComponents';

import InnerSchemaDialog from '../../Schema/InnerSchemaDialog';

const TableCellContentPre = styled(TableCell)(
  () => ({
    whiteSpace: 'pre-line',
  }),
);

function FunctionContent({ data }) {
  const {
    description,
    arguments: argumentsData,
    returnValue,
    alert,
    additionalInfo,
    snippet,
    images,
  } = data;
  return (
    <>
      <Typography gutterBottom>
        <b>{description.head}</b>
        {' '}
        {description.text}
      </Typography>
      <If condition={!_.isEmpty(description.additionalTexts)}>
        <Then>
          {_.map(description.additionalTexts, (text, index) => (
            <Typography key={index} gutterBottom sx={{ marginTop: 2 }}>
              {text}
            </Typography>
          ))}
        </Then>
      </If>
      <If condition={!_.isEmpty(argumentsData)}>
        <Then>
          <Paper elevation={0}>
            <TableHeader>
              <SectionTitle title="Arguments" hash="#arguments" />
            </TableHeader>
            <TableContainer>
              <StyledTable size="small">
                <TableHead>
                  <TableRow>
                    <TableCellContent>argument</TableCellContent>
                    <TableCellContent>Type</TableCellContent>
                    <TableCellContent>Description</TableCellContent>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(argumentsData, (arg) => (
                    <TableRow>
                      <TableCellContent>
                        {arg.name}
                        <If condition={arg.required}>
                          <Then>
                            <Caption
                              variant="caption"
                              color="error"
                            >
                              required
                            </Caption>
                          </Then>
                        </If>
                      </TableCellContent>
                      <TableCellContent>{arg.type}</TableCellContent>
                      <TableCellContentPre>
                        {arg.description}
                        <If condition={arg.properties}>
                          <Then>
                            <InnerSchemaWrapper>
                              <InnerSchemaDialog data={arg} name={arg.name} />
                            </InnerSchemaWrapper>
                          </Then>
                        </If>

                      </TableCellContentPre>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Paper>

        </Then>
      </If>
      <Typography gutterBottom sx={{ marginTop: 2 }}>
        <b>Return value</b>
        {' '}
        -
        {' '}
        {returnValue.type}
      </Typography>
      <If condition={alert}>
        <Then>
          <Alert sx={{ marginTop: 2 }} severity={alert?.severity}>
            {_.isArray(alert?.text) ? _.map(alert?.text, (paragraph, index) => (
              <Typography key={index} sx={{ marginTop: index > 0 ? 2 : 0 }}>
                {paragraph}
              </Typography>
            )) : alert?.text}
          </Alert>
        </Then>
      </If>
      <If condition={additionalInfo}>
        <Then>
          <Typography gutterBottom sx={{ marginTop: 2 }}>
            {additionalInfo?.text}
          </Typography>
        </Then>
      </If>
      <If condition={!_.isEmpty(images)}>
        <Then>
          {_.map(images, ({ src, alt, width }) => (
            <Box key={src} sx={{ marginTop: 2 }}>
              <img width={width} src={src} alt={alt} />
            </Box>
          ))}
        </Then>
      </If>
      {snippet && (
      <Box sx={{ marginTop: 2 }}>
        <If condition={snippet.exampleText}>
          <Then>
            <Typography sx={{ marginBottom: 1 }}>{snippet.exampleText }</Typography>
          </Then>
        </If>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(snippet.content, { language: snippet.lng }).value }} />
        </Snippet>
      </Box>
      )}

    </>

  );
}

FunctionContent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FunctionContent;
