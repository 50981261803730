import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const Icon = styled(Chip)(
  () => ({
    borderRadius: '50%',
    width: '10px',
    height: '10px',
  }),
);

const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const Code = styled(Typography)(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
    fontSize: '16px',
  }),
);

function ResponseCode({ code, type }) {
  return (
    <Wrapper>
      <Icon
        color={type}
        component="span"
      />
      <Code variant="h6" component="span">
        {code}
      </Code>
    </Wrapper>
  );
}

ResponseCode.propTypes = {
  code: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default ResponseCode;
