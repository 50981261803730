import recipients from './sdEternalRecipient.json';
import createSchema from '../schema/create/sdExternalRecipients.json';
import postResponse from './responseExamples/200/post.json';
import deleteResponse from './responseExamples/200/delete.json';

recipients.operations[0].request.schema = createSchema;
recipients.operations[0].responces[0].example = postResponse;
recipients.operations[1].responces[0].example = deleteResponse;

export default recipients;
