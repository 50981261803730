import React from 'react';
import {
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NativeScrollContainer from '../NativeScrollContainer';
import SectionTitle from '../SectionTitle';
import Snippet from '../Snippet';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

const SectionTitleWrapper = styled('div')(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  }),
);

function Intro() {
  const { projectName, hostName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title={`${projectName} SCIM API`} hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom variant="subtitle1">
          System for Cross-domain Identity Management (SCIM) provides standard schema and protocol
          for user management between different applications through your Identity Provider.
        </Typography>
        <Typography gutterBottom>
          {'Base URL: '}
          <Snippet inline removeGutters>{`https://<your_domain>.${hostName}/scim/v2`}</Snippet>
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Use API Bearer token for authentication.
          The token can be used both as an
          {' '}
          <Snippet inline removeGutters>
            {'<api_key>'}
          </Snippet>
          {' '}
          and as a
          {' '}
          <Snippet inline removeGutters>
            {'Bearer <api_key>'}
          </Snippet>
          {' '}
          depending on requirements of your Identity Provider.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Example:
        </Typography>
        <Snippet removeGutters>
          Bearer 296a5709-99bc-49a7-96e0-c0a1b236091f
        </Snippet>
        <Typography gutterBottom>
          You can get the API key in the user profile.
        </Typography>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default Intro;
