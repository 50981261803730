import React, { useContext } from 'react';
import _ from 'lodash';
import { useParams, useOutletContext } from 'react-router-dom';
import { ApiContext } from '../../context';

function AddonInfo() {
  const { entity, operation } = useParams();
  const { api } = useOutletContext();
  const { keydEntities } = useContext(ApiContext)[api];
  const currentOperation = _.get(keydEntities, [
    entity,
    'operations',
    operation,
  ]);
  const { Component, data, name } = currentOperation;
  return (
    <Component data={data} name={name} />
  );
}

export default AddonInfo;
