import files from './files.json';
import addResponse from './payloadExamples/add.json';
import updateResponse from './payloadExamples/update.json';
import removeResponse from './payloadExamples/remove.json';

files.operations[0].payloadExample = addResponse;
files.operations[1].payloadExample = updateResponse;
files.operations[2].payloadExample = removeResponse;

export default files;
