import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
  Link as MuiLink,
} from '@mui/material';
import NativeScrollContainer from '../NativeScrollContainer';
import Snippet from '../Snippet';
import sentryVid from '../../video/sentry.mp4';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';

const Wrapper = styled(Box)(
  ({ theme }) => ({
    padding: `18px ${theme.spacing(1)}  30px ${theme.spacing(1)}`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  }),
);

const VideoWrapper = styled('div')(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }),
);
const BoldText = styled(Typography)({
  fontWeight: 'bold',
});

const StyledLink = styled(MuiLink)({
  marginLeft: '4px',
  marginRight: '4px',
});

const StyledTypography = styled(Typography)({
  marginTop: '5px',
  marginBottom: '5px',
});

function SentryIntegration() {
  const { projectName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <Typography gutterBottom variant="h4" component="h1">
          Sentry Integrations
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Sentry Integrations will make it possible to automatically create card
          when an error occurs in your application.
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Sentry integration is two-step process.
        </Typography>
        <Typography component="span" gutterBottom variant="subtitle1">
          First you have to
        </Typography>
        <StyledLink underline="hover" color="secondary" component={RouterLink} to="/webhooks#setup">
          create webhook
        </StyledLink>
        <Typography component="span" gutterBottom variant="subtitle1">
          {' in '}
          {projectName}
          {' '}
          and choose
        </Typography>
        <BoldText gutterBottom component="span" variant="subtitle1">Sentry Format.</BoldText>
        <StyledTypography sx={{ my: '5px' }} variant="subtitle1">
          Next go to your account in Sentry and follow instruction:
        </StyledTypography>
        <ul>
          <li>
            <Typography component="span" variant="subtitle1">
              Go to
              {' '}
            </Typography>
            <Snippet inline removeGutters>{'Settings -> Integrations.'}</Snippet>
          </li>
          <li>
            <Typography component="span" variant="subtitle1">
              Find
              {' '}
            </Typography>
            <BoldText gutterBottom component="span" variant="subtitle1">WebHooks</BoldText>
            <Typography component="span" variant="subtitle1">
              {' '}
              (use the search field if the integration is not in the list).
            </Typography>
          </li>
          <li>
            <Typography component="span" variant="subtitle1">
              Click
              {' '}
            </Typography>
            <BoldText gutterBottom component="span" variant="subtitle1">Add to Project</BoldText>
            <Typography component="span" variant="subtitle1">
              {' '}
              and select the project for which you want to connect the integration.
            </Typography>
          </li>
          <li>
            <Typography component="span" variant="subtitle1">
              Type your URL and click
              {' '}
            </Typography>
            <BoldText gutterBottom component="span" variant="subtitle1">Save Changes.</BoldText>
          </li>
          <li>
            <Typography component="span" variant="subtitle1">
              Click on
              {' '}
            </Typography>
            <BoldText gutterBottom component="span" variant="subtitle1">Enable Plugin.</BoldText>
          </li>
        </ul>
        <Typography component="span" variant="subtitle1">
          Below is a video that will help you.
        </Typography>
        <VideoWrapper>
          <video className="vid" src={sentryVid} preload controls />
        </VideoWrapper>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default SentryIntegration;
