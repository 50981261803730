import _ from 'lodash';

const store = {};

export function get(name) {
  if (!_.has(store, name)) {
    throw new Error(`${name} has not been set yet`);
  }

  return store[name];
}

export function set(name, value) {
  if (_.has(store, name)) {
    throw new Error(`${name} has already been set`);
  }

  store[name] = value;
}

export function unset(name) {
  delete store[name];
}
