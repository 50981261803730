export default `{
  "archived": false,
  "asap": false,
  "blocked": false,
  "blocking_card": false,
  "board_id": 9,
  "children_count": 0,
  "children_done": 0,
  "has_blocked_children": false,
  "column_id": 26,
  "completed_at": null,
  "completed_on_time": null,
  "condition": 1,
  "created": "2023-12-18T13:24:03.599Z",
  "description": null,
  "description_filled": false,
  "due_date": null,
  "due_date_time_present": false,
  "expires_later": false,
  "fifo_order": null,
  "goals_done": 0,
  "goals_total": 0,
  "id": 516,
  "lane_id": 15,
  "last_moved_at": "2023-12-18T13:24:03.599Z",
  "column_changed_at": "2023-12-18T13:24:03.599Z",
  "lane_changed_at": "2023-12-18T13:24:03.599Z",
  "owner_id": 1,
  "parent_checklist_ids": null,
  "parents_ids": null,
  "planned_start": null,
  "planned_end": null,
  "children_ids": null,
  "parents_count": 0,
  "size_text": null,
  "size_unit": null,
  "size": null,
  "sort_order": 1.813081833861443,
  "state": 2,
  "title": "111",
  "type_id": 1,
  "updated": "2023-12-18T13:24:03.599Z",
  "updater_id": 1,
  "version": 1,
  "email": "kaiten-test+comment-90e803b5cb4264b8-c516@yandex.ru",
  "first_moved_to_in_progress_at": "2023-12-18T13:24:03.599Z",
  "last_moved_to_done_at": null,
  "comments_total": 0,
  "comment_last_added_at": null,
  "properties": null,
  "service_id": null,
  "sd_new_comment": false,
  "share_id": null,
  "share_settings": null,
  "public": false,
  "external_user_emails": null,
  "time_spent_sum": 0,
  "calculated_planned_start": null,
  "calculated_planned_end": null,
  "time_blocked_sum": 0,
  "children_number_properties_sum": null,
  "estimate_workload": 0
}`;
