import React from 'react';
import {
  Typography,
  Alert,
} from '@mui/material';
import hljs from 'highlight.js';
import NativeScrollContainer from '../NativeScrollContainer';
import SectionTitle from '../SectionTitle';
import Snippet from '../Snippet';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from '../Footer';
import createAddonImg from '../../images/create_addon.png';
import addonSettingsImg from '../../images/addon_settings.png';
import newAddonImg from '../../images/new_addon.png';
import cardButtonsImg from '../../images/card_buttons.png';
import iframeCardImg from '../../images/iframe_in_the_card.png';
import facadeBadgesImg from '../../images/facade_badges.png';
import { Wrapper, SectionTitleWrapper } from '../../lib/Components/StyledComponents';

const exampleCode = {
  server: `const express = require('express');
const path = require('path');
const cors = require('cors');

const app = express();

app.use(cors({ origin: '*' }));

app.use(express.static(path.join(__dirname, 'public/views')));

app.use('/js', express.static(path.join(__dirname, 'public/js')));

const port = process.env.PORT || 1111;

app.listen(port, function () {
  console.log(\`Your app is listening on port \${port}\`);
});`,
  mainPage(hostName) {
    return `<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8">
  <script src="https://files.${hostName}.ru/web-sdk/v1.min.js"></script>
</head>

<body>
  <h1>Hey there! 👋</h1>

  <p>This is test kaiten addon connect and initialize page</p>
  <script src="js/client.js"></script>
</body>

</html>`;
  },
  initialize: `Addon.initialize({
    'card_buttons': async (cardButtonsContext) => {
      const butttons = [];
      buttons.push({
        text: 'Test button 1',
        callback: async (callbackContext, callbackOptions) => {
          console.log('card test button 1 clicked, i will fetch card and simply console log it');
          try {
            const card = await callbackContext.getCard();
            console.log('here is card title: ', card.title);
          } catch (err) {
            console.log('error while fetching card');
          }
        }
      });
      return buttons;
    }
  })`,
  example: `Addon.initialize({
    settings: (settingsContext) => {
      return settingsContext.openPopup({
        title: 'Timer settings',
        url: './settings.html',
        height: 200,
        width: 300
      });
    },
    'card_body_section': async (bodySectionContext) => {
      const timerLogs = await bodySectionContext.getData('card', 'private', 'timerLogs');
  
      if (!timerLogs || !timerLogs.length) {
        return [];
      }
  
      return [{
        title: '📝 Timer logs',
        content: {
          type: 'iframe',
          url: bodySectionContext.signUrl('./timeLogs.html'),
          height: 200,
        }
      }]
    },
    'card_buttons': async (cardButtonsContext) => {
      const settings = await cardButtonsContext.getSettings();
      const buttons = [];
  
      const permissions = cardButtonsContext.getPermissions();
  
      if (!permissions.card.update) {
        return [];
      }
  
      if (settings && settings[0]) {
        const currentSpaceSettings = settings[0];
        
        if (currentSpaceSettings.showTestButton) {
          buttons.push({
            text: 'Test button 1',
            callback: async (callbackContext, callbackOptions) => {
              console.log('card test button 1 clicked, i will fetch card and simply console log it');
              try {
                const card = await callbackContext.getCard();
                console.log('here is card title: ', card.title);
              } catch (err) {
                console.log('error while fetching card');
              }
            }
          })
        }
      }
  
      const timerStartTime = await cardButtonsContext.getData('card', 'private', 'timerStartTime');
      
      if (!timerStartTime) {
        buttons.push({
          text: '🟢 Start timer',
          callback: async (buttonContext) => {
            const now = Date.now();
            await buttonContext.setData('card', 'private', 'timerStartTime', now);
          }
        })
      } else {
        buttons.push({
          text: '📝 Add log to timer',
          callback: (buttonContext) => {
            return buttonContext.openPopup({
              title: 'Add text log to current timer',
              url: './timerLog.html',
              height: 200,
              width: 300,
            });
          }
        })
        buttons.push({
          text: '🔴 Stop timer',
          callback: async (buttonContext) => {
            const now = Date.now();
            const startTime = await buttonContext.getData('card', 'private', 'timerStartTime');
            const currentLog = await buttonContext.getData('card', 'private', 'timerLog');
  
            await buttonContext.setData('card', 'private', 'timerStartTime', null);
            
            if (currentLog) {
              await buttonContext.setData('card', 'private', 'timerLog', null);
            }
  
            const data = {
              startTime,
              endTime: now,
              log: currentLog || null,
            }
            const logs = (await buttonContext.getData('card', 'private', 'timerLogs')) || [];
            await buttonContext.setData('card', 'private', 'timerLogs', logs);
          }
        })
      }
      return buttons;
    }
  })`,
  settings: `settings: (settingsContext) => {
    return settingsContext.openPopup({
      title: 'Timer settings',
      url: './settings.html',
      height: 200,
      width: 300
    });
  },`,
  settingHtml(hostName) {
    return `<!DOCTYPE html>
<html>

<head>
  <meta charset="utf-8">
  <link rel="stylesheet" href="index.css">
  <script src="https://files.${hostName}/web-sdk/v1.min.js"></script>
</head>

<body>
  <div id="settingsContent">
    <label for="show-date">Show Date</label>
    <input type="checkbox" id="show-date">

    <label for="show-test-button">Show Test Button</label>
    <input type="checkbox" id="show-test-button">

    <button id="submit">Submit settings</button>

  </div>
  <script src="js/spaceSettings.js"></script>
</body>

</html> `;
  },
  spaceSettings: `const iframe = Addon.iframe();

  const showDateEl = document.getElementById('show-date');
  const showTestButtonEl = document.getElementById('show-test-button');
  const submitButton = document.getElementById('submit');
  
  iframe.fitSize('#settingsContent');
  
  iframe.getSettings()
    .then(([response]) => {
      if (!response) {
        return;
      }
  
      showDateEl.checked = !!response.showDate;
      showTestButtonEl.checked = !!response.showTestButton;
    });
  
  submitButton.addEventListener('click', () => {
    const showDate = showDateEl.checked;
    const showTestButton = showTestButtonEl.checked;
  
    iframe.setSettings({
      showDate,
      showTestButton,
    }).then(() => {
      iframe.closePopup();
    });
  });`,
};

function CreateTestAddonGuide() {
  const { projectName, hostName } = registryGet('config');
  return (
    <NativeScrollContainer className="intro">
      <Wrapper>
        <SectionTitleWrapper>
          <SectionTitle variant="h4" title="Creating Example addons" hash="#header" />
        </SectionTitleWrapper>
        <Typography gutterBottom>
          <a target="_blank" rel="noreferrer" href="https://gitflic.ru/project/kaiten-developers/kaiten-test-addon">
            Repository with test addon
          </a>
        </Typography>
        <Typography gutterBottom>
          You will need a server to host all the addon code.
          Addons require that resources be served over HTTPS.
          {` ${projectName} loads resources into iframes, so you need to update your CORS settings to allow `}
          {` ${projectName} to make requests to fetch your files`}
          . For example, using Express in Node.js -
          {' '}
          <b>server.js</b>
          :
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.server, { language: 'javascript' }).value }} />
        </Snippet>
        <Typography gutterBottom>
          In this example, all HTML files will be located in
          {' '}
          <b>public/views</b>
          ,
          and all JavaScript files in
          {' '}
          <b>public/js</b>
          .
        </Typography>
        <Typography gutterBottom sx={{ marginBottom: 2 }}>
          {`Next, let's create the main HTML page that ${projectName} will load into a hidden iframe.`}
          This page will initialize the operation of our addon:
          {' '}
          <b>public/views/index.html</b>
          .
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.mainPage(hostName), { language: 'html' }).value }} />
        </Snippet>
        <Typography gutterBottom sx={{ marginBottom: 2 }}>
          Next, go to Kaiten in the administrative section “Addons” and click on “Create a new addon”
        </Typography>
        <img width="100%" src={createAddonImg} alt="create addon" />
        <Typography sx={{ marginTop: 1 }} gutterBottom>
          In the window that opens, enter the name and address of the Iframe connector url(the address where your server is running)
          and mark the necessary features of our addon
        </Typography>
        <img width="400" src={addonSettingsImg} alt="create addon" />
        <Typography gutterBottom sx={{ marginTop: 1 }}>
          <b>Settings</b>
          - allow configuring the addon for a specific space (when adding the addon to a space, the settings menu will have an option &ldquo;Settings&rdquo;)
        </Typography>
        <img width="400" src={newAddonImg} alt="new addon" />
        <Typography gutterBottom sx={{ marginTop: 1 }}>
          <b>Buttons in the card</b>
          - a section in the open card where all our action buttons will be displayed
        </Typography>
        <img width="600" src={cardButtonsImg} alt="card buttons" />
        <Typography gutterBottom sx={{ marginTop: 1 }}>
          <b>Iframe in the card</b>
          - a section in the open card where an iframe will be displayed
        </Typography>
        <img width="600" src={iframeCardImg} alt="iframe in the card" />
        <Typography gutterBottom sx={{ marginTop: 1 }}>
          <b>Card facade badges</b>
          - Small badges, placed on card facade on space
        </Typography>
        <img width="300" src={facadeBadgesImg} alt="facade badges" />
        <Typography gutterBottom sx={{ marginTop: 1, marginBottom: 2 }}>
          {'In '}
          <b>public/js/</b>
          {', add the file '}
          <b>client.js</b>
          .
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.initialize, { language: 'javascript' }).value }} />
        </Snippet>
        <Typography gutterBottom sx={{ marginTop: 1 }}>
          Please pay attention to the following points - client.js runs on the client side, and we have access to window.Addon.
          {`When initialize is called, our addon initializes and establishes a connection with ${projectName} (via window.postMessage calls).`}
        </Typography>
        <Typography gutterBottom>
          The first argument passed to initialize is an object whose keys are the features we have enabled for our addon:
        </Typography>
        <ul>
          <li>
            <Typography gutterBottom>
              <b>settings</b>
              {' '}
              - Addon settings in the space
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              <b>card_buttons</b>
              {' '}
              - Addon buttons/actions
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              <b>card_body_section</b>
              {' '}
              - iframe in the body of the open card
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              <b>card_facade_badges</b>
              {' '}
              - Card facade badges
            </Typography>
          </li>
        </ul>
        <Typography gutterBottom>
          {`After initializing the addon, ${projectName} will make a request to the addon to render the necessary section.`}
        </Typography>
        <Typography gutterBottom>
          The values of the keys are functions, where their first argument is an object context providing access to web SDK methods.
        </Typography>
        <Typography gutterBottom>
          The functions for
          {' '}
          <b>card_buttons</b>
          {' '}
          and
          {' '}
          <b>card_body_section</b>
          {' '}
          should return arrays of objects.
        </Typography>
        <Typography gutterBottom>
          In the example above, the
          {' '}
          <b>card_buttons</b>
          {' '}
          function returns an array containing one object, consisting of 2 keys:
        </Typography>
        <ul>
          <li>
            <Typography gutterBottom>
              <b>text</b>
              {' '}
              - Button text
            </Typography>
          </li>
          <li>
            <Typography gutterBottom>
              <b>callback</b>
              {' '}
              - Action that will occur when the button is clicked (in this case, card data will be retrieved and displayed in the console).
            </Typography>
          </li>
        </ul>
        <Typography gutterBottom>
          This example doesn&apos;t do anything useful. Let&apos;s try to do something more meaningful.
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.example, { language: 'javascript' }).value }} />
        </Snippet>
        <Typography gutterBottom sx={{ marginTop: 1 }}>
          Let&apos;s go over the main points of this example:
        </Typography>
        <Typography gutterBottom>
          <b>settings</b>
          {' '}
          - opens a popup, the content of which is an iframe with settings.html
        </Typography>
        <Typography gutterBottom>
          <b>card_body_section</b>
          {' '}
          <ul>
            <li>
              The function
              {' '}
              <Snippet inline removeGutters>
                getData(...)
              </Snippet>
              {' '}
              is called.
              <br />
              This function returns the saved data in the current context of the call (at this moment, all available addon sections are within the card, so the context is always &apos;card&apos;
            </li>
            <li>
              If the saved data for display is not found, the section will not be rendered.
            </li>
            <li>
              If timeLogs data is received, an array with an object will be returned, after which, upon receiving it, an iframe with
              {' '}
              <b>timeLogs.html</b>
              {' '}
              will be rendered in the card body.
            </li>
            <li>
              When addon data changes, this section will be re-rendered. So, if initially timeLogs are not present but later, upon interacting with the addon, the data appears, the section will be re-rendered, and this section will appear.
            </li>
          </ul>
        </Typography>
        <Typography gutterBottom>
          <b>card_buttons</b>
          {' '}
          <ul>
            <li>
              <Snippet inline removeGutters>
                getSettings()
              </Snippet>
              {' '}
              is called to retrieve the addon settings for the current space.
            </li>
            <li>
              <Snippet inline removeGutters>
                getPermissions()
              </Snippet>
              {' '}
              is called to retrieve the permissions of the current user, and if the user does not have access to update the current card, the buttons are not displayed (an empty array is returned).
            </li>
            <li>
              If the addon settings for the current space include the option
              {' '}
              <Snippet inline removeGutters>
                showTestButton
              </Snippet>
              {' '}
              , a test button is added.
            </li>
            <li>
              Calling
              {' '}
              <Snippet inline removeGutters>
                cardButtonsContext.getData(&apos;card&apos;, &apos;private&apos;, &apos;timerStartTime&apos;)
              </Snippet>
              {' '}
              - we retrieve the addon data value for the key timerStartTime.
              If this value is present for the current user, we add a button to stop the timer and a button that, when clicked, opens a popover with an iframe.
              If this value does not exist yet, we display a start timer button.
            </li>
            <li>
              The &apos;🟢 Start timer&apos; button, when pressed, triggers
              {' '}
              <Snippet inline removeGutters>
                await buttonContext.setData(&apos;card&apos;, &apos;private&apos;, &apos;timerStartTime&apos;, now)
              </Snippet>
              {' '}
              saving the value
              {' '}
              <Snippet inline removeGutters>
                now
              </Snippet>
              {' '}
              for the current user in the plugin data in the card context under the key
              {' '}
              <Snippet inline removeGutters>
                timerStartTime.
              </Snippet>
            </li>
          </ul>

        </Typography>
        <Alert sx={{ marginTop: 2, marginBottom: 2 }} severity="info">
          Note: when addon data changes for the current context, all addon sections will be rerendered.
          Therefore, after clicking the &apos;🟢 Start timer&apos; button, the addon buttons will be redrawn, and other buttons, such as &apos;🔴 Stop timer&apos; and &apos;📝 Add log to timer&apos;, will be displayed.
        </Alert>
        <Typography gutterBottom sx={{ marginBottom: 2 }}>
          Let&apos;s consider an example of our settings in the space (settings). When the &quot;Addon Settings&quot; menu item is clicked, the following function will be called:
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.settings, { language: 'javascript' }).value }} />
        </Snippet>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          As a result, a popup will open, with the content being
          {' '}
          <b>settings.html</b>
        </Typography>
        <Typography gutterBottom sx={{ marginBottom: 2 }}>
          Add in
          {' '}
          <b>public/views/settings.html</b>
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.settingHtml(hostName), { language: 'html' }).value }} />
        </Snippet>
        <Typography gutterBottom sx={{ marginBottom: 2, marginTop: 2 }}>
          Add in
          {' '}
          <b>public/js/spaceSettings.js</b>
        </Typography>
        <Snippet>
          <Typography component="pre" dangerouslySetInnerHTML={{ __html: hljs.highlight(exampleCode.spaceSettings, { language: 'javascript' }).value }} />
        </Snippet>
        <Typography gutterBottom sx={{ marginTop: 2 }}>
          As a result, the popup will display a pair of checkboxes and a confirmation button. Clicking the confirmation button will update the addon settings for the current space (

          <Snippet inline removeGutters>
            iframe.setSettings(...)
          </Snippet>
          ) and close the popup (
          <Snippet inline removeGutters>
            iframe.closePopup()
          </Snippet>
          )
        </Typography>
        <Typography gutterBottom>
          We won`&apos;t go into adding the rest of the files, you can fetch them from
          {' '}
          <a target="_blank" rel="noreferrer" href="https://gitflic.ru/project/kaiten-developers/kaiten-test-addon">
            here
          </a>
        </Typography>
        <Footer />
      </Wrapper>
    </NativeScrollContainer>
  );
}

export default CreateTestAddonGuide;
