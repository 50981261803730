import _ from 'lodash';
import automationConditionTypes from './automationConditionTypes';
import automationOperatorsMapData from './automationOperatorsMapData';
import automationComparatorsForCustomProperties from './automationComparatorsForCustomProperties';

const data = [
  {

    type: automationConditionTypes.cardType,
    name: 'Card type',
    key: automationConditionTypes.cardType,
    description: 'Checks for card type',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.cardType}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.cardType].operators, 'yes', automationOperatorsMapData[automationConditionTypes.cardType].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'typeIds', entries: ['typeIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'yes', 'Type Ids.'] },
              ],
            },
          },
        },
      },
    },

  },
  {

    type: automationConditionTypes.tag,
    name: 'Card tags',
    key: automationConditionTypes.tag,
    description: 'Checks for card tags',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.tag}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.tag].operators, 'yes', automationOperatorsMapData[automationConditionTypes.tag].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'tagIds', entries: ['tagIds', 'array of integers', 'For eq, ne - operators maxLength - 1', 'yes', 'Tags Ids.'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationConditionTypes.blockStatus,
    name: 'Card block status',
    key: automationConditionTypes.blockStatus,
    description: 'Checks for card block status',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.blockStatus}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.blockStatus].operators, 'yes', automationOperatorsMapData[automationConditionTypes.blockStatus].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'blocked', entries: ['blocked', 'boolean', '', 'yes', 'Card blocked flag'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationConditionTypes.dueDate,
    name: 'Due date',
    key: automationConditionTypes.dueDate,
    description: 'Checks for due date',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.dueDate}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.dueDate].operators, 'yes', automationOperatorsMapData[automationConditionTypes.dueDate].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'date', entries: ['date', 'string', '', 'for "exact" dateVariant', 'ISO 8601 format'] },
                { key: 'dateVariant', entries: ['dateVariant', 'enum', ['exact', 'current', 'custom', 'within_a_week', 'within_a_month'], 'for "set" comparator ', ' exact - exact date,\n current - event date,\n within_a_week - date within_a_week\n within_a_month - date within_a_month,\n custom - set deviation for is_less, is_more operators,\n  set period for is_between operator'] },
                { key: 'comparator', entries: ['comparator', 'enum', ['set', 'cleared'], 'yes', 'Check due date set or not'] },
                { key: 'deviation', entries: ['deviation', 'enum', ['plus', 'minus'], '"within_a_month", "within_a_week", "custom" dateVariant', 'plus - after event, minus - before event'] },
                { key: 'dateUnit', entries: ['dateUnit', 'enum', ['days', 'weeks', 'months'], 'For "custom" dateVariant', 'Deviation date units'] },
                { key: 'dateValue0', entries: ['dateValue0', 'number', '', 'For "custom" dateVariant and,\n"is_between", "is_more", "is_less" operator', 'First deviation number'] },
                { key: 'dateValue1', entries: ['dateValue1', 'number', '', 'For "custom" dateVariant and,\n"is_between" operator', 'Second deviation number'] },
              ],
            },
          },
        },
      },
    },
  },
  {
    type: automationConditionTypes.customProperty,
    name: 'Changed property',
    key: automationConditionTypes.customProperty,
    description: 'Checks for custom property',
    attributes: {
      schema: {
        columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
        data: [
          { key: 'type', entries: ['type', 'string', `${automationConditionTypes.customProperty}`, 'yes', ''] },
          { key: 'created', entries: ['created', 'string', '', 'yes', ' Created timestamp .ISO 8601 format'] },
          { key: 'operator', entries: ['operator', 'enum', automationOperatorsMapData[automationConditionTypes.customProperty].operators, 'yes', automationOperatorsMapData[automationConditionTypes.customProperty].description] },
          { key: 'data', entries: ['data', 'object', '', 'yes', 'Data  needed for this type'] },
        ],
        linkField: 'Type',
        objectProperties: {
          data: {
            schema: {
              columns: ['Field name', 'Type', 'Constraints', 'Required', 'Description'],
              data: [
                { key: 'value', entries: ['value', 'string| number | array', '', 'yes', 'number with numbers types, array - for select, catalog, users\nrest types-string'] },
                { key: 'propertyIds', entries: ['propertyIds', 'array of integers', 'maxLength - 1', 'yes', 'Property id'] },
                { key: 'comparator', entries: ['comparator', 'enum', '', 'yes', 'Includes allowed comparator for each type'] },
              ],
              linkField: 'Type',
              objectProperties: {
                comparator: {
                  schema: automationComparatorsForCustomProperties,
                },
              },
            },
          },
        },
      },
    },
  },
];

const attributes = {};
const tableData = [];
_.forEach(data, (conditionData) => {
  tableData.push({
    key: conditionData.key,
    entries: [conditionData.type, 'object', conditionData.name, conditionData.description || ''],
  });
  attributes[conditionData.key] = conditionData.attributes;
});
export default {
  schema: {
    columns: ['Condition Type', 'Condition attributes', 'Condition Name', 'Condition description'],
    data: tableData,
    linkField: 'Condition attributes',
    objectProperties: attributes,
  },
};
